import React, { useEffect } from 'react'
import { PrivacyTemplate } from '@components/templates'

const PrivacyPage = ({ match }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <PrivacyTemplate privacy={match.params.type} />
}

export default PrivacyPage
