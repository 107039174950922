import React from 'react'
import { google_login_btn_img } from '@images/'

const GoogleLoginBtn = ({ googleLogin, style }) => {
  const webViewCheck = () => {
    if (
      // navigator.userAgent.indexOf('FB') !== -1 ||
      // navigator.userAgent.indexOf('Instagram') !== -1 ||
      navigator.userAgent.indexOf('KAKAO') !== -1
    ) {
      alert('웹 브라우저에서 시도해주세요.')
    } else {
      googleLogin()
    }
  }

  return (
    <img
      onClick={() => webViewCheck()}
      src={google_login_btn_img}
      alt="google_login_img"
      style={{
        width: '280px',
        height: '48px',
        cursor: 'pointer',
        ...style,
      }}
    />
  )
}

export default GoogleLoginBtn
