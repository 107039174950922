import { observable } from 'mobx'

export default class LikeModel {
  @observable likeCount
  @observable isLike

  constructor(stores, props) {
    if (props) {
      this.likeCount = props.likeCount
      this.isLike = props.isLike
    }
  }
}
