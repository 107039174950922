import { observable } from 'mobx'

export default class YoutubeVideoModel {
  /* Database fields */
  @observable _id

  @observable index
  @observable link
  @observable youtubeVideoId
  @observable trackId
  @observable artistId
  @observable releaseArtistIdList
  @observable featuringArtistIdList
  @observable lyricstArtistIdList
  @observable composerArtistIdList
  @observable arrangerArtistIdList
  @observable updatedAt
  @observable deletedAt
  // youtubeApi를 통해 응답받은 정보
  @observable youtubeVideoInfo

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.index = props.index
      this.link = props.link
      this.youtubeVideoId = props.youtubeVideoId
      this.trackId = props.trackId
      this.artistId = props.artistId
      this.releaseArtistIdList = props.releaseArtistIdList
      this.featuringArtistIdList = props.featuringArtistIdList
      this.lyricstArtistIdList = props.lyricstArtistIdList
      this.composerArtistIdList = props.composerArtistIdList
      this.arrangerArtistIdList = props.arrangerArtistIdList
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
      this.youtubeVideoInfo = props.youtubeVideoInfo
    }
  }
}
