import { Caption1, Caption2, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { isNaN } from 'lodash'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const TableBox = styled.div`
  width: 100%;
  background-color: #ffffff;
`

const TrackGenieBugsTableChart = ({ isFold = true }) => {
  const { trendStore } = useStore()

  const artistTrackTrend = trendStore?.artistTrackTrend

  const parseData = useMemo(() => {
    const mergedObject = []

    for (let i = 0; i < artistTrackTrend?.genieDailyList.length; i++) {
      const genieDailyItem = artistTrackTrend?.genieDailyList[i]
      const genieDate = genieDailyItem.date

      for (let j = 0; j < artistTrackTrend?.bugsDailyList.length; j++) {
        const bugsDailyItem = artistTrackTrend?.bugsDailyList[j]
        const bugsDate = bugsDailyItem.date

        if (genieDate === bugsDate) {
          mergedObject.push({
            date: genieDate,
            likeCountInfo: {
              genie: genieDailyItem.likeCountInfo.genie,
              bugs: bugsDailyItem.likeCountInfo.bugs,
              increaseValue: {
                genie: genieDailyItem.likeCountInfo.increaseValue.genie,
                bugs: bugsDailyItem.likeCountInfo.increaseValue.bugs,
              },
            },
          })
          break
        }
      }
    }

    const sortedData = mergedObject?.sort((a, b) => b?.date - a?.date)

    if (isFold) return sortedData?.slice(0, 5)
    return sortedData
  }, [
    isFold,
    artistTrackTrend?.genieDailyList,
    artistTrackTrend?.bugsDailyList,
  ])

  return (
    <TableBox>
      <Tr>
        <Th>날짜</Th>
        <Th>좋아요(지니)</Th>
        <Th>좋아요(벅스)</Th>
      </Tr>
      {parseData?.map(rowData => {
        const dateStr = `${rowData?.date || ''}`
        return (
          <Tr>
            <Td>
              <Caption1>
                {`${dateStr.slice(2, 4)}. ${dateStr.slice(
                  4,
                  6,
                )}. ${dateStr.slice(6, 8)}`}
              </Caption1>
            </Td>
            <Td>
              <Flex type="column" align="center">
                <Caption1>
                  {convertToPricingComma(rowData?.likeCountInfo?.genie || 0)}
                </Caption1>
                <Flex align="center" style={{ gap: 4 }}>
                  {rowData?.likeCountInfo?.increaseValue?.genie > 0 ? (
                    <Up />
                  ) : rowData?.likeCountInfo?.increaseValue?.genie < 0 ? (
                    <Down />
                  ) : (
                    <></>
                  )}
                  <Caption2 color="#949494">
                    {rowData?.likeCountInfo?.increaseValue?.genie === 0
                      ? '-'
                      : convertToPricingComma(
                          Math.abs(
                            rowData?.likeCountInfo?.increaseValue?.genie,
                          ),
                        )}
                  </Caption2>
                </Flex>
              </Flex>
            </Td>
            <Td>
              <Flex type="column" align="center">
                <Caption1>
                  {convertToPricingComma(rowData?.likeCountInfo?.bugs || 0)}
                </Caption1>
                <Flex align="center" style={{ gap: 4 }}>
                  {rowData?.likeCountInfo?.increaseValue?.bugs > 0 ? (
                    <Up />
                  ) : rowData?.likeCountInfo?.increaseValue?.bugs < 0 ? (
                    <Down />
                  ) : (
                    <></>
                  )}
                  <Caption2 color="#949494">
                    {rowData?.likeCountInfo?.increaseValue?.bugs === 0
                      ? '-'
                      : convertToPricingComma(
                          Math.abs(rowData?.likeCountInfo?.increaseValue?.bugs),
                        )}
                  </Caption2>
                </Flex>
              </Flex>
            </Td>
          </Tr>
        )
      })}
    </TableBox>
  )
}

export default observer(TrackGenieBugsTableChart)

const Tr = styled(Flex)`
  flex: 1;
`

const Th = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;

  color: #646464;
  font-size: 12px;
`

const Td = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #66bc6a;

  margin-top: 2px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #ea4653;

  margin-top: 2px;
`
