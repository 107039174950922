import { observable } from 'mobx'

export default class ArtistFanSpotPointModel {
  /* Database fields */
  @observable _id

  @observable artistId
  @observable point
  @observable rank

  @observable createdAt

  @observable createdAtKST
  @observable createdYearKST
  @observable createdMonthKST
  @observable createdDayKST
  @observable createdHourKST
  @observable createdMinuteKST
  @observable createdSecondKST

  @observable pointUpdatedAt

  @observable pointUpdatedAtKST
  @observable pointUpdatedYearKST
  @observable pointUpdatedMonthKST
  @observable pointUpdatedDayhKST
  @observable pointUpdatedHourKST
  @observable pointUpdatedMinuteKST
  @observable pointUpdatedSecondKST

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.artistId = props.artistId
      this.point = props.point
      this.rank = props.rank

      this.createdAt = props.createdAt

      this.createdAtKST = props.createdAtKST
      this.createdYearKST = props.createdYearKST
      this.createdMonthKST = props.createdMonthKST
      this.createdDayKST = props.createdDayKST
      this.createdHourKST = props.createdHourKST
      this.createdMinuteKST = props.createdMinuteKST
      this.createdSecondKST = props.createdSecondKST

      this.pointUpdatedAt = props.pointUpdatedAt

      this.pointUpdatedAtKST = props.pointUpdatedAtKST
      this.pointUpdatedYearKST = props.pointUpdatedYearKST
      this.pointUpdatedMonthKST = props.pointUpdatedMonthKST
      this.pointUpdatedDayhKST = props.pointUpdatedDayhKST
      this.pointUpdatedHourKST = props.pointUpdatedHourKST
      this.pointUpdatedMinuteKST = props.pointUpdatedMinuteKST
      this.pointUpdatedSecondKST = props.pointUpdatedSecondKST
    }
  }
}
