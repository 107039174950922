import React, { useEffect, useState } from 'react'
import { BodyWrapper, Flex } from '@components/atoms'
import { plam_app_logo_img } from '@images/'
import styled, { css, keyframes } from 'styled-components'
import { mbtiQuizData } from '../../resources/mbtiQuizData'

const MyIdolQuizTemplate = ({ step, hanldeAnswer }) => {
  const [anime, setAnime] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  return (
    <BodyWrapper
      style={{ padding: 0, minHeight: '100vh', backgroundColor: '#FFCC00' }}
    >
      <Flex
        type="column"
        style={
          {
            height: '100%',
            alignItems: 'center',
            backgroundColor: '#FFCC00',
            padding: 24,
          }
        }
      >
        <Flex justify="space-between" align="center" style={{ width: '100%' }}>
          <Flex align="center">
            <RixText size={20} style={{ marginRight: 4 }}>
              Q
            </RixText>
            <RixText size={14}>{step < 10 ? `0${step}` : step}</RixText>
          </Flex>
          <RixText size={12}>{`${step} / 12`}</RixText>
        </Flex>
        <QuizBox anime={anime} onAnimationEnd={() => setAnime(false)}>
          <Flex style={{ width: '100%', justifyContent: 'flex-start' }}>
            <RixText
              size={24}
              align="left"
              style={
                {
                  margin: '88px 0 54px 0',
                  whiteSpace: 'pre-line',
                  height: 131,
                }
              }
            >
              {mbtiQuizData[step - 1].text}
            </RixText>
          </Flex>
          <QuizBtn
            onClick={
              () => {
                setAnime(true)
                hanldeAnswer(mbtiQuizData[step - 1], 'Y')
              }
            }
          >
            <GGText
              color="#ffffff"
              style={
                {
                  whiteSpace: 'pre-line',
                }
              }
            >
              {mbtiQuizData[step - 1].answer1}
            </GGText>
          </QuizBtn>
          <QuizBtn
            style={{ marginTop: 16, whiteSpace: 'pre-line' }}
            onClick={
              () => {
                setAnime(true)
                hanldeAnswer(mbtiQuizData[step - 1], 'N')
              }
            }
          >
            <GGText color="#ffffff">{mbtiQuizData[step - 1].answer2}</GGText>
          </QuizBtn>
        </QuizBox>
        <LogoImg
          src={plam_app_logo_img}
          style={{ marginTop: 109, marginBottom: 40 }}
        />
      </Flex>
    </BodyWrapper>
  )
}

export default MyIdolQuizTemplate

const RixText = styled.p`
  /* font-family: NotoSansCJKkr-Bold; */
  font-family: RixYeoljeongdoPR;
  font-style: italic;
  /* transform: skew(347deg); */
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

const GGText = styled.p`
  /* font-family: NotoSansCJKkr-Bold; */
  font-family: ${({ type }) =>
    type ? `GongGothicOTF${type}` : 'GongGothicOTFLight'};
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

const QuizBtn = styled(Flex)`
  padding: 32px 34px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #000001;
  cursor: pointer;
`

const LogoImg = styled.img`
  width: 58px;
  height: 16px;
`

const boxFadeOut = keyframes`
  from {opacity: 1; margin-right:0;}
  to {opacity: 0; margin-right:100px;}
`

const boxFadeIn = keyframes`
  from {opacity: 0; margin-left:100px;}
  to {opacity: 1; margin-right:0;}
`

const QuizBox = styled.div`
  animation: ${({ anime }) =>
    anime
      ? css`
          ${boxFadeOut} 0.25s
        `
      : css`
          ${boxFadeIn} 0.25s
        `};

  width: 100%;
  display: flex;
  flex-direction: column;
`
