import React from 'react'
import { Flex, Caption1, H6 } from '@components/atoms'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import styled from 'styled-components'
const CustomSkeleton = styled(Skeleton)`
  line-height: unset !important;
`

const LikeAristItemSkeleton = ({ item }) => {
  return (
    <Flex type="column" align="center" style={{ gap: 5 }}>
      <CustomSkeleton width={64} height={64} circle={true} />
      <Caption1 style={{ lineHeight: 1 }}>
        <Skeleton width={50} height={18} />
      </Caption1>
    </Flex>
  )
}

export default LikeAristItemSkeleton
