import { action, observable } from 'mobx'
import { Store } from '.'
import { CommentModel } from './models'
import { Network } from './networks'

export default class CommentStore {
  @observable commentList
  @observable commentListCount
  @observable contentTitle

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.commentList = []
    this.commentListCount = 0
    this.contentTitle = null
  }

  @action.bound
  fetchCommentList = async params => {
    if (!params.dataObjectId || !params.dataCollectionName) return

    // return this.store.useLoading(async () => {
    const res = await this.network.commentNetwork.getComments({
      dataObjectId: params.dataObjectId,
      dataCollectionName: params.dataCollectionName,
      userId: params.userId ? params.userId : null,
      limit: params.limit ? params.limit : 5,
      offset: params.offset ? params.offset : 0,
    })

    if (!res) return

    if (params && params.offset < 1) {
      this.commentList = res.commentList
        .filter(elem => !!elem)
        .map(elem => new CommentModel(this.store, elem))
    }
    else {
      this.commentList = this.commentList.concat(
        res.commentList
          .filter(elem => !!elem)
          .map(elem => new CommentModel(this.store, elem)),
      )
    }
    this.contentTitle = res.contentTitle
    this.commentListCount = res.commentCount
    // })
  }

  @action.bound
  createComment = async params => {
    if (
      (this.store.authStore.currentUser
        && !this.store.authStore.currentUser._id)
      || !params.dataObjectId
      || !params.dataCollectionName
      || !params.text
    )
      return

    return this.store.useLoading(async () => {
      const res = await this.network.commentNetwork.postComments({
        ...params,
        userId: this.store.authStore.currentUser._id,
      })
      if (params && params.parentId) {
        const findIndex = this.commentList.findIndex(
          comment => comment['_id'] === params.parentId,
        )

        this.commentList[findIndex].reply = this.commentList[
          findIndex
        ].reply.concat(new CommentModel(this.store, res.comment))
      }

      return !!res
    })
  }

  @action.bound
  updateComment = async params => {
    if (
      !this.store.authStore.currentUser._id
      || !params.commentId
      || !params.text
    )
      return

    return this.store.useLoading(async () => {
      const res = await this.network.commentNetwork.putComments(
        params.commentId,
        {
          text: params.text,
          isAnonymous: params.isAnonymous,
          userId: this.store.authStore.currentUser._id,
        },
      )
      // if (params && params.parentId) {
      //   const findIndex = this.commentList.findIndex(
      //     comment => comment['_id'] === params.parentId,
      //   )

      //   this.commentList[findIndex].reply = this.commentList[
      //     findIndex
      //   ].reply.concat(new CommentModel(this.store, res.comment))
      // }

      return !!res
    })
  }

  @action.bound
  removeComment = async (commentId, userId) => {
    if (!userId || !commentId) return

    return this.store.useLoading(async () => {
      const res = await this.network.commentNetwork.deleteComments(commentId, {
        userId,
      })

      return !!res
    })
  }

  @action.bound
  // async updateCommentLike(updateType: 'add' | 'delete', commentId) {
  async updateCommentLike(updateType: 'add' | 'delete', comment) {
    // return this.store.useLoading(async () => {
    if (!updateType || !this.store.authStore.currentUser._id || !comment) {
      return false
    }

    let res

    // 좋아요 추가 일때,
    if (updateType === 'add') {
      res = await this.network.commentNetwork.postCommentsLikes(
        comment._id,
        this.store.authStore.currentUser._id,
      )
      if (!res) return

      // const findIndex = this.commentList.findIndex(
      //   comment => comment['_id'] === commentId,
      // )

      comment.isLike = true
      comment.likeCount += 1
      // this.commentList[findIndex].isLike = true
      // this.commentList[findIndex].likeCount =
      //   this.commentList[findIndex].likeCount + 1
    }
    else if (updateType === 'delete') {
      // 좋아요 삭제 일때,
      res = await this.network.commentNetwork.deleteCommentsLikes(
        comment._id,
        this.store.authStore.currentUser._id,
      )
      if (!res) return

      // const findIndex = this.commentList.findIndex(
      //   comment => comment['_id'] === commentId,
      // )

      comment.isLike = false
      comment.likeCount -= 1

      // this.commentList[findIndex].isLike = false
      // this.commentList[findIndex].likeCount =
      //   this.commentList[findIndex].likeCount - 1
    }

    return !!res
    // })
  }

  @action.bound
  reportComment = async params => {
    if (!this.store.authStore.currentUser._id || !params.commentId) return
    return this.store.useLoading(async () => {
      const res = await this.network.commentNetwork.postCommentsReports(
        // commentId,
        params.commentId,
        {
          userId: this.store.authStore.currentUser._id,
          reason: (params.reason && params.reason) || null,
        },
      )

      if (!res) return

      if (res.data && res.data.comment) {
        return 'completed'
      }
      if (res.error && res.error.type === 'Conflict') {
        return 'conflicted'
      }
    })
  }
}
