import React, { useState, useEffect } from 'react'
import { Typography, Flex, H4, Caption1, H6 } from '@components/atoms'
import { no_next_page_img } from '@images/'
import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'

const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const FanspotScoreCard = ({
  isDetailPage = false,
  onClickDetail = () => {},
  artistFanspotScore,
  myFanspotScore,
  myRank,
  artistRank,
  artistInfo,
  currentUser,
}) => {
  return (
    <>
      <ListBox>
        <Flex type="column">
          <Caption1
            type="Regular"
            align="left"
            color="#646464"
            style={
              {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                width: '100%',
                marginBottom: '12px',
              }
            }
          >
            {artistInfo?.name}
          </Caption1>
          <Flex
            align="center"
            // justify="space-between"

            style={{ gap: 20 }}
          >
            <Flex type="column" style={{ gap: 4 }}>
              <Caption1 type="Medium" align="left">
                아티스트 팬스팟 스코어
              </Caption1>
              <H6 align="left" type="Bold">
                {
                  artistFanspotScore
                    ? convertToPricingComma(artistFanspotScore)
                    : '-'
                }
              </H6>
            </Flex>

            {
              !isDetailPage && (
                <Flex
                  type="column"
                  // onClick={onClickDetail}
                  style={{ gap: 4 }}
                >
                  <Caption1 type="Medium" align="left">
                  랭크
                  </Caption1>
                  <H6 align="left" type="Bold">
                    {
                      artistRank && artistRank <= 1000
                        ? convertToPricingComma(artistRank)
                        : '-'
                    }
                  </H6>
                </Flex>
              )
            }
          </Flex>
        </Flex>

        <Flex
          style={
            {
              height: 1,
              width: '100%',
              backgroundColor: '#f4f4f4',
              margin: '12px 0',
            }
          }
        />

        <Flex type="column">
          <Caption1
            type="Regular"
            align="left"
            color="#646464"
            style={
              {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                width: '100%',
                marginBottom: '12px',
              }
            }
          >
            {currentUser?.nickname || currentUser?.name || currentUser?.account}
          </Caption1>
          <Flex type="row" style={{ gap: 20 }}>
            <Flex type="column" style={{ width: '116px', gap: 4 }}>
              <Caption1 type="Medium" align="left">
                나의 팬스팟 스코어
              </Caption1>
              <H6 align="left" type="Bold">
                {myFanspotScore ? convertToPricingComma(myFanspotScore) : '-'}
              </H6>
            </Flex>
            <Flex type="column" style={{ gap: 4 }}>
              <Caption1 type="Medium" align="left">
                나의 팬스팟 랭크
              </Caption1>
              <H6 align="left" type="Bold">
                {myRank && myRank <= 1000 ? convertToPricingComma(myRank) : '-'}
              </H6>
            </Flex>
          </Flex>
        </Flex>
      </ListBox>
    </>
  )
}
export default FanspotScoreCard
