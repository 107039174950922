import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Flex, BodyWrapper, H6, Input } from '@components/atoms'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import {
  BurgerMenu,
  MostArticleList,
  TotalInplamArtistList,
} from '@components/organisms'
import { colors } from '@colors/'
import { useObserver } from 'mobx-react'
import { Helmet } from 'react-helmet'
import Pagination from 'react-js-pagination'
import { SearchArtistInplamItem } from '@components/molecules'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

interface InPlamTemplateProps {
  totalInplamList: Array;
  latestActicleList: Array;
  setOffset: Function;
}

const InPlamTemplate = ({
  totalInplamList,
  latestActicleList,
  popularTotalArticleList,
  searchOffset,
  setSearchOffset,
  setOffset,
  fetchSearchInplamList,
  searchInplamList,
  sortBy,
  offset,
  handleChangeSortBy,
  history,
  more,
  fetchTotalList,
  isFavorite,
}: InPlamTemplateProps) => {
  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [keyword, setKeyword] = useState('') // 검색어
  const [inputValue, setInputValue] = useState('') // view 검색어
  const [hasMore, setHasMore] = useState(true) // 검색결과 더보기 유무

  const countOffset = () => {
    setSearchOffset(searchOffset + 1)
  }

  const debouncedOnChange = useRef(
    _.debounce(value => {
      setKeyword(value)
      if (searchOffset > 1) {
        setSearchOffset(0)
      }
    }, 300),
  ).current

  const handleSearch = value => {
    setInputValue(value)
    debouncedOnChange(value)
  }

  useEffect(() => {
    const fetchSearch = async () => {
      if (keyword !== '') {
        const result = await fetchSearchInplamList({
          offset: searchOffset,
          limit: 10,
          name: keyword,
        })

        if (result.length < 10) {
          setHasMore(false)
        }
        else if (result.length >= 10) {
          setHasMore(true)
        }
      }
    }
    fetchSearch()
  }, [keyword, searchOffset])

  return useObserver(() => (
    <>
      <Helmet>
        <title>{`팬스팟`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>
      <BurgerMenu isHome isOpen={isOpen} setIsOpen={setIsOpen} />
      <BodyWrapper
        style={
          {
            paddingTop: 56,
            paddingBottom: 64,
            minHeight: '100vh',
          }
        }
      >
        <Flex align="center" style={{ width: '100%', marginTop: 16 }}>
          <SearchInplamInput
            placeholder="아티스트, 앨범, 노래 등 검색"
            onChange={e => handleSearch(e.target.value)}
            value={inputValue}
            keyword={keyword}
          />
          {
            keyword !== '' && (
              <Flex
                justify="flex-end"
                align="center"
                style={{ width: 48, height: 40 }}
              >
                <H6
                  color={colors.brownish_grey}
                  style={{ cursor: 'pointer' }}
                  onClick={
                    () => {
                      setInputValue('')
                      setKeyword('')
                    }
                  }
                >
                취소
                </H6>
              </Flex>
            )
          }
        </Flex>
        {
          searchInplamList && keyword !== '' ? (
            <InfiniteScroll
              dataLength={searchInplamList.length}
              scrollThreshold={0.8}
              next={countOffset}
              hasMore={hasMore}
              loader={
                <ReactLoading
                  type="spin"
                  color="black"
                  style={
                    {
                      width: '50px',
                      height: '50px',
                      margin: '0 auto 50px auto',
                    }
                  }
                />
              }
            // className="scroll_div"
            >
              <SearchList>
                {
searchInplamList?.map(item => (
  <SearchArtistInplamItem
    item={item}
    key={item._id}
    history={history}
  />
))
                }
              </SearchList>
            </InfiniteScroll>
          ) : (
            <>
              <TotalInplamArtistList
                list={totalInplamList}
                setOffset={setOffset}
                sortBy={sortBy}
                offset={offset}
                fetchTotalList={fetchTotalList}
                isFavorite={isFavorite}
                handleChangeSortBy={handleChangeSortBy}
                more={more}
              />
              <MostArticleList
                title="인기 팬스팟"
                list={popularTotalArticleList}
                style={{ margin: '16px 0 0 0' }}
              />
              <MostArticleList
                title="최신 팬스팟"
                list={latestActicleList}
                style={{ margin: '16px 0 0 0' }}
              />
            </>
          )
        }
      </BodyWrapper>
    </>
  ))
}

export default InPlamTemplate

const SearchInplamInput = styled(Input)`
  width: 100%;
  height: 40px;
  padding: 6px 16px;

  border: none !important;
  background-color: #f4f4f4;
  font-size: 16px;

  box-sizing: border-box;

  /* ${({ keyword }) =>
    keyword !== ''
    && css`
      width: 80%;
    `} */
`

const SearchList = styled(Flex)`
  flex-direction: column;

  > div:first-child {
    padding-top: 16px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
  > div {
    padding: 6px 0;
    cursor: pointer;
  }
`
