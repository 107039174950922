import React from 'react'
import styled from 'styled-components'
import { shadow_img, btn_img } from '@images'
import { colors } from '@colors'

export const Btn = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${colors.main};
  border-radius: 28px;
  position: absolute;
  top: 4px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContractBtn = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div
        style={{
          position: 'fixed',
          bottom: '18px',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '76px',
            height: '76px',
          }}
        >
          <img
            style={{
              width: '76px',
              height: '76px',
              position: 'absolute',
              top: '0',
              left: '0',
            }}
            src={shadow_img}
            alt="back"
          />
          <a href={'http://plam.kr/'} target="_blank" rel="noopener noreferrer">
            <Btn>
              <img
                src={btn_img}
                alt="contract_btn_img"
                width="26px"
                heigth="26px"
              />
            </Btn>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContractBtn
