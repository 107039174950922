import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { RecordModel, ArtistModel, AlbumModel, TrackModel } from './models'

const DATA_TYPE = {
  artist: 'artist',
  album: 'album',
  track: 'track',
}
export default class DataMapStore {
  @observable dataType
  @observable data

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.record = {}
  }

  @action.bound
  async fetchData(shortId) {
    return this.store.useLoading(async () => {
      if (!shortId) return

      const fetchedData = await this.network.dataMapNetwork.getData(shortId)
      if (!fetchedData) {
        this.dataType = 'notFound'
        return
      }

      let data
      switch (fetchedData.dataType) {
        case DATA_TYPE['artist']:
          data = new ArtistModel(this.store, fetchedData.data)
          this.store.artistStore.artistDetail = new ArtistModel(
            this.store,
            fetchedData.data,
          )

          break
        case DATA_TYPE['album']:
          data = new AlbumModel(this.store, fetchedData.data)
          break
        case DATA_TYPE['track']:
          data = new TrackModel(this.store, fetchedData.data)
          break
        default:
          break
      }

      this.dataType = fetchedData.dataType || 'notFound'
      this.data = data

      return fetchedData
    })
  }

  @action.bound
  async fetchShortenUrlData(subdomain, customUrl) {
    return this.store.useLoading(async () => {
      if (!subdomain) return

      const fetchedData = await this.network.dataMapNetwork.getShortenUrlData(
        subdomain,
        customUrl,
      )
      if (!fetchedData) {
        this.dataType = 'notFound'
        return
      }

      let data
      switch (fetchedData.dataType) {
        case DATA_TYPE['artist']:
          data = new ArtistModel(this.store, fetchedData.data)
          this.store.artistStore.artistDetail = new ArtistModel(
            this.store,
            fetchedData.data,
          )
          // // if (data.isDisplayFollowerData) {
          // await this.store.statsStore.fetchSnsListLike(data._id, 'monthly')
          // // }
          break
        case DATA_TYPE['album']:
          data = new AlbumModel(this.store, fetchedData.data)

          // this.store.albumStore.albumDetail = data

          if(data){
            this.store.commentStore.fetchCommentList({
              dataObjectId: data._id,
              dataCollectionName: 'albums',
              offset: 0,
            })
          }
          break
        case DATA_TYPE['track']:
          data = new TrackModel(this.store, fetchedData.data)

          // this.store.albumStore.trackDetail = data

          if(data){
            this.store.commentStore.fetchCommentList({
              dataObjectId: data._id,
              dataCollectionName: 'tracks',
              offset: 0,
            })
          }
          break
        default:
          break
      }

      this.dataType = fetchedData.dataType || 'notFound'
      this.data = data

      return fetchedData
    })
  }
}
