import { observable } from 'mobx'
import { STORAGE_URL } from '@consts/'

export default class ChartModel {
  @observable _id
  @observable artistId
  @observable rank // 현재순위
  @observable rankChanges // 순위 변화량
  @observable periodType // 기간 타입
  @observable artistLikeIncreaseValue // 아티스트 좋아요 증가량
  @observable trackLikeIncreaseValue // 트랙 좋아요 증가량
  @observable likeCount
  @observable agencyInfo
  @observable createdAt
  @observable artistName
  @observable uniqueName
  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }
  get image512Uri() {
    return `${STORAGE_URL}${this.image512Path || this.imageOriginalPath}`
  }
  get image128Uri() {
    return `${STORAGE_URL}${this.image128Path || this.imageOriginalPath}`
  }
  get image64Uri() {
    return `${STORAGE_URL}${this.image64Path || this.imageOriginalPath}`
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.artistId = props.artistId
      this.rank = props.rank // 현재순위
      this.rankChanges = props.rankChanges // 순위 변화량
      this.periodType = props.periodType // 기간 타입
      this.artistLikeIncreaseValue = props.artistLikeIncreaseValue // 아티스트 좋아요 증가량
      this.trackLikeIncreaseValue = props.trackLikeIncreaseValue // 트랙 좋아요 증가량
      this.likeCount = props.likeCount
      this.agencyInfo = props.agencyInfo
      this.createdAt = props.createdAt
      this.artistName = props.artistName
      this.uniqueName = props.uniqueName
      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path
    }
  }
}
