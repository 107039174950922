import React from 'react'
import { Flex, H6, Caption1 } from '@components/atoms'
import { default_profile_img } from '@images'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import { like_gray_img } from '@images/'
import { converseUnitEng } from '@utils/format'
import Image from './Image'
import { colors } from '@colors'
import { handleArtistImgError } from '@utils/handler'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const ArtistImage = styled.img`
  width: 56px;
  height: 56px;
  object-fit: contain;
  border-radius: 28px;
  border: solid 0.5px #d4d4d4;
  background-color: #d4d4d4;
  margin-right: 12px;
`

const RankChange = styled.div`
  min-width: 24px;
  max-width: 24px;
  text-align: center;
  margin-right: 5px;

  &::before {
    content: ${(props) =>
      // 순위가 1위 => 3위 가 되면 data값이 +2 로 온다.
      props.data > 0
        ? "' \\25BC'"
        : props.data === 0
        ? "' \\257A'"
        : "' \\25B2'"};
    color: ${(props) =>
      props.data > 0 ? '#66bc6a' : props.data === 0 ? '#242424' : '#ea4653'};
    font-size: 10px;
    margin-left: ${(props) =>
      props.rank > 99 ? '-2px' : props.rank > 9 ? '0px' : '-4px'};
  }
`

const PlamChartItem = ({ item }) => {
  return (
    <Flex style={{ width: '100%', height: '56px' }}>
      <Link
        to={`/artist/${item.uniqueName}`}
        style={{ width: '100%', height: '100%' }}
      >
        <Flex style={{ width: '100%', height: '100%' }}>
          {/* 아티스트 이미지 영역 */}
          <Image
            src={
              item && item.image64Uri ? item.image64Uri : default_profile_img
            }
            size={['56px', '56px']}
            style={{
              borderRadius: '28px',
              border: `solid 0.5px ${colors.inActive}`,
              backgroundColor: `${colors.inActive}`,
              marginRight: '12px',
            }}
            onError={(e) => handleArtistImgError(e)}
          />

          {/* 등수 및 아티스트 이름 영역 */}
          <Flex
            type="column"
            style={{
              width: 'fit-content',
              height: '100%',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Flex>
              <H6
                style={{
                  marginRight: '5px',
                  minWidth: '24px',
                  maxWidth: '24px',
                }}
              >
                {item.rank}
              </H6>
              <H6 align="left" style={{ ...ellipsisStyle }}>
                {item.artistName}
              </H6>
            </Flex>
            <Flex style={{ alignItems: 'flex-end' }}>
              <RankChange
                data={item.rankChanges || 0}
                rank={item.rank}
                style={{ width: '16px' }}
              />
              <Caption1
                align="left"
                color="#949494"
                type="Regular"
                style={{ ...ellipsisStyle }}
              >
                {item.agencyInfo ? item.agencyInfo.name : 'Independent'}
              </Caption1>
            </Flex>
          </Flex>

          {/* 좋아요 수치 영역 */}
          <Flex
            style={{
              alignSelf: 'center',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <img
              src={like_gray_img}
              style={{ width: '16px', height: '16px', marginRight: '4px' }}
            />
            <Caption1 color="#646464">
              {converseUnitEng(item.likeCount || 0)}
            </Caption1>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}

export default PlamChartItem
