import { redirectApi } from '@utils/axios'
import { Network } from '.'

export default class AuthNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async postLogin(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/login',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postLogin error', error)
    }
  }

  async postAccount(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/account',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postAccount error', error)
    }
  }

  async postPassword(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/password',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postPassword error', error)
    }
  }

  async putPassword(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/password',
        'put',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postPassword error', error)
    }
  }

  getLoginFacebook = async () => {
    try {
      return await redirectApi('/user/login/facebook')
    } catch (error) {
      console.warn('AuthNetwork getLoginFacebook login error', error)
    }
  }

  async getAuthFacebook(code) {
    try {
      const { data } = await this.network._axiosApi(
        `/user/auth/facebook/${code}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('AuthNetwork getAuthFacebook login error', error)
    }
  }

  getLoginKakao = async () => {
    try {
      return await redirectApi('/user/login/kakao')
    } catch (error) {
      console.warn('AuthNetwork getLoginKakao error', error)
    }
  }

  async getAuthKakao(code) {
    try {
      const { data } = await this.network._axiosApi(
        `/user/auth/kakao/${code}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('AuthNetwork getAuthKakao login error', error)
    }
  }

  getLoginGoogle = async () => {
    try {
      return await redirectApi('/user/login/google')
    } catch (error) {
      console.warn('AuthNetwork getLoginGoogle error', error)
    }
  }

  async getAuthGoogle(code) {
    try {
      const { data } = await this.network._axiosApi(
        `/user/auth/google/${code}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('AuthNetwork getAuthGoogle login error', error)
    }
  }

  getLoginApple = async () => {
    try {
      return await redirectApi('/user/login/apple')
    } catch (error) {
      console.warn('AuthNetwork getLoginApple error', error)
    }
  }

  async getAuthApple(code) {
    try {
      const { data } = await this.network._axiosApi(
        `/user/auth/apple/${code}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('AuthNetwork getAuthApple login error', error)
    }
  }

  async postTokenLogin(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/token',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postTokenLogin error', error)
    }
  }

  async getLike(pageId, loginUserId) {
    try {
      const { data } = await this.network._axiosApiAuth(`/like`, 'get', {
        pageId,
        loginUserId,
      })
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postLike error', error)
    }
  }

  async postLike(params) {
    try {
      const { data } = await this.network._axiosApiAuth('/like', 'post', params)
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postLike error', error)
    }
  }

  async deleteLike(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/like',
        'delete',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork deleteLike error', error)
    }
  }

  putUser = async params => {
    try {
      const { data } = await this.network._axiosApiAuth('/user', 'put', params)
      return data || null
    } catch (error) {
      console.warn('AuthNetwork putUser error', error)
      return error.response
    }
  }

  getUserLikeArtist = async ({ loginUserId }) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/user/${loginUserId}/like/artist`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork getUserLikeArtist error', error)
      return error.response
    }
  }

  getUserLikeTrack = async ({ loginUserId, where }) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/user/${loginUserId}/like/track`,
        'get',
        where,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork getUserLikeArtist error', error)
      return error.response
    }
  }

  getPopularTrack = async ({ where }) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/track/home/rank`,
        'get',
        where,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork getUserLikeArtist error', error)
      return error.response
    }
  }

  async deleteUser({ userObjectId }) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/user/${userObjectId}`,
        'delete',
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork deleteUser error', error)
    }
  }

  async postBlockUser(currentUserObjectId, blockedUserObjectId, where) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/user/${currentUserObjectId}/block/${blockedUserObjectId}`,
        'post',
        where,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postBlockUser error', error)
    }
  }

  getPopup = async ({ where }) => {
    try {
      const { data } = await this.network._axiosApiAuth(`/popup`, 'get', where)
      return data || null
    } catch (error) {
      console.warn('AuthNetwork getPopup error', error)
      return error.response
    }
  }

  getNotiList = async ({ where }) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/alert-notification`,
        'get',
        where,
      )
      if (data?.data) {
        return data.data
      }
      return null
    } catch (error) {
      console.warn('authNetwork getNotiList error', error)
    }
  }
}
