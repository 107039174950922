// date를 yyyy.mm.dd.Day 로 변환
export function dateFormat(value) {
  let date = new Date(value)
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()
  const day = date.getDay()

  const week = ['일', '월', '화', '수', '목', '금', '토']
  const todayLabel = week[day]

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}.${mm}.${dd} (${todayLabel})`)
}

// yyyy.mm.dd
export function dateYMD(value, isDot = false) {
  const date = new Date(value)
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }

  if (isDot) {
    return `${yyyy}.${mm}.${dd}`
  }
  return `${yyyy}년 ${mm}월 ${dd}일`
}

// yyyy년 mm월 dd일 hh시 mm분
export function dateYMDMM(value) {
  let date = new Date(value)
  let min = date.getMinutes()
  let hour = date.getHours()
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) dd = `0${dd}`
  if (mm < 10) mm = `0${mm}`
  if (hour < 10) hour = `0${hour}`
  if (min < 10) min = `0${min}`

  return (date = `${yyyy}년 ${mm}월 ${dd}일 ${hour}시 ${min}분`)
}

export function dateTodayYMDMM(value) {
  let date = new Date(value)
  let min = date.getMinutes()
  let hour = date.getHours()
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  const today = new Date()
  const today_yyyy = today.getFullYear()
  let today_mm = today.getMonth() + 1
  let today_dd = today.getDate()

  if (dd < 10) dd = `0${dd}`
  if (mm < 10) mm = `0${mm}`
  if (hour < 10) hour = `0${hour}`
  if (min < 10) min = `0${min}`

  if (today_dd < 10) today_dd = `0${today_dd}`
  if (today_mm < 10) today_mm = `0${today_mm}`

  if (yyyy === today_yyyy && mm === today_mm && dd === today_dd) {
    return (date = `${hour} : ${min}`)
  }
  return (date = `${yyyy}. ${mm}. ${dd} ${hour} : ${min}`)
}

/*
 * 수치 압축 표현
 * 1000 = 1k
 * 10,000 = 10k
 * 10000k = 10m
 */
// export const converseUnitEng = (num) => {
//   if (typeof num !== 'number') {
//     return num
//   } else if (num > 99999) {
//     // 1만 부터 압축 표시
//     const unitArray = [
//       { label: '', unit: 1 },
//       { label: 'k', unit: 1000 },
//       { label: 'm', unit: 1000000 },
//     ]

//     let i = 1
//     for (; i < unitArray.length; i++) {
//       const _unit = unitArray[i].unit / unitArray[i - 1].unit
//       if (num < _unit) {
//         break
//       }
//       num /= _unit
//     }

//     if ((Math.floor(num * 10) / 10).toString().length > 3) {
//       return Math.floor(Math.floor(num * 10) / 10) + unitArray[i - 1].label
//     }
//     return Math.floor(num * 10) / 10 + unitArray[i - 1].label
//   } else {
//     return convertToPricingComma(num.toString())
//   }
// }

export const converseUnitEng = (num, isKor = false) => {
  let label = ''
  if (num === null) {
    return 0
  }
  if (num <= 99999) {
    // 100,000 이하 숫자는 x,xxx 숫자 표현
    const str = num.toString()
    return (
      (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
    )
  }
  if (num > 9999999) {
    // 1,000,000 이하 숫자는 xxx.xm으로 표현
    label = 'm'
    if (isKor) label = '만'
    return (Math.floor((num / 1000000) * 10) / 10).toFixed(1) + label
  }
  if (num > 99999) {
    // 100,000 이하 숫자는 xxx.xk로 표현
    label = 'k'
    if (isKor) label = '천'
    return (Math.floor((num / 1000) * 10) / 10).toFixed(1) + label
  }
}

export const converseCommentUnitEng = num => {
  let label = ''
  if (num === null) {
    return 0
  }
  if (num <= 9999) {
    // 10,000 이하 숫자는 x,xxx 숫자 표현
    const str = num.toString()
    return (
      (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
    )
  }
  if (num > 999999) {
    // 100,000 이하 숫자는 xxx.xm으로 표현
    label = 'm'
    return Math.floor((num / 1000000) * 10) + label
  }
  if (num > 9999) {
    // 10,000 이하 숫자는 xxx.xk로 표현
    label = 'k'
    return Math.floor((num / 1000) * 10) + label
  }
}

// x,xxx 숫자 표현
export const convertToPricingComma = str => {
  let value = str
  if (typeof str === 'number') {
    value = value.toString()
  }
  return (
    (value && value.replace && value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
    ''
  )
}

export function findUrlInText(text) {
  const returnUrlList = []
  const urlRegex = /(https?:\/\/[^\s]+)/g
  text.replace(urlRegex, function (url) {
    returnUrlList.push(url)
  })

  return returnUrlList
}

export const insightConverseUnitEng = num => {
  let label = ''
  if (num <= 99999) {
    // 100,000 이하 숫자는 x,xxx 숫자 표현
    const str = num === null ? '0' : num.toString()
    return (
      (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
    )
  }
  if (num > 99999999) {
    // 100,000,000 이상 xxx.xm
    label = 'm'
    return parseFloat(((num / 1000000) * 10) / 10).toFixed(1) + label
  }
  if (num > 9999999) {
    // 10,000,000 ~ 100,000,000 xx.xxm 으로 표현
    label = 'm'
    return parseFloat(((num / 1000000) * 10) / 10).toFixed(2) + label
  }
  if (num > 999999) {
    // 1,000,000 ~ 10,000,000 x.xxxm 으로 표현
    label = 'm'
    return parseFloat(((num / 1000000) * 10) / 10).toFixed(3) + label
  }
  if (num > 99999) {
    // 100,000 ~ 1,000,000 xxx.xk 로 표현
    label = 'k'
    return parseFloat(((num / 1000) * 10) / 10).toFixed(1) + label
  }
}

/**
0 ~ 99,999 까지: ‘회’로 표시
100,000 ~ 999,999 까지: 천회 / +소수점 1자리 (100.1천회 ~ 999.9천회)
1,000,000 ~ 99,999,999 까지: 만회 / + 소수점 1자리 (99,999
100,000,000 ~: 억회 / + 소수점 2자리
 */
export const converseUnitEngByYoutube = num => {
  let label = ''

  if (num === null) {
    return '0'
  }

  if (num >= 100000000) {
    // 억 이상 단위
    label = '억'
    const formattedNum = (Math.floor(num / 1000000) / 100).toFixed(2)
    return `${formattedNum} ${label}`
  }

  if (num >= 1000000) {
    // 천만 단위
    label = '만'
    const formattedNum = (Math.floor(num / 1000) / 10).toFixed(2)
    return `${formattedNum} ${label}`
  }

  if (num >= 100000) {
    // 백만 단위
    label = '천'
    const formattedNum = (Math.floor(num / 100) / 10).toFixed(1)
    return `${formattedNum} ${label}`
  }

  // 만 단위까지
  label = ''
  const formattedNum = num?.toLocaleString()
  return `${formattedNum} ${label}`
}
