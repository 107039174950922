import React from 'react'
import { Link } from 'react-router-dom'
import { handleTrackImgError } from '@utils/handler'
import { like_gray_img } from '@images'
import {
  converseUnitEngByYoutube,
  convertToPricingComma,
  dateYMD,
} from '@utils/format'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'
import { Flex, Typography, Caption1, Caption2, H6 } from '../../atoms'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const ArtistVideoItem = ({ item, index }) => {
  const thumbnailUrl = STORAGE_URL + item?.youtubeVideoInfo?.image128Path

  return (
    <Link to={`/track/${item?.trackId}?youtubeIdx=${item?.index}`}>
      <Flex
        style={{
          gap: 8,
          height: 97,
          padding: '4px 0',
        }}
      >
        <Flex
          style={{ minWidth: 24, paddingTop: 20 }}
          align="flex-start"
          justify="center"
        >
          <H6>{index + 1}</H6>
        </Flex>
        <Flex
          type="column"
          style={{
            flex: 1,
            borderBottom: '1px solid #f4f4f4',
          }}
        >
          <Flex
            style={{
              gap: 8,
              width: '100%',
            }}
          >
            <Image
              size={['96px', '54px']}
              src={thumbnailUrl}
              onError={handleTrackImgError}
            />
            <Flex
              type="column"
              style={{
                flex: 1,
                gap: 2,
              }}
            >
              <Caption1 style={ellipsisStyle} align="left">
                {item?.youtubeVideoInfo?.title}
              </Caption1>
              <Caption2 color="#949494" style={ellipsisStyle} align="left">
                {item?.youtubeVideoInfo?.channelTitle}
              </Caption2>
              <Caption2 style={ellipsisStyle} color="#949494" align="left">
                {dateYMD(item?.youtubeVideoInfo?.publishedAt, true)}
              </Caption2>
            </Flex>
          </Flex>
          <Flex style={{ margin: '8px 0 6px 0', gap: 14 }}>
            <Flex justify="space-between" align="center" style={{ gap: 4 }}>
              <Caption1 type="Regular">
                {`조회수 ${converseUnitEngByYoutube(
                  item?.youtubeVideoInfo?.viewCount || 0,
                  true,
                )}회`}
              </Caption1>
              <Flex align="center">
                {item?.youtubeVideoInfo?.increaseValue?.viewCount === 0 ? (
                  <Caption1 color="#949494">-</Caption1>
                ) : item?.youtubeVideoInfo?.increaseValue?.viewCount > 0 ? (
                  <Flex style={{ marginBottom: 4 }}>
                    <IconUp />
                  </Flex>
                ) : (
                  <Flex style={{ marginTop: 6 }}>
                    <IconDown />
                  </Flex>
                )}
                <Caption1 color="#949494" style={{ marginLeft: 6 }}>
                  {convertToPricingComma(
                    item?.youtubeVideoInfo?.increaseValue?.viewCount || 0,
                  )}
                </Caption1>
              </Flex>
            </Flex>
            <Flex justify="space-between" align="center" style={{ gap: 4 }}>
              <Caption1 type="Regular">
                {`좋아요 ${converseUnitEngByYoutube(
                  item?.youtubeVideoInfo?.likeCount || 0,
                  true,
                )}회`}
              </Caption1>
              <Flex align="center">
                {item?.youtubeVideoInfo?.increaseValue?.likeCount === 0 ? (
                  <Caption1 color="#949494">-</Caption1>
                ) : item?.youtubeVideoInfo?.increaseValue?.likeCount > 0 ? (
                  <Flex style={{ marginBottom: 4 }}>
                    <IconUp />
                  </Flex>
                ) : (
                  <Flex style={{ marginTop: 6 }}>
                    <IconDown />
                  </Flex>
                )}
                <Caption1 color="#949494" style={{ marginLeft: 6 }}>
                  {convertToPricingComma(
                    item?.youtubeVideoInfo?.increaseValue?.likeCount || 0,
                  )}
                </Caption1>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}

export default ArtistVideoItem

const IconUp = styled(Flex)`
  width: 0;
  height: 0;
  border-bottom: 5px solid #66bc6a;
  border-top: 5px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`
const IconDown = styled(Flex)`
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid #ea4653;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`
