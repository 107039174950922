import * as amplitude from '@amplitude/analytics-browser';

const page = '/valuation'

export const amplitudeEvent = {
  CLICK_PREPAID: () => {
    _track('Click prepaid', {page})
  },
  CLICK_DETAIL_VALUATION: () => {
    _track('Click detail valuation', {page})
  },
  CLICK_EVERAGE_YEAR: (year) => {
    _track('Click everage year', {page, year})
  },
  ENTER_DIRECTLY: () => {
    _track('Enter directly', {page})
  },
  ENTER_DIRECTLY_COMPLITE: ({profit, type}) => {
    _track('Enter directly complite', {page, profit, type})
  },
  SLIDE: ({profit}) => {
    _track('slide', {page, profit})
  }
}

const _track = (label, params) => {
  console.log('[amplitudeEvent]',label, params)

  if(process.env['NODE_ENV'] === 'development'){
    return
  }
  amplitude.track(label, params)
}