import React, { useEffect, useState } from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { H6, Flex } from '@components/atoms'
import {
  KakaoLoginBtn,
  FacebookLoginBtn,
  GoogleLoginBtn,
  AppleLoginBtn,
  ArtistLoginBtn,
} from '@components/molecules'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'
import { like_artist_img, like_track_img } from '@images/index'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 1; }
`

const AnimationPopup = styled.div`
  animation-name: ${boxFade};
  animation-duration: 1s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;

  position: absolute;
  z-index: 9999;
  /* top: 0; */
  top: ${props => props.scrollHeight - 90}px;
  left: 0;
  width: 100%;
  height: 120vh;
  backdrop-filter: blur(4.1px);
  background-color: rgba(36, 36, 36, 0.7);
`

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 80px);
  height: 288px;
  left: 40px;
  /* top: 116px; */
  top: 20%;
  background: white;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LikePopup = ({ handleClose, type='artist' }) => {
  const { authStore } = useStore()

  const neverShowPopup = async () => {
    if (type === 'artist') {
      authStore.isNeverShowLikeArtistPopup = true
    }
    else {
      authStore.isNeverShowLikeTrackPopup = true
    }
    handleClose()
  }


  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AnimationPopup scrollHeight={scrollPosition}>
      <PopupInner>
        {
          type==='artist'
            ? (
              <img
                src={like_artist_img}
                alt="like_artist"
                style={
                  {
                    width: 136,
                    height: 112,
                    // margin: '8px 0px 32px 0px',
                    alignSelf: 'center',
                  }
                }
              />

            )
            : (
              <img
                src={like_track_img}
                alt="like_track"
                style={
                  {
                    width: 136,
                    height: 112,
                    // margin: '8px 0px 32px 0px',
                    alignSelf: 'center',
                  }
                }
              />

            )
        }

        <Flex style={{ marginBottom: '32px', marginTop: '8px' }} type={'column'}>
          <H6>홈 화면에</H6>
          <H6>{`좋아요한 ${type === 'artist' ? '아티스트' :'트랙 데이터'}가 생겼어요!`}</H6>
        </Flex>

        <H6
          onClick={handleClose}
          type={'Regular'}
          color='#ffffff'
          style={
            {
              width:'124px',
              height:'36px',
              background: '#242424',
              justifyContent:'center',
              alignItems:'center',
              borderRadius: '22px',
              display: 'flex',
              cursor: 'pointer',
            }
          }
        >
          확인
        </H6>

        <H6
          onClick={neverShowPopup}
          color="#ffffff"
          style={
            {
              textDecoration: 'underline',
              cursor: 'pointer',
              position: 'absolute',
              top: '318px',
            }
          }
        >
          다시보지 않기
        </H6>
      </PopupInner>
    </AnimationPopup>
  )
}

export default LikePopup
