import React, { useEffect } from 'react'
import { SearchTemplate } from '@components/templates'
import { observer, inject } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

const SearchPage = ({ searchStore }) => {
  const updateSearchKeyword = searchStore.updateSearchKeyword || (() => {})
  const updateIsSearchMode = searchStore.updateIsSearchMode || (() => {})
  const updateIsSearched = searchStore.updateIsSearched || (() => {})
  const fetchSearchKeyword = searchStore.fetchSearchKeyword || (() => {})

  const searchList = searchStore.searchList || null

  const location = useLocation()

  const { keyword } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  useEffect(() => {
    const initSearch = async () => {
      if (keyword) {
        await updateSearchKeyword(keyword)
        await updateIsSearchMode('searched')
        await updateIsSearched(true)
      }
      // await fetchSearchKeyword(match.params.keyword)
      await fetchSearchKeyword(keyword)
    }

    initSearch()
  }, [keyword])

  if (searchList) {
    return <SearchTemplate />
  }
  return <div>검색중..</div>
}

export default inject('searchStore')(observer(SearchPage))
