import { observable } from 'mobx'
import { STORAGE_URL } from '@consts'
import { AlbumModel, TrackModel } from '.'

export default class ArtistModel {
  /* Database fields */
  @observable _id

  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable description
  @observable facebookUrl
  @observable instagramUrl
  @observable youtubeUrl
  @observable soundcloudUrl
  @observable homepageUrl
  @observable emailUrl
  @observable etcSocialUrl
  @observable twitterUrl
  @observable weverseUrl
  @observable tiktokUrl

  @observable spotifyUrl
  @observable youtubeMusicUrl
  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl
  @observable vibeUrl
  @observable floUrl
  @observable appleMusicUrl
  @observable amazonMusicUrl
  @observable tidalUrl
  @observable deezerUrl
  @observable jooxUrl
  @observable mymusicUrl
  @observable kkboxUrl
  @observable linejpUrl
  @observable linetwUrl
  @observable yandexUrl
  @observable nctUrl
  @observable zingUrl
  @observable anghmiUrl

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable userId
  @observable uniqueName
  @observable isAuto
  @observable likeCount

  @observable becauseOfAlbumId
  @observable becauseOfTrackId
  @observable becauseOfArtistId
  @observable becauseOfUserId

  @observable isDisplayFollowerData
  @observable isDisplayInstagramData
  @observable isDisplayTwitterData
  @observable isDisplayYoutubeData
  @observable isDisplaySoundcloudData
  @observable isDisplayDescription

  @observable isWebPlamUser
  /* Database fields end */

  @observable releaseTrackList = []
  @observable releaseAlbumList = []
  @observable releaseVideoList = []
  @observable participationTrackList = []
  @observable participationAlbumList = []
  @observable participationVideoList = []
  @observable totalTrackList = []
  @observable totalAlbumList = []
  @observable totalVideoList = []

  @observable totalTrackCount
  @observable releaseTrackCount
  @observable participationTrackCount
  @observable totalAlbumCount
  @observable releaseAlbumCount
  @observable participationAlbumCount
  @observable totalVideoCount // 전체 비디오 갯수
  @observable releaseVideoCount // 발매 비디오 갯수
  @observable participateVideoCount // 참여 비디오 갯수

  @observable nowLikeCrawling

  @observable subdomainInfo
  @observable stageNameList

  @observable name
  @observable stageName
  @observable agencyInfo
  @observable isInplamOpen
  @observable inplamOpenRequest

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }
  get image512Uri() {
    return `${STORAGE_URL}${this.image512Path || this.imageOriginalPath}`
  }
  get image128Uri() {
    return `${STORAGE_URL}${this.image128Path || this.imageOriginalPath}`
  }
  get image64Uri() {
    return `${STORAGE_URL}${this.image64Path || this.imageOriginalPath}`
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.UPC = props.UPC

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      this.name = props.name
      this.stageName =
        props.stageNameList &&
        props.stageNameList.filter(elem => elem._id === elem.artistId) &&
        props.stageNameList.filter(elem => elem._id === elem.artistId)[0].name

      this.description = props.description
      this.facebookUrl = props.facebookUrl
      this.instagramUrl = props.instagramUrl
      this.youtubeUrl = props.youtubeUrl
      this.soundcloudUrl = props.soundcloudUrl
      this.homepageUrl = props.homepageUrl
      this.emailUrl = props.emailUrl
      this.etcSocialUrl = props.etcSocialUrl
      this.twitterUrl = props.twitterUrl
      this.weverseUrl = props.weverseUrl
      this.tiktokUrl = props.tiktokUrl

      this.spotifyUrl = props.spotifyUrl
      this.youtubeMusicUrl = props.youtubeMusicUrl
      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
      this.vibeUrl = props.vibeUrl
      this.floUrl = props.floUrl
      this.appleMusicUrl = props.appleMusicUrl
      this.amazonMusicUrl = props.amazonMusicUrl
      this.tidalUrl = props.tidalUrl
      this.deezerUrl = props.deezerUrl
      this.jooxUrl = props.jooxUrl
      this.mymusicUrl = props.mymusicUrl
      this.kkboxUrl = props.kkboxUrl
      this.linejpUrl = props.linejpUrl
      this.linetwUrl = props.linetwUrl
      this.yandexUrl = props.yandexUrl
      this.nctUrl = props.nctUrl
      this.zingUrl = props.zingUrl
      this.anghmiUrl = props.anghmiUrl

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.userId = props.userId
      this.uniqueName = props.uniqueName
      this.isAuto = props.isAuto
      this.likeCount = props.likeCount

      this.becauseOfAlbumId = props.becauseOfAlbumId
      this.becauseOfTrackId = props.becauseOfTrackId
      this.becauseOfArtistId = props.becauseOfArtistId
      this.becauseOfUserId = props.becauseOfUserId

      this.subdomainInfo = props.subdomainInfo

      // this.releaseTrackList =
      //   (props.releaseTrackList &&
      //     props.releaseTrackList
      //       .filter((elem) => !!elem)
      //       .map((elem) => new TrackModel(stores, elem))
      //       .sort((a, b) =>
      //         a.releasedAt && b.releasedAt
      //           ? new Date(b.releasedAt).getTime() -
      //             new Date(a.releasedAt).getTime()
      //           : 0,
      //       )) ||
      //   []
      // this.releaseAlbumList =
      //   (props.releaseAlbumList &&
      //     props.releaseAlbumList
      //       .filter((elem) => !!elem)
      //       .map((elem) => new AlbumModel(stores, elem))
      //       .sort((a, b) =>
      //         a.releasedAt && b.releasedAt
      //           ? new Date(b.releasedAt).getTime() -
      //             new Date(a.releasedAt).getTime()
      //           : 0,
      //       )) ||
      //   []
      // this.participationTrackList =
      //   (props.participationTrackList &&
      //     props.participationTrackList
      //       .filter((elem) => !!elem)
      //       .map((elem) => new TrackModel(stores, elem))
      //       .sort((a, b) =>
      //         a.releasedAt && b.releasedAt
      //           ? new Date(b.releasedAt).getTime() -
      //             new Date(a.releasedAt).getTime()
      //           : 0,
      //       )) ||
      //   []
      // this.participationAlbumList =
      //   (props.participationAlbumList &&
      //     props.participationAlbumList
      //       .filter((elem) => !!elem)
      //       .map((elem) => new AlbumModel(stores, elem))
      //       .sort((a, b) =>
      //         a.releasedAt && b.releasedAt
      //           ? new Date(b.releasedAt).getTime() -
      //             new Date(a.releasedAt).getTime()
      //           : 0,
      //       )) ||
      //   []
      this.totalTrackCount = props.totalTrackCount
      this.releaseTrackCount = props.releaseTrackCount
      this.participationTrackCount = props.participationTrackCount
      this.totalAlbumCount = props.totalAlbumCount
      this.releaseAlbumCount = props.releaseAlbumCount
      this.participationAlbumCount = props.participationAlbumCount

      this.releaseVideoCount = props.releaseVideoCount
      this.participateVideoCount = props.participateVideoCount
      this.totalVideoCount = props.totalVideoCount

      this.nowLikeCrawling = props.nowLikeCrawling
      this.isDisplayFollowerData = props.isDisplayFollowerData
      this.isDisplayInstagramData = props.isDisplayInstagramData
      this.isDisplayTwitterData = props.isDisplayTwitterData
      this.isDisplayYoutubeData = props.isDisplayYoutubeData
      this.isDisplaySoundcloudData = props.isDisplaySoundcloudData
      this.isDisplayDescription = props.isDisplayDescription

      this.isWebPlamUser = props.isWebPlamUser

      this.stageNameList = props.stageNameList
      this.agencyInfo = props.agencyInfo
      this.isInplamOpen = props.isInplamOpen
      this.inplamOpenRequest = props.inplamOpenRequest
    }
  }
}
