import React, { useState, useEffect } from 'react'
import { Flex, Input, H6 } from '@components/atoms'
import {
  search_btn_img_black,
  search_btn_img_gray,
  search_btn_img,
} from '@images'
import styled, { keyframes, css } from 'styled-components'
import { SearchListItem } from '@components/molecules'
import { useLocation, useHistory } from 'react-router-dom'

const searchOn = keyframes`from {transform: translateX(100%);}to {transform: translateX(0);}`
const searchOff = keyframes`from {transform: translateX(-100%);}to {transform: translateX(0);}`

const SearchBox = styled(Flex)`
  height: 56px;
  align-items: center;

  ${props =>
    props.isSearchMode === 'on'
    || props.isSearchMode === 'ing'
    || props.isSearchMode === 'searched'
      ? css`
          box-shadow: 0 1px 2px 0 rgba(36, 36, 36, 0.16);
          animation: ${searchOn} 300ms linear;
          width: 100%;
          position: absolute;
          background: #ffffff;
          z-index: 8900;
        `
      : props.isSearchMode === 'off'
        ? css`
          animation: ${searchOff} 300ms linear;
          width: initial;
        `
        : false}
`

const SearchList = styled(Flex)`
  position: absolute;
  background-color: #ffffff;
  top: 56px;
  width: 100%;
  height: calc(100vh - 56px);
`

const SearchListContent = styled(Flex)`
  flex-direction: column;
  padding: 20px;
  width: 100%;
  > div:first-child {
    padding-top: 0px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
  > div {
    padding: 12px 0;
    cursor: pointer;
  }
`

const SearchArea = ({
  isHome,
  isSearchMode,
  isSearched,
  searchKeyword,
  updateIsSearchMode,
  updateIsSearched,
  updateSearchKeyword,
  autocompleteKeyword,
  fetchSearchKeyword,
  fetchSearchAutoComplete,
  updateSearchCount,
  setBeforeSearchPage,
  beforeSearchPage,
}) => {
  const location = useLocation()
  const history = useHistory()

  const handleSearch = async _searchKeyword => {
    if (_searchKeyword === '' || !_searchKeyword) return
    // await fetchSearchKeyword(_searchKeyword)

    updateSearchKeyword(_searchKeyword)
    updateIsSearchMode('searched')
    updateIsSearched(true)

    // 첫 검색 시, 검색 전 페이지 저장
    if (!isSearched) {
      setBeforeSearchPage(location.pathname)
    }

    history.push(`/search?keyword=${encodeURIComponent(_searchKeyword)}`)
  }

  useEffect(() => {
    // 검색 상태 화면 분기 처리
    if (isSearchMode == 'on' || isSearchMode == 'ing') {
      if (searchKeyword !== '') {
        updateIsSearchMode('ing')
        // document.body.style.overflow = 'hidden'
      }
      else {
        updateIsSearchMode('on')
        // updateIsSearched(false)
      }
    }
    else if (isSearchMode !== 'searched') {
      updateIsSearchMode('off')
      // document.body.style.overflow = 'unset'
      updateIsSearched(false)
    }
  }, [searchKeyword, isSearchMode])

  useEffect(() => {
    updateIsSearchMode('off')
    updateIsSearched(false)
  }, [location])

  return (
    <>
      <SearchBox isSearchMode={isSearchMode}>
        <img
          src={
            isHome
              ? search_btn_img_black
              : isSearchMode === 'on'
                || isSearchMode === 'ing'
                || isSearchMode === 'searched'
                ? search_btn_img_black
                : search_btn_img
          }
          style={
            {
              widht: '32px',
              height: '32px',
              marginRight: '16px',
              cursor: 'pointer',
              marginLeft: isSearchMode ? '20px' : 0,
            }
          }
          onClick={() => updateIsSearchMode('on')}
        />
        {
          (isSearchMode === 'on'
          || isSearchMode === 'ing'
          || isSearchMode === 'searched') && (
            <>
              <Input
                style={
                  {
                    width: '100%',
                    border: 'none',
                    height: '24px',
                    fontSize: '16px',
                    margin: 0,
                    padding: 0,
                  }
                }
                placeholder="아티스트, 앨범, 노래 등 검색"
                onChange={
                  e => {
                    updateSearchKeyword(e.target.value)
                    updateIsSearchMode('ing')
                    // updateIsSearched(false)
                    fetchSearchAutoComplete(e.target.value)
                  }
                }
                onKeyDown={e => e.keyCode === 13 && handleSearch(searchKeyword)}
                value={searchKeyword}
              />
              <H6
                onClick={
                  () => {
                    updateIsSearchMode('off')
                    updateSearchKeyword('')
                    if (isSearchMode === 'searched') {
                      history.push(beforeSearchPage)
                    }
                  }
                }
                style={{ marginRight: '16px', cursor: 'pointer' }}
              >
              취소
              </H6>
            </>
          )
        }
      </SearchBox>
      {
        searchKeyword && isSearchMode === 'ing' && (
          <SearchList>
            <SearchListContent>
              {
                autocompleteKeyword.map((item, index) => (
                  <SearchListItem
                    _onClick={handleSearch}
                    updateSearchCount={updateSearchCount}
                    item={item}
                    key={index}
                  />
                ))
              }
            </SearchListContent>
          </SearchList>
        )
      }
    </>
  )
}

export default SearchArea
