import React, { useEffect, useState } from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { H6, Flex, Caption1 } from '@components/atoms'
import {
  KakaoLoginBtn,
  FacebookLoginBtn,
  GoogleLoginBtn,
  AppleLoginBtn,
  ArtistLoginBtn,
} from '@components/molecules'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'
import { apple_logo, download_qr, like_artist_img, like_track_img, playstore_logo } from '@images/index'
import { ANDROID_STORE_URL, IOS_STORE_URL } from '../../../resources'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 1; }
`

const AnimationPopup = styled.div`
  animation-name: ${boxFade};
  animation-duration: 1s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;

  position: absolute;
  z-index: 9999;
  /* top: 0; */
  top: ${props => props.scrollHeight - 0}px;
  left: 0;
  width: 100%;
  height: 120vh;
  backdrop-filter: blur(4.1px);
  background-color: rgba(36, 36, 36, 0.7);
`

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 80px);
  /* height: 288px;*/
  padding: 24px 0px;
  left: 40px;
  /* top: 116px; */
  top: 20%;
  background: white;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const QrContainer = styled(Flex)`
  marginBottom: 32px;
  marginTop: 8px

  width: 112px;
  background: #eaeaea;
  border-radius: 8px;

  padding: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const AppDownloadPopup = ({ handleClose }) => {
  const { authStore } = useStore()


  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const onPressQRcode = () => {
    const ua = navigator.userAgent.toLowerCase()

    window.location.href = 'https://plamin.page.link/89eQ'
    // ua.indexOf('android') > -1
    //   ? ANDROID_STORE_URL
    // : IOS_STORE_URL
  }

  const onPressDownloadButton = ({type = 'android'}) => {


    window.location.href = type === 'android'
      ? ANDROID_STORE_URL
      : IOS_STORE_URL
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AnimationPopup scrollHeight={scrollPosition}>
      <PopupInner>
        <H6
          style={
            {
              whiteSpace: 'pre-line'
            }
          }
        >
          {'해당 기능은 플램 앱을 통해\n제공하고 있습니다.'}
        </H6>
        <QrContainer style={{ marginBottom: '24px', marginTop: '20px', cursor: 'pointer' }} type={'column'} onClick={onPressQRcode}>
          <img
            src={download_qr}
            alt="download_qr"
            style={
              {
                width: 110,
                height: 110,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                borderRadius: '8px'
              }
            }
          />


        </QrContainer>

        <Caption1
          onClick={()=> {onPressDownloadButton({type : 'android'})}}
          color='#242424'
          style={
            {
              width:'160px',
              height:'40px',
              justifyContent:'center',
              borderRadius: '4px',
              borderColor: '#eaeaea',
              borderWidth:  '1px',
              borderStyle: 'solid',
              alignItems:'center',
              display: 'flex',
              cursor: 'pointer',
              flexDirection:'row',
              marginBottom: '12px'
            }
          }
        >
          <img
            src={playstore_logo}
            alt="playstore_down"
            style={
              {
                width: 16,
                height: 16,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                marginRight: '4px'
              }
            }
          />

          구글 플레이 스토어
        </Caption1>

        <Caption1
          onClick={()=> {onPressDownloadButton({type : 'ios'})}}
          color='#242424'
          style={
            {
              width:'160px',
              height:'40px',
              justifyContent:'center',
              alignItems:'center',
              borderRadius: '4px',
              borderColor: '#eaeaea',
              borderStyle: 'solid',
              borderWidth:  '1px',
              display: 'flex',
              cursor: 'pointer',
              flexDirection:'row',
              marginBottom: '24px'
            }
          }
        >
          <img
            src={apple_logo}
            alt="apple_down"
            style={
              {
                width: 16,
                height: 16,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                marginRight: '4px'
              }
            }
          />

          앱 스토어
        </Caption1>

        <H6
          onClick={handleClose}
          type={'Regular'}
          color='#ffffff'
          style={
            {
              width:'124px',
              height:'36px',
              background: '#242424',
              justifyContent:'center',
              alignItems:'center',
              borderRadius: '22px',
              display: 'flex',
              cursor: 'pointer',
            }
          }
        >
          확인
        </H6>

        {/* <H6
          onClick={neverShowPopup}
          color="#ffffff"
          style={
            {
              textDecoration: 'underline',
              cursor: 'pointer',
              position: 'absolute',
              top: '318px',
            }
          }
        >
          다시보지 않기
        </H6> */}
      </PopupInner>
    </AnimationPopup>
  )
}

export default AppDownloadPopup
