import { Caption1, Flex } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import {
  converseCommentUnitEng,
  convertToPricingComma,
  dateTodayYMDMM,
} from '@utils/format'
import { auth_artist_img, upload_photo_img } from '@images/'
import { STORAGE_URL } from '@consts'

const FanspotScoreRankingListItem = ({ item }) => {
  if (item) {
    return (
      <ItemBox>
        <Flex
          style={
            {
              flex: 1,
            }
          }
        >
          <Caption1
            style={{ width: '48px' }}
            type="Regular"
            color={'#646464'}
            align="center"
          >
            {convertToPricingComma(item?.rank)}
          </Caption1>
          <NameText
            style={{ padding: '0 25px 0 5px' }}
            type="Regular"
            color={'#646464'}
            align="left"
          >
            {
              `${item?.userInfo?.nickname
              || item?.userInfo?.artistInfo?.name
              || item?.userInfo?.account
              || '-'}`
            }
          </NameText>
          <Caption1
            style={{ width: '104px' }}
            type="Bold"
            color={'#646464'}
            align="center"
          >
            {convertToPricingComma(item?.point)}
          </Caption1>
        </Flex>
      </ItemBox>
    )
  }
  return <></>
}

export default FanspotScoreRankingListItem

const ItemBox = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 20px 0 20px 7px;
  border-bottom: solid 1px ${colors.whitef4};
  box-sizing: border-box;
  height: 58px;
  display: flex;
  align-items: center;
`

const NameText = styled(Caption1)`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: left;
  line-height: 18px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`

const CommentCountBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 6px 9px;
  background-color: ${colors.whitef4};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const ThumbnailBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
`

const FromArtistImg = styled.img`
  width: 12px;
  height: 12px;
`
