import React, { useEffect, useState } from 'react'
import { BodyWrapper, Caption1, Flex, H6, Input } from '@components/atoms'
import { plam_logo_img, goback_img, check_empty_img, check_img } from '@images/'
import { RoundBtn } from '@components/molecules'
import { colors } from '@colors/'

const LoginArtistTemplate = ({
  currentUser,
  history,
  jsonWebToken,
  location,
  match,
  login,
  redirectPath,
  duplicateUser,
}) => {
  const [loginInfo, setLoginInfo] = useState({ account: '', password: '' })
  const [errors, setErrors] = useState({
    invalidAccount: false,
    invalidLogin: false,
  })
  const [isAutoLogin, setIsAutoLogin] = useState(false)

  const handleLoginInfo = (key, value) => {
    setLoginInfo(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleValidAccount = account => {
    setLoginInfo(prevState => ({
      ...prevState,
      account,
    }))
  }

  const handleLogin = async (account, password, _isAutoLogin) => {
    const result = await login(account, password, _isAutoLogin)
    if (!result?.data?.isLogin) {
      setErrors(prevState => ({
        ...prevState,
        invalidLogin: true,
      }))
    } else if (result) {
      history.push(localStorage.getItem('plam_current_page'))
    }
  }

  // useEffect(() => {
  //   if (currentUser !== null) {
  //     history.push(localStorage.getItem('plam_current_page'))
  //   }
  // }, [currentUser])

  return (
    <>
      <BodyWrapper
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={goback_img}
          alt="go_back"
          style={{
            width: '24px',
            height: '24px',
            alignSelf: 'end',
            cursor: 'pointer',
          }}
          onClick={() => history.goBack()}
        />
        <img
          src={plam_logo_img}
          alt="logo_img"
          style={{ width: '167px', height: '48px', margin: '53px 0 40px 0' }}
          onClick={() => (window.location.href = '/')}
        />
        <Flex type="column" style={{ width: '100%' }}>
          <Flex>
            <Input
              placeholder="가입한 아이디 입력"
              style={{ width: '100%', height: 48, boxSizing: 'border-box' }}
              value={loginInfo.account}
              onChange={e => handleLoginInfo('account', e.target.value)}
              onBlur={() => handleValidAccount(loginInfo.account)}
            />
            {errors?.invalidAccount && (
              <Caption1
                align="left"
                color={colors.lightish_red}
                style={{ marginTop: 8 }}
              >
                아이디 형식이 올바르지 않습니다.
              </Caption1>
            )}
          </Flex>
          <Flex
            type="column"
            style={{ width: '100%', margin: '20px 0 26px 0' }}
          >
            <Input
              placeholder="비밀번호"
              style={{
                width: '100%',
                height: 48,
                boxSizing: 'border-box',
              }}
              type="password"
              value={loginInfo.password}
              onChange={e => handleLoginInfo('password', e.target.value)}
            />
            {errors?.invalidLogin && (
              <Caption1
                align="left"
                color={colors.lightish_red}
                style={{ marginTop: 8 }}
              >
                아이디 혹은 비밀번호가 일치하지 않습니다.
                <br />
                입력한 내용을 다시 확인해 주세요.
              </Caption1>
            )}
          </Flex>
          <Flex
            justify="center"
            align="center"
            style={{ width: '100%' }}
            onClick={() => setIsAutoLogin(!isAutoLogin)}
          >
            <img
              src={isAutoLogin ? check_img : check_empty_img}
              alt="check_box"
              style={{ width: 24, height: 24, marginRight: 4 }}
            />
            <H6>로그인 상태 유지</H6>
          </Flex>
          <Flex justify="center" style={{ marginTop: 30 }}>
            <Caption1>
              <Caption1
                color="#1982df"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/auth/find-id')}
              >
                아이디
              </Caption1>
              &nbsp;또는&nbsp;
              <Caption1
                color="#1982df"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/auth/find-password')}
              >
                비밀번호
              </Caption1>
              를 잊으셨습니까?
            </Caption1>
          </Flex>
          <Flex justify="center" style={{ marginTop: 34, width: '100%' }}>
            <RoundBtn
              onClick={() =>
                handleLogin(loginInfo.account, loginInfo.password, isAutoLogin)
              }
            >
              로그인
            </RoundBtn>
          </Flex>
        </Flex>
      </BodyWrapper>
    </>
  )
}

export default LoginArtistTemplate
