import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Caption1, H5 } from '@components/atoms'
import { move_btn_img, move_prev_btn_img } from '@images'
import { Link } from 'react-router-dom'
import { default_album_img } from '@images/'
import SearchArtistList from './SearchArtistList'
import SearchAlbumList from './SearchAlbumList'
import SearchTrackList from './SearchTrackList'
import { observer, inject } from 'mobx-react'
import {
  SearchTrackItem,
  SearchAlbumItem,
  SearchArtistItem,
} from '@components/molecules'

const Section = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 36px;

  > div:first-child {
    padding-top: 0px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
  > div {
    padding: 16px 0;
    cursor: pointer;
  }
`

const List = styled(Flex)`
  flex-direction: column;

  > div:first-child {
    padding-top: 16px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
  > div {
    padding: 6px 0;
    cursor: pointer;
  }
`

//  검색 결과 리스트 컴포넌트
const SearchResultList = ({ searchStore }) => {
  /*
   * 더보기 분기 상태
   * : 'total', 'artist', 'album', 'track'
   */

  const searchKeyword = searchStore.searchKeyword || null
  const more = searchStore.more || null
  const updateIsSearchMode = searchStore.updateIsSearchMode || (() => {})
  const setMore = searchStore.setMore || (() => {})

  const searchList = searchStore.searchList || null
  const autocompleteKeyword = searchStore.autocompleteKeyword || null
  const searchArtistList = searchStore.searchArtistList || null
  const searchAlbumList = searchStore.searchAlbumList || null
  const searchTrackList = searchStore.searchTrackList || null

  const firstFetchSearchDetail =
    searchStore.firstFetchSearchDetail || (() => {})
  const moreFetchSearchDetail = searchStore.moreFetchSearchDetail || (() => {})

  const showDetail = (type) => {
    if (more === type) {
      setMore('total')
    } else {
      setMore(type)
    }
  }

  if (more === 'total') {
    return (
      <Section>
        <Flex type="column">
          <Flex
            style={{ alignItems: 'center' }}
            onClick={() => showDetail('artist')}
            style={{ paddingTop: '20px', alignItems: 'center' }}
          >
            <H5 type="Bold" align="left">
              아티스트
            </H5>
            <img src={move_btn_img} style={{ widht: '20px', height: '20px' }} />
          </Flex>
          <List>
            {searchList.artistList && searchList.artistList.length >= 1 ? (
              searchList.artistList.map((item, index) => {
                return (
                  <SearchArtistItem
                    item={item}
                    key={item._id}
                    setIsSearch={updateIsSearchMode}
                  />
                )
              })
            ) : (
              <Caption1 align="left">검색 결과가 없습니다.</Caption1>
            )}
          </List>
        </Flex>
        <Flex type="column">
          <Flex
            style={{ alignItems: 'center' }}
            onClick={() => showDetail('track')}
          >
            <H5 type="Bold" align="left">
              트랙
            </H5>
            <img src={move_btn_img} style={{ widht: '20px', height: '20px' }} />
          </Flex>
          <List>
            {searchList.trackList && searchList.trackList.length >= 1 ? (
              searchList.trackList.map((item, index) => {
                return (
                  <SearchTrackItem
                    key={item._id}
                    item={item}
                    setIsSearch={updateIsSearchMode}
                  />
                )
              })
            ) : (
              <Caption1 align="left">검색 결과가 없습니다.</Caption1>
            )}
          </List>
        </Flex>
        <Flex type="column">
          <Flex
            style={{ alignItems: 'center' }}
            onClick={() => showDetail('album')}
          >
            <H5 type="Bold" align="left">
              앨범
            </H5>
            <img src={move_btn_img} style={{ widht: '20px', height: '20px' }} />
          </Flex>
          <List>
            {searchList.albumList && searchList.albumList.length >= 1 ? (
              searchList.albumList.map((item, index) => {
                return (
                  <SearchAlbumItem
                    item={item}
                    key={item._id}
                    setIsSearch={updateIsSearchMode}
                  />
                )
              })
            ) : (
              <Caption1 align="left">검색 결과가 없습니다.</Caption1>
            )}
          </List>
        </Flex>
      </Section>
    )
  } else if (more === 'artist') {
    return (
      <Section>
        <SearchArtistList
          showDetail={showDetail}
          setIsSearch={updateIsSearchMode}
          searchArtistList={searchArtistList}
          keyword={searchKeyword}
          firstFetchSearchDetail={firstFetchSearchDetail}
          moreFetchSearchDetail={moreFetchSearchDetail}
        />
      </Section>
    )
  } else if (more === 'track') {
    return (
      <Section>
        <SearchTrackList
          showDetail={showDetail}
          setIsSearch={updateIsSearchMode}
          searchTrackList={searchTrackList}
          keyword={searchKeyword}
          firstFetchSearchDetail={firstFetchSearchDetail}
          moreFetchSearchDetail={moreFetchSearchDetail}
        />
      </Section>
    )
  } else if (more === 'album') {
    return (
      <Section>
        <SearchAlbumList
          showDetail={showDetail}
          setIsSearch={updateIsSearchMode}
          searchAlbumList={searchAlbumList}
          keyword={searchKeyword}
          firstFetchSearchDetail={firstFetchSearchDetail}
          moreFetchSearchDetail={moreFetchSearchDetail}
        />
      </Section>
    )
  }
}

export default inject('searchStore')(observer(SearchResultList))
