import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { H6, Caption1, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: white;
  overflow: auto;
  width: 340px;
  min-height: 104px;

  height: fit-content;
  top: 50%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  :-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background-color: transparent;
  color: #646464;
  cursor: pointer;
  margin-right: 24px;
`

const Okbtn = styled.button`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.confirmColor};
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onConfirm,
  onCancel,
  isShowCancel = false,
  confirmText = '확인',
  cancelText = '취소',
  confirmColor = '#242424',
  cancelColor = '#242424',
  title,
  body,
}) => {
  const handleOk = () => {
    if (onConfirm) {
      onConfirm()
    }
    toggle()
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H6
                type="Regular"
                align="left"
                style={{ whiteSpace: 'pre-line' }}
              >
                {title}
              </H6>
              <Caption1 color="#949494" style={{ whiteSpace: 'pre' }}>
                {body}
              </Caption1>
              <Flex style={{ justifyContent: 'flex-end', marginTop: '30px' }}>
                {
                  isShowCancel && (
                    <CancelBtn
                      onClick={handleCancel}
                      confirmColor={cancelColor}
                    >
                      <H6>{cancelText}</H6>
                    </CancelBtn>
                  )
                }
                <Okbtn onClick={handleOk} confirmColor={confirmColor}>
                  <H6>{confirmText}</H6>
                </Okbtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
