import { ArtistSnsListenDetailTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ArtistSnsListenDetailPage = () => {
  const params = useParams()

  const artistId = params?.id
  const type = params?.type

  const { trendStore, artistStore } = useStore()
  const { artistTrend } = trendStore
  const { artistDetail } = artistStore
  const fetchArtistTrend = trendStore.fetchArtistTrend || (() => {})
  const fetchArtistTrendTrack = trendStore.fetchArtistTrendTrack || (() => {})
  const fetchArtistDetail = artistStore.fetchArtistDetail || (() => {})

  const [periodType, setPeriodType] = useState(28)

  useEffect(() => {
    if (!artistDetail && artistId) {
      fetchArtistDetail(artistId)
    }
  }, [artistId])

  useEffect(() => {
    if (artistDetail) {
      fetchArtistTrend(artistDetail?._id, { __periodDays: periodType })
      if (type === 'track') {
        fetchArtistTrendTrack(artistDetail?._id, { __periodDays: periodType })
      }
    }
  }, [artistDetail, periodType, fetchArtistTrend, fetchArtistTrendTrack, type])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ArtistSnsListenDetailTemplate
      artist={artistDetail}
      artistTrend={artistTrend}
      periodType={periodType}
      setPeriodType={setPeriodType}
    />
  )
}

export default observer(ArtistSnsListenDetailPage)
