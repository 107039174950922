import React from 'react'
import styled from 'styled-components'
import { share_img } from '@images'

const SharedImg = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const SharedBtn = (props) => {
  return (
    <SharedImg
      style={props.style}
      onClick={props && props.onClick}
      src={share_img}
      alt="share"
    />
  )
}

export default SharedBtn
