import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import {
  ArtistTrendModel,
  ArtistTrendTrackModel,
  ArtistTrackTrendModel,
} from './models'

export default class TrendStore {
  @observable artistTrend
  @observable artistTrendTrackList
  @observable artistTrackTrend

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistTrend = null
    this.artistTrendTrackList = []

    this.artistTrackTrend = null
  }

  @action.bound
  async fetchArtistTrend(aritstObjectId, params) {
    return this.store.useLoading(async () => {
      if (!aritstObjectId || !params) {
        this.init()
        return
      }

      const { data } = await this.network.trendNetwork.getTrendArtistAPI(
        aritstObjectId,
        params,
      )

      if (data?.artist) {
        this.artistTrend = new ArtistTrendModel(this.store, data.artist)
      } else {
        this.artistTrend = null
      }
    })
  }

  @action.bound
  async fetchArtistTrendTrack(aritstObjectId, params) {
    return this.store.useLoading(async () => {
      if (!aritstObjectId || !params) {
        this.init()
        return
      }

      const { data } = await this.network.trendNetwork.getTrendArtistTrackAPI(
        aritstObjectId,
        params,
      )

      if (data?.trackList) {
        this.artistTrendTrackList = data?.trackList?.map(
          track => new ArtistTrendTrackModel(this.store, track),
        )
      }
    })
  }

  @action.bound
  async fetchArtistTrackTrend(aritstObjectId, trackObjectId, params) {
    return this.store.useLoading(async () => {
      if (!aritstObjectId || !trackObjectId || !params) {
        this.init()
        return
      }

      const {
        data,
      } = await this.network.trendNetwork.getTrendArtistTrackDetailAPI(
        aritstObjectId,
        trackObjectId,
        params,
      )

      if (data?.track)
        this.artistTrackTrend = new ArtistTrackTrendModel(
          this.store,
          data.track,
        )
    })
  }
}
