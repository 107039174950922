import './youtubeInText.css'
import React, { useEffect } from 'react'
import qs from 'qs'

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return (
    text &&
    text.replace(urlRegex, function(url) {
      const query = qs.parse(url, {
        ignoreQueryPrefix: true,
        // 문자열 맨 앞의 ?를 생력
      })

      if (url.indexOf('youtube') !== -1) {
        // if (url.indexOf('youtube') !== -1) {
        if (query[Object.keys(query)[0]]) {
          return `<div class="youtube-in-text">
                    <iframe width="100%" height="100%"
                    src="https://www.youtube.com/embed/${
                      // url.split('watch?v=')[1]
                      query[Object.keys(query)[0]]
                    }?rel=0&modestbranding=1"
                    frameborder="0" allow="accelerometer;
                    autoplay;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
                    allowfullscreen>
                    </iframe></div>`
        }
      }
      if (url.indexOf('youtu.be') !== -1) {
        // if (!query[Object.keys(query)[0]] && url.indexOf('youtu.be') !== -1) {

        return `<div class="youtube-in-text">
                  <iframe width="100%" height="100%"
                  src="https://www.youtube.com/embed/${url
                    .split('youtu.be/')[1]
                    .substr(0, 11)}?rel=0&modestbranding=1"
                  frameborder="0" allow="accelerometer;
                  autoplay;
                  encrypted-media;
                  gyroscope;
                  picture-in-picture"
                  allowfullscreen>
                  </iframe></div>`
      }

      // 인스타그램 링크 처리 (임베드 공유 복사)
      if (
        url.includes('instagram.com/p/') &&
        url.includes('?utm_source=ig_embed')
      ) {
        const embedHtml = `${url}`

        return embedHtml
      }

      // 인스타그램 링크 처리 (임베드 링크 복사)
      if (url.includes('instagram.com/p/')) {
        const embedHtml = `
          <blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14" style="background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);">
            <a href="${url}" target="_blank">Instagram에서 이 게시물 보기</a>
          </blockquote>
          <script async src="//www.instagram.com/embed.js"></script>`

        return embedHtml
      }

      // X (구 Twitter) 링크 처리
      if (
        url.includes('twitter.com') ||
        (url.includes('x.com') && url.includes('?ref_src=twsrc'))
      ) {
        return `${url}`
      }

      // X (구 Twitter) 링크 처리
      if (url.includes('twitter.com') || url.includes('x.com')) {
        if (url.includes('x.com')) {
          return `<blockquote class="twitter-tweet"><a href="${url.replace(
            'x.com',
            'twitter.com',
          )}"></a></blockquote>`
        }
        return `<blockquote class="twitter-tweet"><a href="${url}"></a></blockquote>`
      }

      return url.replace(
        urlRegex,
        '<a style="color: -webkit-link" href="$1" target="_blank">$1</a>',
      )
    })
  )
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

const YoutubeInText = ({ text }) => {
  useEffect(() => {
    // 인스타그램 임베드 스크립트 로드
    if (window.instgrm) {
      window.instgrm.Embeds.process()
    } else {
      const script = document.createElement('script')
      script.async = true
      script.src = '//www.instagram.com/embed.js'
      document.body.appendChild(script)
    }

    // X 임베드 스크립트 로드
    if (window.twttr) {
      window.twttr.widgets.load()
    } else {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://platform.twitter.com/widgets.js'
      document.body.appendChild(script)
    }
  }, [text])

  return (
    <div
      className="youtube-in-text-wrapper"
      dangerouslySetInnerHTML={{
        __html: urlify(text),
      }}
    />
  )
}

export default YoutubeInText
