import React, { useState , useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { H4, H6, H5, Caption1, Flex, Caption2 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { no_select_option_img, select_option_img_black } from '@images/'
import { colors } from '@colors'
import { COMMENT_REPORT_REASON } from '@consts'
import TextareaAutosize from 'react-textarea-autosize'


const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onCancel,
  onOk,
  okText = '확인',
  okColor = '#242424',
  title,
  body,
}) => {
  const [reason, setReason] = useState('')
  const [directValue, setDirectValue] = useState('')

  useEffect(() => {
    return () => {
      setReason('')
      setDirectValue('')
    }
  },[isShowing])

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = () => {
    if (reason === '') return
    if (reason === '기타' && directValue === '') return
    if (onOk) {
      if (reason === '기타') {
        onOk(directValue)
      }
      else {
        onOk(reason)
      }
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold">신고하기</H4>
              <H5 type="Bold" align="left">
                  신고사유
              </H5>
              <ReportSelectBox>
                {
                  COMMENT_REPORT_REASON.map(item => (
                    <ReportOptionBox
                      key={item.value}
                      onClick={() => setReason(item.value)}
                    >
                      <img
                        src={
                          reason === item.value
                            ? select_option_img_black
                            : no_select_option_img
                        }
                        alt="option_box"
                      />
                      <H6 type="Regular" color={colors.brown_grey}>
                        {item.label}
                      </H6>
                    </ReportOptionBox>
                  ))
                }
              </ReportSelectBox>
              <ReportDirectBox value={directValue}>
                <ReportDirectInput
                  minRows={6}
                  maxRows={9}
                  maxLength={500}
                  placeholder="기타 항목 선택 후 입력해주세요"
                  value={directValue}
                  onChange={e => setDirectValue(e.target.value)}
                />
                <CountDirectValue>{`${directValue.length}/500`}</CountDirectValue>
              </ReportDirectBox>
              <Flex style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
                <CancelBtn onClick={handleCancel}>
                  <H6>취소</H6>
                </CancelBtn>
                <Okbtn onClick={handleOk} okColor={okColor}>
                  <H6>신고</H6>
                </Okbtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}

/**
 * style
 */
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: white;
  overflow: scroll;
  width: 340px;
  min-height: 104px;

  height: fit-content;
  top: 50%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background-color: transparent;
  color: #646464;
  cursor: pointer;
  margin-right: 24px;
`

const Okbtn = styled.button`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.okColor};
`

const ReportSelectBox = styled(Flex)`
  flex-direction: column;
  margin: 16px 0px;

  > div {
    margin: 10px 0px;
  }

  > div:first-child {
    margin-top: 0px;
  }

  > div:last-child {
    margin-bottom: 0px;
  }
`

const ReportOptionBox = styled(Flex)`
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`

const ReportDirectBox = styled(Flex)`
  position: relative;
  padding: 10px 12px;

  background-color: #ffffff;
  border: solid 1px #f4f4f4;

  ${props =>
    props.value !== ''
    && css`
      border: solid 1px #242424;
    `}
`

const ReportDirectInput = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  border: none;
  font-family: NotoSansCJKkr-Regular;
`

const CountDirectValue = styled(Caption1)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: NotoSansCJKkr-Regular;
  color: #d4d4d4;
`
