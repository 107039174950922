import React, { useEffect, useState } from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { H6, Flex, Caption1 } from '@components/atoms'
import {
  KakaoLoginBtn,
  FacebookLoginBtn,
  GoogleLoginBtn,
  AppleLoginBtn,
  ArtistLoginBtn,
} from '@components/molecules'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'
import { like_artist_img, like_track_img, referral_img } from '@images/index'
import { STORAGE_URL } from '@consts/'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 1; }
`

const AnimationPopup = styled.div`
  animation-name: ${boxFade};
  animation-duration: 1s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;

  position: absolute;
  z-index: 9999;
  /* top: 0; */
  top: ${props => props.scrollHeight - 90}px;
  left: 0;
  width: 100%;
  height: 120vh;
  backdrop-filter: blur(4.1px);
  background-color: rgba(36, 36, 36, 0.7);

  display: flex;
`

const PopupInner = styled.div`
  position: relative;
  width: 280px;
  min-height: 324px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: white;
  border-radius: 2px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`

const NoticePopup = ({ handleClose, noticePopupList }) => {
  const { authStore } = useStore()

  const neverShowTodayPopup = async () => {
    const neverShowNoticeList =
      JSON.parse(localStorage.getItem('neverShowNoticePopupList')) || []

    const foundIndex = neverShowNoticeList?.findIndex(
      val => val?._id === noticePopupList?.[0]?._id,
    )

    if (foundIndex > -1) {
      neverShowNoticeList[foundIndex].bannedAt = new Date()
    }
    else {
      neverShowNoticeList.push({
        ...noticePopupList?.[0],
        bannedAt: new Date(),
      })
    }
    localStorage.setItem(
      'neverShowNoticePopupList',
      JSON.stringify(neverShowNoticeList),
    )

    handleClose()
    authStore.noticePopupList?.splice(0, 1)
  }

  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      document.body.style.overflow = 'auto'
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AnimationPopup scrollHeight={scrollPosition}>
      <PopupInner>
        <Flex style={{ minHeight: '280px', flex: 1 }}>
          {
            <img
              src={noticePopupList?.[0]?.popupImageUrl}
              alt="popupImage"
              style={
                {
                  width: '100%',
                  height: 'auto',
                  // margin: '8px 0px 32px 0px',
                  alignSelf: 'center',
                  cursor: noticePopupList?.[0]?.link ? 'pointer' : null,
                }
              }
              onClick={
                () => {
                  if (noticePopupList?.[0]?.link) {
                    window.location.href = noticePopupList?.[0]?.link
                  }
                }
              }
            />
          }
        </Flex>
        <Flex type={'row'} style={{ width: '100%', alignItems: 'center' }}>
          <Caption1
            onClick={neverShowTodayPopup}
            type={'Regular'}
            color="#949494"
            style={
              {
                display: 'flex',
                cursor: 'pointer',
                flex: 1,
                background: '#ffffff',
                height: '44px',
                justifyContent: 'center',
                alignItems: 'center',
              }
            }
          >
            오늘은 그만 보기
          </Caption1>
          <div style={{ width: 1, height: 20, backgroundColor: '#d4d4d4' }} />
          <Caption1
            onClick={handleClose}
            type={'Regular'}
            color="#242424"
            style={
              {
                height: '44px',
                background: '#ffffff',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                cursor: 'pointer',
                flex: 1,
              }
            }
          >
            확인
          </Caption1>
        </Flex>
      </PopupInner>
    </AnimationPopup>
  )
}

export default NoticePopup
