import { EditProfileTemplate, EditPhotoTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { SERVER_URL } from '@consts/'

const EditProfilePage = ({ history }) => {
  const { authStore, useLoading } = useStore()

  const [isEditProfile, setIsEditProfile] = useState(false)
  const [nickname, setNickname] = useState('')
  const [profileSrc, setProfileSrc] = useState(null)
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: 'px',
    x: 100,
    y: 100,
    width: 200,
    height: 200,
  })
  const [croppedImageUrl, setCroppedImageUrl] = useState()
  const [beforePostImage, setBeforePostImage] = useState(null)

  const uploadInput = () => {
    document.getElementById('upload_input').click()
  }

  const onSelectFile = e => {
    // 파일이 등록되면
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 50 * 1024 * 1024) {
        alert('파일 용량은 50MB까지 가능합니다.')
        return
      }
      // HTML5 의 FileAPI 를 사용한다
      // FileReader 객체를 reader 에 저장
      const reader = new FileReader()

      // readAsDataURL로 파일을 읽는다
      reader.readAsDataURL(e.target.files[0])

      // readAsDataURL 메서드 실행이 완료되면 onload 이벤트가 발생한다
      // 이 이벤트가 발생하면(읽기가 완료되면) 해당 이미지를 src state에 저장한다
      reader.addEventListener('load', () => {
        setProfileSrc(reader.result)
      })
      setIsEditProfile(true)
      window.scrollTo(0, 0)
    }
  }

  const getPresignedPostData = selectedFile => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      // Set the proper URL here.
      const url = `${SERVER_URL}/file`

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', `jwt ${authStore.jsonWebToken}`)
      xhr.send(
        JSON.stringify({
          name: selectedFile.name,
          type: selectedFile.type,
          isArticle: false,
        }),
      )
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText))
      }
    })
  }

  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        // console.log(key, ' : ', presignedPostData.fields[key])
        formData.append(key, presignedPostData.fields[key])
      })
      // Actual file has to be appended last.
      formData.append('file', file)
      const xhr = new XMLHttpRequest()
      // xhr.upload.addEventListener(
      //   'progress',
      //   function(event) {
      //     if (event.lengthComputable) {
      //       setPercent(Math.floor((100 * event.loaded) / event.total))
      //     }
      //   },
      //   false,
      // )

      xhr.open('POST', presignedPostData.url, true)
      xhr.send(formData)
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText)
      }
    })
  }

  const hanldeUpload = async () => {
    const { data: presignedPostData } = await getPresignedPostData(
      croppedImageUrl,
    )

    try {
      await uploadFileToS3(presignedPostData, croppedImageUrl)

      if (
        presignedPostData
        && presignedPostData.fields
        && presignedPostData.fields.key
      ) {
        return presignedPostData.fields.key
      }
    }
    catch (e) {
      console.log('An error occurred!', e.message)
      return false
    }
  }

  if (authStore.currentUser) {
    return (
      <>
        <input
          id="upload_input"
          type="file"
          onChange={onSelectFile}
          style={{ display: 'none' }}
          accept="image/*"
        />
        {
          !isEditProfile ? (
            <EditProfileTemplate
              history={history}
              currentUser={authStore.currentUser || null}
              updateUser={authStore.updateUser || (() => {})}
              editProfile={uploadInput}
              beforePostImage={beforePostImage}
              hanldeUpload={hanldeUpload}
              croppedImageUrl={croppedImageUrl}
              fetchAuthUser={authStore.login || (() => {})}
              useLoading={useLoading}
              nickname={nickname}
              setNickname={setNickname}
            />
          ) : (
            <EditPhotoTemplate
              src={profileSrc}
              currentUser={authStore.currentUser || null}
              setSrc={setProfileSrc}
              crop={crop}
              setCrop={setCrop}
              croppedImageUrl={croppedImageUrl}
              setCroppedImageUrl={setCroppedImageUrl}
              setIsEditProfile={setIsEditProfile}
              hanldeUpload={hanldeUpload}
              setBeforePostImage={setBeforePostImage}
            />
          )
        }
      </>
    )
  }
  return <Redirect to="/" />
}

export default observer(EditProfilePage)
