import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2, Caption1, H6 } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import { IncreaseTextBox } from '@components/molecules'
import {
  melon_follower_img,
  instagram_follower_img,
  twitter_follower_img,
  soundcloud_follower_img,
  youtube_follower_img,
  no_melon_card_img,
} from '@images/'

const Box = styled(Flex)`
  width: 100%;
  min-width: 240px;
  height: 103px;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 12px;
  box-sizing: border-box;

  img {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
`

const SnsFollowerCard = ({
  snsType,
  data,
  periodData,
  unit,
  onClick,
  isListen = false,
}) => {
  return (
    <>
      {
        data && data[`${snsType}`] !== null ? (
          <Box
            type="column"
            style={
              {
                justifyContent: 'space-between',
                cursor: onClick ? 'pointer' : 'default',
              }
            }
            onClick={onClick}
          >
            <Flex>
              <img
                src={
                  snsType === 'melon'
                    ? melon_follower_img
                    : snsType === 'instagram'
                      ? instagram_follower_img
                      : snsType === 'youtube'
                        ? youtube_follower_img
                        : snsType === 'twitter'
                          ? twitter_follower_img
                          : snsType === 'soundcloud'
                            ? soundcloud_follower_img
                            : false
                }
                alt="sns_img"
              />
              <H6
                color="#242424"
                type="Bold"
                style={{ alignSelf: 'center', textTransform: 'capitalize' }}
              >
                {snsType}
              </H6>
            </Flex>
            <Flex>
              <Flex type="column">
                <Caption2
                  align="left"
                  color="#949494"
                  style={{ marginBottom: '4px' }}
                >
                  {isListen ? 'Daily Listen' : 'Total Follower'}
                </Caption2>
                <H6 type="Bold" align="left">
                  {
                    `${data && convertToPricingComma(data[`${snsType}`] || 0)} ${
                      unit ? unit : 'followers'
                    }`
                  }
                </H6>
              </Flex>

              <IncreaseTextBox
                data={periodData && periodData[`${snsType}`]}
                style={{ alignSelf: 'flex-end', margin: '0 0 0 16px' }}
              />
            </Flex>
          </Box>
        ) : snsType === 'melon' ? (
          <Box
            type="column"
            style={
              {
                justifyContent: 'space-between',
                cursor: onClick ? 'pointer' : 'default',
                maxWidth: 172,
                minWidth: 'initial',
              }
            }
          >
            <Flex>
              <img
                src={no_melon_card_img}
                alt="no_melon"
                width={36}
                height={36}
              />
              <Caption2>데이터 분석 요청</Caption2>
            </Flex>
            <Button
              onClick={() => window.open('https://forms.gle/QzrQ42eCmSRBSuMv9')}
            >
            신청하기
            </Button>
          </Box>
        ) : (
          false
        )
      }
    </>
  )
}

export default SnsFollowerCard

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 28px;
  padding: 0 0px;
  border-radius: 2px;
  background-color: #242424;
  color: white;
`
