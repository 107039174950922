import { arrow_right_white_img } from '@images'
import React, { useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

const YoutubeUrlList = ({ opts, list, onReady, onPlay }) => {
  const location = useLocation()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const divRef = useRef(null)
  const [divWidth, setDivWidth] = useState(380)
  const SLIDE_WIDTH = divWidth || 380
  const youtubeIdx = Number(query?.youtubeIdx || '0')

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const width = divRef.current.offsetWidth
        setDivWidth(width)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const youtubeRef = useRef(null)
  const [activeIdx, setActiveIdx] = useState(youtubeIdx)
  const [transformValue, setTransformValue] = useState(
    youtubeIdx * (-1 * SLIDE_WIDTH - 8),
  )
  const [isPlaying, setIsPlaying] = useState(false)

  const handleLeftClick = () => {
    if (activeIdx > 0) {
      setTransformValue(transformValue + SLIDE_WIDTH + 8)
      setActiveIdx(prevIdx => prevIdx - 1)
    }
  }

  const handleRightClick = () => {
    if (activeIdx < list.length - 1) {
      setTransformValue(transformValue - SLIDE_WIDTH - 8)
      setActiveIdx(prevIdx => prevIdx + 1)
    }
  }

  useEffect(() => {
    let timer

    if (!isPlaying) {
      timer = setTimeout(() => {
        if (activeIdx < list.length - 1) {
          handleRightClick()
        }
        else {
          setTransformValue(0)
          setActiveIdx(0)
        }
      }, 30000)
    }

    return () => clearTimeout(timer)
  }, [isPlaying, activeIdx])

  return (
    <List ref={divRef}>
      {activeIdx !== 0 && <LeftBtn onClick={handleLeftClick} />}
      <ListWrapper transformValue={transformValue}>
        {
list?.map((url, index) => (
  <VideoWrapper key={`${url}_${index}`} slideWidth={SLIDE_WIDTH}>
    <YouTube
      ref={youtubeRef}
      videoId={url.split('watch?v=')[1]}
      opts={opts}
      onReady={e => onReady(e, index)}
      onPlay={
        () => {
          setIsPlaying(true)
          onPlay(url)
        }
      }
      onPause={() => setIsPlaying(false)}
      onEnd={() => setIsPlaying(false)}
      onError={() => setIsPlaying(false)}
      containerClassName="youtube_wrapper"
      className="youtube_player"
    />
  </VideoWrapper>
))
        }
      </ListWrapper>
      {
list?.length > 1 && activeIdx !== list.length - 1 && (
          <RightBtn onClick={handleRightClick} />
        )
      }
    </List>
  )
}

export default YoutubeUrlList

const LeftBtn = ({ onClick }) => {
  return (
    <BtnWrapper direction="left" onClick={onClick}>
      <img
        src={arrow_right_white_img}
        width={16}
        height={16}
        alt="next youtube"
      />
    </BtnWrapper>
  )
}

const RightBtn = ({ onClick }) => {
  return (
    <BtnWrapper onClick={onClick}>
      <img
        src={arrow_right_white_img}
        width={16}
        height={16}
        alt="next youtube"
      />
    </BtnWrapper>
  )
}

const List = styled.div`
  position: relative;
  padding: 10px 0;
  display: flex;
  gap: 8px;
`

const ListWrapper = styled.div`
  width: fit-content;
  padding: 10px 0;
  display: flex;
  gap: 8px;
  transform: translateX(${props => props.transformValue}px);
  transition: transform 0.3s ease-out; // for smooth transition
`

const VideoWrapper = styled.div`
  min-width: ${({ slideWidth }) => slideWidth}px;
  min-height: 214px;
  max-width: ${({ slideWidth }) => slideWidth}px;
  max-height: 214px;
  width: 100%;
  height: 100%;
`

const BtnWrapper = styled.div`
  position: absolute;
  z-index: 2;

  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 50%;
  top: calc(50% - 16px);
  cursor: pointer;

  ${({ direction }) =>
    direction === 'left'
      ? css`
          transform: scaleX(-1);
          left: -16px;
        `
      : css`
          transform: scaleX(1);
          right: -16px;
        `}
`
