import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { LastLocationProvider } from 'react-router-last-location'
import InitializePage from '@utils/InitializePage'

import SwitchRouter from './SwitchRouter'

// const history = createBrowserHistory()

export default InitializePage(({ history }) => {
  return (
    <Router history={history}>
      <LastLocationProvider>
        <SwitchRouter history={history} />
      </LastLocationProvider>
    </Router>
  )
})
