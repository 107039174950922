import { Caption1, Flex } from '@components/atoms'
import React from 'react'
import { STORAGE_URL } from '@consts'
import styled, { css } from 'styled-components'
import { favorite_img } from '@images/index'

const InplamArtistListItem = ({ item, onClick = () => {} }) => {
  return (
    <Box
      isOpen={item?.isInplamOpen}
      isFavorite={item?.isInplamFavorite}
      onClick={onClick}
    >
      <img
        alt={item?._id}
        src={STORAGE_URL + item?.image64Path}
        width={24}
        height={24}
        style={{ borderRadius: '50%' }}
      />
      <Caption1 style={{ wordBreak: 'nowrap' }}>
        {item?.name || item?.artistName || ''}
      </Caption1>
      {item?.isInplamFavorite ? (
        <img src={favorite_img} width={12} height={12} alt="favorite" />
      ) : (
        <></>
      )}
    </Box>
  )
}

export default InplamArtistListItem

const Box = styled(Flex)`
  gap: 6px;
  width: fit-content;
  height: 32px;
  padding: 7px 12px 7px 4px;
  background-color: #242424;
  border: solid 1px #242424;
  color: #ffffff;

  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;

  justify-content: center;
  align-items: center;

  ${({ isOpen, isFavorite }) =>
    isFavorite
      ? css`
          background-color: #242424;
          border: solid 1px #242424;
          color: #ffffff;
        `
      : isOpen &&
        css`
          background-color: #f4f4f4;
          border: solid 1px #d4d4d4;
          color: #242424;
        `}
`
