import React, { useEffect, useState } from 'react'

import styled, { css } from 'styled-components'

import {
  prev_page_img,
  no_next_page_img,
  no_prev_page_img,
  next_page_img,
} from '@images/'

import { colors } from '@colors/'
import { usePagination } from '@utils/hooks'

interface PaginationProps {
  pageLength: Number;
  currentPage: Number;
  totalCount: Number;
  handlePaginate: Function;
}

/**
 * ... 표시 미구현
 */
const CustomPagination = ({
  pageLength,
  totalCount,
  currentPage,
  handlePaginate,
}: PaginationProps) => {
  const [pageList, setPageList] = useState([])

  const [pagination, setPagination] = usePagination({
    current: currentPage,
    total: totalCount,
    defaultPageSize: 10,
    pageSize: 10,
    onChange: handlePaginate,
  })

  useEffect(() => {
    for (let i = 1; i <= Math.ceil(totalCount / pageLength); i++) {
      setPageList(prevState => prevState.concat(i))
    }
  }, [pageLength, totalCount])

  if (pageList && pageList.length > 0) {
    return (
      <Pagination>
        <PrevNextPage
          src={currentPage !== 1 ? prev_page_img : no_prev_page_img}
        />
        {
          pageList.map(pageNum => (
            <PaginationItem
              key={pageNum}
              onClick={() => handlePaginate(pageNum)}
              isActive={currentPage === pageNum}
            >
              {pageNum}
            </PaginationItem>
          ))
        }
        <PrevNextPage
          src={
            pageList.length !== currentPage ? next_page_img : no_next_page_img
          }
        />
      </Pagination>
    )
  }
  return <></>
}

export default CustomPagination

const Pagination = styled.ul`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  padding: 0;
  /*
  list-style: none;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  */

  /* :after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  } */
`

const PaginationItem = styled.li`
  min-width: 32px;
  text-align: center;
  list-style: none;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;

  font-size: 14px;
  color: ${colors.brown_grey};
  ${props =>
    props.isActive
    && css`
      color: ${colors.black};
    `}
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
