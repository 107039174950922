import { Caption2, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import React from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'
import styled from 'styled-components'

const COLORS = ['#0066ff', '#ea4653', '#66bc6a']

const TrackSnsLineChart = ({ dailyListData }) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={dailyListData}>
        <XAxis
          hide
          dataKey="date"
          tickFormatter={tick => {
            const strTick = `${tick}`
            return `${strTick.slice(0, 4)}년 ${strTick.slice(4, 6)}월 ${strTick.slice(6, 8)}일`
          }}
          allowDuplicatedCategory={false}
        />
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip content={<CustomTooltip />} />
        <Line
          yAxisId={'right'}
          dataKey={`listenerCountInfo.youtube`}
          name="Listeners"
          type="monotone"
          stroke={COLORS[1]}
          dot={false}
        />
        <Line
          dataKey={`listenerCountInfo.melon`}
          name="Listeners"
          type="monotone"
          stroke={COLORS[2]}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default TrackSnsLineChart

const CustomTooltip = ({ active, payload, label: _label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {`${label.slice(0, 4)}년 ${label.slice(4, 6)}월 ${label.slice(6, 8)}일`}
        </Caption2>
        <Flex justify="space-between">
          <Caption2 type="Bold">
            {`Melon ${item?.rankInfo?.melon ? `${item?.rankInfo?.melon}위` : ''}`}
          </Caption2>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.listenerCountInfo?.melon || 0)}`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Caption2 type="Bold">{`Youtube`}</Caption2>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.listenerCountInfo?.youtube || 0)}`}
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}

const ToolTipBox = styled(Flex)`
  width: 114px;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 8px;
`
