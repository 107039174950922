import React, { useEffect, useState } from 'react'
import { TrackTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { useLastLocation } from 'react-router-last-location'
import { AlbumStore, RecordStore } from '../stores'

interface TrackPageProps {
  authStore: AuthStore;
  albumStore: AlbumStore;
  recordStore: RecordStore;
  commentStore: CommentStore;
  match: Any;
  location: Any;
  history: Any;
}

const TrackPage = ({
  match,
  location,
  history,
  albumStore,
  recordStore,
  authStore,
  commentStore,
}: TrackPageProps) => {
  /* store */
  const currentUser = authStore.currentUser || null
  const logout = authStore.logout || (() => {})

  const likeInfo = authStore.likeInfo || null
  const fetchLike = authStore.fetchLike || (() => {})
  const updateLike = authStore.updateLike || (() => {})

  const trackDetail = albumStore.trackDetail || null
  const enterRecord = recordStore.enterRecord || (() => {})

  const fetchCommentList = commentStore.fetchCommentList || (() => {})
  const commentList = commentStore.commentList || null
  const commentListCount = commentStore.commentListCount || null
  /* store end */

  const lastLocation = useLastLocation()

  const isPlayFirstYoutube = location?.state?.isPlayFirstYoutube;

  // // post record
  // const checkReferrer = async (track) => {
  //   const referrer = document.referrer
  //   // 도메인 정규식
  //   var regExp = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi
  //   const origin =
  //     referrer.match(regExp) && referrer.match(regExp)[0].split('://')[1]
  //   const path = referrer.replace(
  //     referrer.match(regExp) && referrer.match(regExp)[0],
  //     '',
  //   )

  //   const params = {
  //     origin: origin,
  //     path: path === '' ? null : path,
  //     referer: referrer === '' ? null : referrer,

  //     plamUrl: document.URL,
  //     urlType: 'tracks',
  //     plamPathId: match.params.id,
  //   }

  //   // 첫 방문시 로직
  //   if (
  //     lastLocation === null &&
  //     origin !== null &&
  //     origin !== document.domain &&
  //     history.length === 1 &&
  //     performance.navigation.type !== 1
  //   ) {
  //     await recordStore.createRecord(params)
  //     sessionStorage.setItem('recordId', recordStore.record._id)
  //   } else if (
  //     lastLocation === null &&
  //     origin === null &&
  //     history.length === 2 &&
  //     performance.navigation.type !== 1
  //   ) {
  //     await recordStore.createRecord(params)
  //     sessionStorage.setItem('recordId', recordStore.record._id)
  //   }
  // }

  // put Record
  const updateRecord = async (type, url) => {
    const params = {
      _id: sessionStorage.getItem('recordId'),
      clickType: type,
      clickUrl: url,
    }

    await recordStore.updateRecord(params)
  }

  useEffect(() => {
    // fetch Track
    albumStore.fetchTrackDetail(match.params.id)
    // if (trackDetail) {
    //   checkReferrer(trackDetail)
    // }

    // post Record
    const enterPage = {
      referrer: document.referrer,
      domain: document.domain,
      URL: document.URL,
      lastLocation,
      history,
      performance,
      type: 'track',
      pathId: match.params.id,
    }
    enterRecord(enterPage)

    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [match, albumStore])

  // fetch Track Comment
  useEffect(() => {
    if (trackDetail) {
      fetchCommentList({
        dataObjectId: trackDetail._id,
        dataCollectionName: 'tracks',
        offset: 0,
      })
    }
  }, [trackDetail])

  return (
    <TrackTemplate
      track={trackDetail}
      currentPath={document.domain + match.url}
      updateRecord={updateRecord}
      currentUser={currentUser}
      logout={logout}
      fetchLike={fetchLike}
      likeInfo={likeInfo}
      updateLike={updateLike}
      commentList={commentList}
      commentListCount={commentListCount}
      isPlayFirstYoutube={isPlayFirstYoutube}
    />
  )
}

export default inject(
  'recordStore',
  'albumStore',
  'authStore',
  'commentStore',
)(observer(TrackPage))
