import { Caption1, Caption2, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const TableBox = styled.div`
  width: 100%;
  background-color: #ffffff;
`

const DailySnsTableChart = ({ data, snsType, isFold = true }) => {
  const parseData = useMemo(() => {
    const sortedData = data?.sort((a, b) => b?.date - a?.date)
    // const sortedData = data?.sort(
    //   (a, b) => b?.listenerInfo[snsType] - a?.listenerInfo[snsType],
    // )

    if (isFold) return sortedData?.slice(0, 5)
    return sortedData
  }, [isFold, data, snsType])


  return (
    <TableBox>
      <Tr>
        <Th>날짜</Th>
        <Th>{snsType === 'melon' ? '청취자 수' : '조회 수'}</Th>
        <Th>좋아요 수</Th>
        <Th>팔로워 수</Th>
      </Tr>
      {
parseData?.map(rowData => {
  const dateStr = `${rowData?.date || ''}`

  return (
    <Tr>
      <Td>
        <Caption1>
          {
            `${dateStr.slice(2, 4)}. ${dateStr.slice(
              4,
              6,
            )}. ${dateStr.slice(6, 8)}`
          }
        </Caption1>
      </Td>
      <Td>
        <Flex type="column" align="center">
          <Caption1>
            {convertToPricingComma(rowData?.listenerInfo?.[snsType] || 0)}
          </Caption1>
          <Flex align="center" style={{ gap: 4 }}>
            {
rowData?.listenerInfo?.increaseValue?.[snsType] > 0 ? (
  <Up />
) : rowData?.listenerInfo?.increaseValue?.[snsType] < 0 ? (
  <Down />
) : (
  <></>
)
            }
            <Caption2 color="#949494">
              {
rowData?.listenerInfo?.increaseValue?.[snsType] === 0
  ? '-'
  : convertToPricingComma(
    Math.abs(
                            rowData?.listenerInfo?.increaseValue?.[snsType],
    ),
  )
              }
            </Caption2>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex type="column" align="center">
          <Caption1>
            {convertToPricingComma(rowData?.likeInfo?.[snsType] || 0)}
          </Caption1>
          <Flex align="center" style={{ gap: 4 }}>
            {
rowData?.likeInfo?.increaseValue?.[snsType] > 0 ? (
  <Up />
) : rowData?.likeInfo?.increaseValue?.[snsType] < 0 ? (
  <Down />
) : (
  <></>
)
            }
            <Caption2 color="#949494">
              {
rowData?.likeInfo?.increaseValue?.[snsType] === 0
  ? '-'
  : convertToPricingComma(
    Math.abs(rowData?.likeInfo?.increaseValue?.[snsType]),
  )
              }
            </Caption2>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex type="column" align="center">
          <Caption1>
            {convertToPricingComma(rowData?.followerInfo[snsType] || 0)}
          </Caption1>
          <Flex align="center" style={{ gap: 4 }}>
            {
rowData?.followerInfo?.increaseValue?.[snsType] > 0 ? (
  <Up />
) : rowData?.followerInfo?.increaseValue?.[snsType] < 0 ? (
  <Down />
) : (
  <></>
)
            }
            <Caption2 color="#949494">
              {
rowData?.followerInfo?.increaseValue?.[snsType] === 0
  ? '-'
  : convertToPricingComma(
    Math.abs(
                            rowData?.followerInfo?.increaseValue?.[snsType],
    ),
  )
              }
            </Caption2>
          </Flex>
        </Flex>
      </Td>
    </Tr>
  )
})
      }
    </TableBox>
  )
}

export default DailySnsTableChart

const Tr = styled(Flex)`
  flex: 1;
`

const Th = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;

  color: #646464;
  font-size: 12px;
`

const Td = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #66bc6a;

  margin-top: 2px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #ea4653;

  margin-top: 2px;
`
