import { desktop } from '@styles/media'
import React from 'react'
import styled from 'styled-components'

const LoanRequestBtn2 = ({ onClick, text, style }) => {
  return (
    <Btn onClick={onClick} style={style}>
      <Text>{text}</Text>
    </Btn>
  )
}

export default LoanRequestBtn2

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 2px;

  width: 100%;
  @media ${desktop}{
    width: 320px;
  }

  height: 44px;
  background: #242424;

  cursor: pointer;
`

const Text = styled.p`
  font-family: NotoSansCJKkr-Medium;

  font-size: 12px;
  color: #ffffff;
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`
