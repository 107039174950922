import { BodyWrapper, Flex, H5 } from '@components/atoms'
import React, { useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import styled from 'styled-components'
import { x_btn_white_img } from '@images/'
import { colors } from '@colors/'

const EditPhotoTemplate = ({
  src,
  crop,
  currentUser,
  setCrop,
  setCroppedImageUrl,
  setIsEditProfile,
  setBeforePostImage,
}) => {
  const imageRef = useRef(null)
  const [tempUrl, setTempUrl] = useState()

  const onImageLoaded = image => {
    imageRef.current = image
  }

  const onCropChange = (_crop, percentCrop) => {
    // 퍼센트 크롭을 사용해도 된다:
    // this.setState({ crop: percentCrop });
    // this.setState({ crop });
    setCrop(_crop)
  }

  const makeClientCrop = async _crop => {
    if (imageRef.current && _crop.width && _crop.height) {
      // getCroppedImg() 메서드 호출한 결과값을
      // state에 반영한다
      const _croppedImageUrl = await getCroppedImg(
        imageRef.current,
        _crop,
        `${currentUser && currentUser.nickname}.jpeg`,
      )
      const __croppedImageUrl = await getCroppedImgForUrl(
        imageRef.current,
        _crop,
        `${currentUser && currentUser.nickname}.jpeg`,
      )

      setTempUrl(__croppedImageUrl)
      setCroppedImageUrl(_croppedImageUrl)
    }
  }

  const onCropComplete = (_crop, percentCrop) => {
    makeClientCrop(_crop)
  }

  const getCroppedImg = (image, _crop, fileName) => {
    const canvas = document.createElement('canvas') // document 상에 canvas 태그 생성
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    // 캔버스 영역을 크롭한 이미지 크기 만큼 조절
    canvas.width = _crop.width
    canvas.height = _crop.height
    // getContext() 메서드를 활용하여 캔버스 렌더링 컨텍스트 함수 사용
    // 이 경우 drawImage() 메서드를 활용하여 이미지를 그린다
    const ctx = canvas.getContext('2d')

    // 화면에 크롭된 이미지를 그린다
    ctx.drawImage(
      // 원본 이미지 영역
      image, // 원본 이미지
      _crop.x * scaleX, // 크롭한 이미지 x 좌표
      _crop.y * scaleY, // 크롭한 이미지 y 좌표
      _crop.width * scaleX, // 크롭한 이미지 가로 길이
      _crop.height * scaleY, // 크롭한 이미지 세로 길이
      // 캔버스 영역
      0, // 캔버스에서 이미지 시작 x 좌표
      0, // 캔버스에서 이미지 시작 y 좌표
      _crop.width, // 캔버스에서 이미지 가로 길이
      _crop.height, //  캔버스에서 이미지 세로 길이
    )

    // canvas 이미지를 base64 형식으로 인코딩된 URI 를 생성한 후 반환한다
    // return new Promise(resolve => {
    //   resolve(canvas.toDataURL())
    // })

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = fileName
          resolve(blob)
        },
        'image/jpeg',
        1,
      )
    })
  }

  const getCroppedImgForUrl = (image, _crop, fileName) => {
    const canvas = document.createElement('canvas') // document 상에 canvas 태그 생성
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    // 캔버스 영역을 크롭한 이미지 크기 만큼 조절
    canvas.width = _crop.width
    canvas.height = _crop.height
    // getContext() 메서드를 활용하여 캔버스 렌더링 컨텍스트 함수 사용
    // 이 경우 drawImage() 메서드를 활용하여 이미지를 그린다
    const ctx = canvas.getContext('2d')

    // 화면에 크롭된 이미지를 그린다
    ctx.drawImage(
      // 원본 이미지 영역
      image, // 원본 이미지
      _crop.x * scaleX, // 크롭한 이미지 x 좌표
      _crop.y * scaleY, // 크롭한 이미지 y 좌표
      _crop.width * scaleX, // 크롭한 이미지 가로 길이
      _crop.height * scaleY, // 크롭한 이미지 세로 길이
      // 캔버스 영역
      0, // 캔버스에서 이미지 시작 x 좌표
      0, // 캔버스에서 이미지 시작 y 좌표
      _crop.width, // 캔버스에서 이미지 가로 길이
      _crop.height, //  캔버스에서 이미지 세로 길이
    )

    // canvas 이미지를 base64 형식으로 인코딩된 URI 를 생성한 후 반환한다
    return new Promise(resolve => {
      resolve(canvas.toDataURL())
    })
  }

  return (
    <BodyWrapper style={{ padding: 0, backgroundColor: 'black' }}>
      <Flex type="column" style={{ position: 'relative' }}>
        <Header>
          <img
            src={x_btn_white_img}
            onClick={() => setIsEditProfile(false)}
            alt="close_icon"
          />
          <H5
            color={colors.white}
            onClick={
              () => {
                if (crop.width === 0) return
                setBeforePostImage(tempUrl)
                setIsEditProfile(false)
              }
            }
          >
            완료
          </H5>
        </Header>
        <EidtPhotoArea>
          {
            src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )
          }
        </EidtPhotoArea>
      </Flex>
    </BodyWrapper>
  )
}

export default EditPhotoTemplate

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  background-color: black;
  height: 64px;
  z-index: 1000;
  position: fixed;
  width: 100%;
  min-width: 320px;
  max-width: 420px;

  img {
    margin: 0 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  h5 {
    margin: 0 16px;
  }
`

const EidtPhotoArea = styled(Flex)`
  background-color: black;
  margin-top: 64px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
