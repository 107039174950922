import React from 'react'
import styled from 'styled-components'
import { back_img } from '@images'

const ContentImg = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  top: 6px;
  left: 6px;
  cursor: pointer;
`

const StramingButton = ({ type = 'etc', link = '#/', onClick }) => {
  const content_img = require(`@images/${type}_img.png`)
  const jp_img = require(`@images/jp_img.png`)
  const tw_img = require(`@images/tw_img.png`)

  return (
    <div
      style={{
        position: 'relative',
        width: '60px',
        height: '60px',
        margin: '4px',
      }}
    >
      <img
        style={{
          width: '60px',
          height: '60px',
          position: 'absolute',
          top: '0',
          left: '0',
        }}
        src={back_img}
        alt="back"
      />
      <a href={link} target="_blank" rel="noopener noreferrer">
        <ContentImg src={content_img} alt={type} onClick={onClick} />
      </a>
      {(type === 'linetwUrl' || type === 'linejpUrl') && (
        <img
          src={type === 'linetwUrl' ? tw_img : jp_img}
          alt={type === 'linetwUrl' ? 'tw' : 'jp'}
          style={{
            width: 20,
            height: 20,
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        />
      )}
    </div>
  )
}

export default StramingButton
