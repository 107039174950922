import React from 'react'
import { Flex, Caption1, H6 } from '@components/atoms'
import { default_album_img } from '@images'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { handleAlbumImgError } from '@utils/handler'
import { colors } from '@colors'
import { getIsWebApp, sendWTAPostMessage } from '@utils/postMessage'
import { point_img } from '@images/index'
import { convertToPricingComma } from '@utils/format'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const RewardItem = ({ item, itemStyle, setShowDownloadPopup= ()=>{} }) => {
  return (
    <a
      style={{cursor : 'pointer'}}
      onClick={
        ()=>{

          const isWebApp = getIsWebApp({navigator})
          if(isWebApp){
            const data = {
              code: 'WTA_REWARD_ITEM',
              data : {rewardId : item?._id}
            }
            sendWTAPostMessage({window, navigator, postMessageData: data})
          }
          else{
            setShowDownloadPopup(true)
          }
        }
      }
    >
      <Flex
        type="column"
        style={{ ...itemStyle, width: '150px', }}
      >
        <Image
          src={item?.trackInfo?.albumInfo?.image128Uri ? item?.trackInfo?.albumInfo?.image128Uri : default_album_img}
          onError={e => handleAlbumImgError(e)}
          size={['150px', '150px']}
          style={
            {
              borderRadius: '10px',
              border: `solid 0.5px ${colors.inActive}`,
            }
          }
        />
        <H6 align="left" style={ellipsisStyle}>
          {item.trackInfo.title}
        </H6>
        <Caption1 align="left" color="#949494" style={ellipsisStyle}>
          {
            item.trackInfo.releaseArtistList.map(
              (artist, index) =>
                `${artist.name}
              ${
    !(index === item.trackInfo.releaseArtistList.length - 1) ? ',\u00A0' : ''
    }`,
            )
          }
        </Caption1>
        <H6 style={{ display :'flex', flexDirection:'row', alignItems:'center', marginTop: '2px', lineHeight: '20px' }} type={'Bold'} align="left">
          <Image
            src={point_img}
            size={['16px', '16px']}
            style={{marginRight: '2px'}}
          />
          {
            `${convertToPricingComma(item?.rewardOncePoint || 0)}`
          }
        </H6>
      </Flex>
    </a>
  )
}

export default RewardItem
