import React, { useState } from 'react'
import {
  Flex,
  BodyWrapper,
  Typography,
  Input,
  Caption1,
} from '@components/atoms'
import { plamin_logo_img } from '@images/index'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'

const AuthFindPasswordTemplate = () => {
  const history = useHistory()
  const { authStore } = useStore()
  const findPassword = authStore?.findPassword || (() => { })

  const [step, setStep] = useState(0)
  const [account, setAccount] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleFindAccount = async () => {
    if (step === 0) {
      const params = {
        stage: 1,
        account,
      }

      const res = await findPassword(params)

      if (res) {
        if (res?.data?.isSuccess) {
          setError('')
          setStep(1)
        } else {
          setError('account-duplicated')
        }
      }
    } else {
      const params = {
        stage: 2,
        account,
        email,
      }

      const res = await findPassword(params)

      if (res) {
        if (res?.data?.isSend) {
          setError('complete')
        } else {
          setError('email-duplicated')
        }
      }
    }
  }

  return (
    <BodyWrapper>
      <Flex
        type="column"
        style={{
          height: '100vh',
          padding: '56px 0',
          boxSizing: 'border-box',
        }}
      >
        <Flex
          type="column"
          style={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <img
            src={plamin_logo_img}
            width={126}
            height={36}
            alt="logo"
            onClick={() => (window.location.href = '/')}
          />
          {error === 'complete' ? (
            <>
              <Typography
                size="38px"
                type="bold"
                style={{ margin: '32px 0 12px 0', textAlign: 'center' }}
              >
                임시 비밀번호
                <br />
                전송 완료
              </Typography>
              <Box>
                <Typography size="12px" type="Regular" color="#646464">
                  아래의 이메일로 임시 비밀번호를 전송했습니다. 24시간 이내로
                  새로운 비밀번호로 재설정해주세요.
                </Typography>
                <Typography size="16px">{email}</Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography
                size="38px"
                type="bold"
                style={{ margin: '32px 0 12px 0' }}
              >
                {step === 0 ? '비밀번호 재설정' : '임시 비밀번호 전송'}
              </Typography>
              <Typography size="12px" color="#646464">
                {step === 0
                  ? '비밀번호 재설정 할 아이디를 입력해주세요.'
                  : '아이디에 등록된 이메일을 입력해주세요.'}
              </Typography>
              <Input
                placeholder={step === 0 ? '아이디' : '이메일'}
                style={{ height: 48, width: '100%', marginTop: 32 }}
                value={step === 0 ? account : email}
                onChange={e => {
                  if (step === 0) {
                    setAccount(e.currentTarget.value)
                  } else setEmail(e.currentTarget.value)
                }}
              />
              {error === 'account-duplicated' && (
                <Caption1
                  color="#ea4653"
                  align="left"
                  style={{ marginTop: 8, width: '100%' }}
                >
                  아이디를 다시 확인해주세요.
                </Caption1>
              )}
              {error === 'email-duplicated' && (
                <Caption1
                  color="#ea4653"
                  align="left"
                  style={{ marginTop: 8, width: '100%' }}
                >
                  이메일을 다시 확인해주세요.
                </Caption1>
              )}
            </>
          )}
        </Flex>
        {error === 'complete' ? (
          <Flex type="column">
            <Button
              style={{ color: 'black', backgroundColor: '#f4f4f4' }}
              onClick={() => history.push('/login/artist')}
            >
              로그인
            </Button>
            <Typography
              size="16px"
              color="#646464"
              style={{
                width: '100%',
                marginTop: 24,
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => history.push('/auth/find-password')}
            >
              임시 비밀번호 재전송
            </Typography>
          </Flex>
        ) : (
          <Button
            disabled={step === 0 ? !account : !email}
            onClick={handleFindAccount}
          >
            {step === 0 ? '다음' : '전송'}
          </Button>
        )}
      </Flex>
    </BodyWrapper>
  )
}

export default AuthFindPasswordTemplate

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  border: none;
  background-color: black;
  color: white;

  :disabled {
    color: #bfbfbf;
    background-color: #f4f4f4;
  }
`

const Box = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 104px;
  border-radius: 2px;
  border: solid 0.5px #eaeaea;
  background-color: #fafafa;
  padding: 0 36px;
`
