import React from 'react'
import styled from 'styled-components'

const ContentImg = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`

const SnsButton = ({ style, type = 'etc', link = '#/', onClick }) => {
  const content_img = require(`@images/sns_${type}.png`)

  if (type !== 'email') {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <ContentImg
          style={style && style}
          src={content_img}
          alt={type}
          onClick={onClick}
        />
      </a>
    )
  } else {
    return (
      <ContentImg
        onClick={onClick}
        style={style && style}
        src={content_img}
        alt={type}
      />
    )
  }
}

export default SnsButton
