import { axiosApi } from '../../utils'

import AuthNetwork from './AuthNetwork'
import AlbumNetwork from './AlbumNetwork'
import ArtistNetwork from './ArtistNetwork'
import TrackNetwork from './TrackNetwork'
import RecordNetwork from './RecordNetwork'
import DataMapNetwork from './DataMapNetwork'
import StatsNetwork from './StatsNetwork'
import SearchNetwork from './SearchNetwork'
import CommentNetwork from './CommentNetwork'
import ArticleNetwork from './ArticleNetwork'
import InplamNetwork from './InplamNetwork'
import TrendNetwork from './TrendNetwork'
import RewardNetwork from './RewardNetwork'

export class Network {
  _axiosApi = axiosApi
  _axiosApiAuth = axiosApi

  authNetwork: AuthNetwork
  albumNetwork: AlbumNetwork
  artistNetwork: ArtistNetwork
  trackNetwork: TrackNetwork
  recordNetwork: RecordNetwork
  dataMapNetwork: DataMapNetwork
  statsNetwork: StatsNetwork
  rewardNetwork: RewardNetwork
  searchNetwork: SearchNetwork
  commentNetwork: CommentNetwork
  articleNetwork: ArticleNetwork
  inplamNetwork: InplamNetwork
  trendNetwork: TrendNetwork

  constructor(props) {
    props.getJwt && this.createAxiosApiAuth(props.getJwt)

    this.authNetwork = new AuthNetwork(this)
    this.albumNetwork = new AlbumNetwork(this)
    this.artistNetwork = new ArtistNetwork(this)
    this.trackNetwork = new TrackNetwork(this)
    this.recordNetwork = new RecordNetwork(this)
    this.dataMapNetwork = new DataMapNetwork(this)
    this.statsNetwork = new StatsNetwork(this)
    this.rewardNetwork = new RewardNetwork(this)
    this.searchNetwork = new SearchNetwork(this)
    this.commentNetwork = new CommentNetwork(this)
    this.articleNetwork = new ArticleNetwork(this)
    this.inplamNetwork = new InplamNetwork(this)
    this.trendNetwork = new TrendNetwork(this)
  }

  createAxiosApiAuth = getJwt => {
    this._axiosApiAuth = async (url, method = 'GET', data, options) => {
      const jwtToken = getJwt && getJwt() ? `jwt ${getJwt && getJwt()}` : 'jwt '
      const _options = {
        ...options,
        headers: {
          Authorization: jwtToken,
          ...(options && typeof options['headers'] === 'object'
            ? options['headers']
            : {}),
        },
      }
      return axiosApi(url, method, data, _options)
    }
  }
}
