import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { RecordModel } from './models'

export default class RecordStore {
  @observable record
  @observable recorded

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.record = {}
    this.recorded = false
  }

  @action.bound
  enterRecord = async enterPage => {
    return this.store.useLoading(async () => {
      // 도메인 정규식
      const regExp = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi
      const origin =
        enterPage.referrer.match(regExp)
        && enterPage.referrer.match(regExp)[0].split('://')[1]
      const path = enterPage.referrer.replace(
        enterPage.referrer.match(regExp) && enterPage.referrer.match(regExp)[0],
        '',
      )

      const recordParams = {
        origin,
        path: path === '' ? null : path,
        referer: enterPage.referrer === '' ? null : enterPage.referrer,

        plamUrl: enterPage.URL,
        urlType: enterPage.type,
        plamPathId: enterPage.pathId,
      }

      if (
        enterPage.lastLocation === null
        && origin !== null
        && origin !== enterPage.domain
        && enterPage.history.length === 1
        && enterPage.performance.navigation.type !== 1
      ) {
        const { data } = await this.network.recordNetwork.postRecord(
          recordParams,
        )
        sessionStorage.setItem('recordId', data.record._id)
      }
      else if (
        enterPage.lastLocation === null
        && origin === null
        && origin !== enterPage.domain
        // enterPage.history.length <= 2 &&
        && enterPage.performance.navigation.type !== 1
      ) {
        const { data } = await this.network.recordNetwork.postRecord(
          recordParams,
        )
        sessionStorage.setItem('recordId', data.record._id)
      }
      else if (
        enterPage.performance.navigation.type === 1
        && !sessionStorage.getItem('recordId')
      ) {
        const { data } = await this.network.recordNetwork.postRecord(
          recordParams,
        )
        sessionStorage.setItem('recordId', data.record._id)
      }
      else if (
        enterPage.performance.navigation.type === 1
        && !!sessionStorage.getItem('recordId')
      ) {
        /* 새로고침 시, 기존 recordId 사용 */
      }
      else {
        return
      }
      this.recorded = true
    })
  }

  @action.bound
  updateRecord = async record => {
    return this.store.useLoading(async () => {
      if (!record) return

      const updatedRecord = await this.network.recordNetwork.putRecord(record)
      if (!updatedRecord) return

      const baseRecord = new RecordModel(this.store, updatedRecord)
      this.record = baseRecord
    })
  }
}
