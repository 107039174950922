import React, { useState } from 'react'
import './Popup.css'
import styled, { css, keyframes } from 'styled-components'
import { tooltip_head_img } from '@images/'
import { Caption1, Flex, Typography } from '../../atoms'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const boxFadeOut = keyframes`
  0% { opacity: 1;}
  100% { opacity: 0; }
`

const AnimatioWrapper = styled.div`
  /* animation-name: ${boxFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 100; */
  animation-name: ${props => props.animName};
  animation-duration: ${props => props.animDuration};
`

const PopupInner = styled.div`
  position: absolute;
  box-shadow: 0 0 6px 0 rgba(36, 36, 36, 0.78);
  ${props =>
    props.position === 'left'
      ? css`
          left: 0;
        `
      : props.position === 'right'
        ? css`
          right: 0;
        `
        : css`
          left: 50%;
          right: 50%;
        `}

  top: 68px;
  background: #242424;
  z-index: 1000;
  border-radius: 6px;
  color: #ffffff;

  padding: 16px 17px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TooltipHead = styled.img`
  width: 18px;
  height: 12px;
  top: -27px;
  position: absolute;

  ${props =>
    props.position === 'left'
      ? css`
          left: 0;
        `
      : props.position === 'right'
        ? css`
          right: 0;
        `
        : css`
          left: 50%;
          right: 50%;
        `}
`

export default () => {
  const [isShowing, setIsShowing] = useState(false)
  const [closeAction, setCloseAction] = useState(false)

  const toggleTooltip = () => {
    setIsShowing(!isShowing)
  }

  const onClose = () => {
    setCloseAction(true)
    setTimeout(() => {
      setIsShowing(false)
    }, 500)
  }

  const View = ({
    text,
    backgroundColor = '#242424',
    position = 'left',
    handleClose = null,
  }) => {
    if (isShowing) {
      return (
        <AnimatioWrapper
          animName={closeAction && boxFadeOut}
          animDuration={'0.5s'}
        >
          <PopupInner
            position={position}
            scrollTop={document.documentElement.scrollTop}
            style={{ backgroundColor }}
          >
            <Flex type="column" style={{ position: 'relative' }}>
              <TooltipHead
                src={tooltip_head_img}
                alt="tooltip_head"
                position={position}
              />
              <Caption1
                type="Regular"
                align="left"
                style={{ whiteSpace: 'pre' }}
              >
                {text && text}
              </Caption1>
              <Flex
                style={
                  {
                    width: '100%',
                    height: 30,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }
                }
              >
                <Caption1
                  type="Regular"
                  onClick={
                    () => {
                      handleClose && handleClose()
                      onClose()
                    }
                  }
                  style={{ cursor: 'pointer' }}
                >
                  닫기
                </Caption1>
              </Flex>
            </Flex>
          </PopupInner>
        </AnimatioWrapper>
      )
    }
    return <></>
  }

  return {
    View,
    toggleTooltip,
  }
}
