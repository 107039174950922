import { ActivityArticleTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

const ActivityArticlePage = ({ history }) => {
  const { authStore, articleStore } = useStore()

  const [offset, setOffset] = useState(0)
  const [uniqueName, setUniqueName] = useState('')
  const [isAlaram, setIsAlaram] = useState(true) // 알림 설정 여부

  const handleAlaram = async value => {
    await authStore.updateUser({
      isInplamNotification: value,
    })
    setIsAlaram(value)
  }

  useEffect(() => {
    if (authStore.currentUser) {
      setIsAlaram(authStore.currentUser.isInplamNotification)
    }
  }, [authStore.currentUser])

  useEffect(() => {
    if (authStore.currentUser) {
      articleStore.fetchMyArticleList({ offset, uniqueName })
    }
  }, [authStore.currentUser, articleStore, offset, uniqueName])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ActivityArticleTemplate
      type="myArticles"
      history={history}
      uniqueName={uniqueName}
      setUniqueName={setUniqueName}
      myArticleList={articleStore.myArticleList || null}
      myArticleListCount={articleStore.myArticleListCount || null}
      artistInfoList={articleStore.artistInfoList || null}
      offset={offset}
      setOffset={setOffset}
      isAlaram={isAlaram}
      handleAlaram={handleAlaram}
      fetchUser={authStore.login || (() => {})}
    />
  )
}

export default observer(ActivityArticlePage)
