export const mbtiQuizData = [
  {
    no: 1,
    type: 'IE',
    text: '첫 그룹 데뷔!\n멤버들과 처음 만난 나는?',
    answer1: '멤버들이 먼저 말을 걸 때 까지 기다린다.',
    answer2: ' 안녕? 앞으로 잘 지내보자! 먼저 말을 건다.',
  },
  {
    no: 2,
    type: 'SN',
    text: '멤버들이 나에게\n제일 많이 할 것 같은 말은?',
    answer1: '어떻게 그렇게 꾸준하게 해?\n 반복적인 것도 잘하는구나~',
    answer2: '넌 정말 창의적인 것 같아~\n 독특한 생각을 많이 해서 신기해!',
  },
  {
    no: 3,
    type: 'TF',
    text:
      '멤버 중 한명이 열애설에\n걸려버렸다!\n 멤버가 어떻게 사과하면\n 내 맘이 풀릴까?',
    answer1:
      '원래 걔네 숙소에서 놀려고 했는데 잠깐 편의점 가는 사이에 사진이 찍혀버렸어 ᅲᅲ 미안해',
    answer2:
      '진짜 미안해... 진짜 잘못했어.. 앞으로 절대 안그럴게.. 약속할게..(진심이 느껴지는 사과)',
  },
  {
    no: 4,
    type: 'PJ',
    text: '첫 무대의상!\n 선택권은 나에게 있다.',
    answer1: '어? 오늘 이 의상 작살나는데?\n 오늘 예뻐보이는 옷이 최고지!',
    answer2: '전날부터 머리부터 발끝까지\n 미리 구상하고 생각해둔다.',
  },
  {
    no: 5,
    type: 'IE',
    text: '오늘은 스케쥴이 없는 주말!\n 숙소에 있는 나는?',
    answer1: '오늘은 좀 쉬어야겠다. 누워서 뒹굴, 개인 시간 갖기',
    answer2: '피곤하... 어 루X큐브? 멤버들을 부른다.',
  },
  {
    no: 6,
    type: 'SN',
    text: '우연히 꿈에 그리던 탑스타를 목격!\n 멤버들에게 설명할 때 나는?',
    answer1: '눈이 되게 크고 서구적이야.\n 얼굴도 작고 9등신 모델 같았어.',
    answer2: '진심 영화에서 보는 것 보다 백배는 잘생겼어!',
  },
  {
    no: 7,
    type: 'TF',
    text: '멤버와의 작은 의견충돌,\n 이때의 나는?',
    answer1: '이부분은 이래서 내가 그랬던거야.\n 논리적으로 말한다',
    answer2: '그게 아니라 이건 너가...(울컥)',
  },
  {
    no: 8,
    type: 'PJ',
    text: '안무 연습 중\n 해결이 안될 때 나는?',
    answer1: '우선 혼자 해보자.\n 이러다 언젠간 나에게 맞는 방법을 찾겠지!',
    answer2: '경험은 가장 중요한 해결법.\n 선배님들이 하던대로 하자!',
  },
  {
    no: 9,
    type: 'IE',
    text: '멤버중 한명이 라방중이다.\n 그 때 나를 부르는 내 팬!',
    answer1: '가볍게 미소지으며 잠깐 소통하고 빠진다.',
    answer2: '네 고객님 저를 부르셨나요? 함께 즐겁게 소통한다.',
  },
  {
    no: 10,
    type: 'SN',
    text: '스케쥴 이동중인 차 안,\n 내 머릿속은?',
    answer1: '강남 근처라 그런지 차가 많이 막히네.\n 멀미만 안났음 좋겠다.',
    answer2:
      '가다가 갑자기 사고나면 어떡하지? 막내부터 구해야하나? 내가 심폐소생술은 할 줄 아나?',
  },
  {
    no: 11,
    type: 'TF',
    text: '첫 무대 후,\n내가 더 듣고 싶은 말은?',
    answer1:
      '첫 무대치고 굉장히 잘했지만 엔딩 부분만 수정하면 아이돌 중 최고일 것 같아.',
    answer2:
      '처음이라 떨렸을텐데 엄청 잘했어! 밤새 연습하느라 힘들었지? 정말 수고했어 ᅲᅲ',
  },
  {
    no: 12,
    type: 'PJ',
    text: ' 내일은 팬들과 약속한 라방!\n 라방을 준비할 때 나는?',
    answer1: '질문을 어떻게 예상해~\n 대답 해 줄 수 있는 것만 최선을 다하자!',
    answer2: '예상 질문부터 마무리는 어떻게 해야할 지\n 꼼꼼히 체크한다.',
  },
]
