import { Caption1, Caption2, Flex, H2, H4 } from '@components/atoms'
import React from 'react'
import { STORAGE_URL } from '@consts'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Observer } from 'mobx-react'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const LikeTrackList = ({
  list,
  onPressMoreTrackList,
  isLoading = false,
  likedTrackSortBy,
  handleChangeLikedTrackSortBy = () => {},
}) => {
  const isEnded = list?.length % 10

  return (
    <Flex type="column">
      <Flex type={'row'} style={{ justifyContent: 'space-between' }}>
        <H4 type="Bold" align="left">
          좋아요한 트랙
        </H4>
        <Flex type={'row'} style={{ gap: '12px', alignItems: 'center' }}>
          <SortText
            onClick={
              () => {
                handleChangeLikedTrackSortBy('listener')
              }
            }
            active={likedTrackSortBy === 'listener'}
          >
            청취자순
          </SortText>
          <SortText
            onClick={
              () => {
                handleChangeLikedTrackSortBy('rank')
              }
            }
            active={likedTrackSortBy === 'rank'}
          >
            순위순
          </SortText>
        </Flex>
      </Flex>
      <Flex
        style={
          {
            flexDirection: 'row',
            marginBottom: '12px',
            marginTop: '20px',
          }
        }
      >
        <Flex style={{ flex: 1 }}>
          <TitleText>트랙</TitleText>
        </Flex>
        <Flex style={{ width: 80, alignItems: 'center' }}>
          <TitleText style={{ textAlign: 'center' }}>청취자</TitleText>
        </Flex>
        <Flex style={{ width: 80, alignItems: 'center' }}>
          <TitleText style={{ textAlign: 'center' }}>순위</TitleText>
        </Flex>
      </Flex>
      <Flex type="column" style={{ marginLeft: -20 }}>
        <div
          style={
            {
              width: 'calc(100vw + 20px)',
              height: 1,
              backgroundColor: '#eaeaea',
            }
          }
        />
      </Flex>
      <Flex type="column" style={{ marginLeft: -20 }}>
        <Flex
          style={
            {
              flexDirection: 'column',
            }
          }
        >
          <Observer>
            {
              () => {
                if (isLoading) {
                  return Array(10)
                    .fill(1)
                    ?.map((item, index) => {
                      return (
                        <div key={`likeTrackSkel-${item + index}`}>
                          <TrackItemSkeleton />
                          <div
                            style={
                              {
                                width: 'calc(100vw + 20px)',
                                height: 1,
                                backgroundColor: '#eaeaea',
                              }
                            }
                          />
                        </div>
                      )
                    })
                }

                return list?.map(item => {
                  return (
                    <div key={`likeTrack-${item?._id}`}>
                      <LikeTrackItem item={item} key={item._id} />
                      <div
                        style={
                          {
                            width: 'calc(100vw + 20px)',
                            height: 1,
                            backgroundColor: '#eaeaea',
                          }
                        }
                      />
                    </div>
                  )
                })
              }
            }
          </Observer>
        </Flex>
      </Flex>
      {
        !isEnded && (
          <Button
            onClick={onPressMoreTrackList}
            style={
              {
                marginTop: 12,
                justifyContent: 'center',
                alignContent: 'center',
              }
            }
          >
            <ButtonText style={{ lineHeight: '36px' }}>더보기</ButtonText>
          </Button>
        )
      }
    </Flex>
  )
}

export default LikeTrackList

const List = styled(Flex)`
  overflow-x: scroll;
  margin-left: -20px;
  padding-left: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Button = styled(Flex)`
  width: '100%';
  height: 36px;
  border-radius: 2px;
  background-color: #f8f8f8;
`

const ButtonText = styled(Caption1)`
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #646464;
`

const LikeTrackItem = ({ item }) => {
  const { title, _id, likeCount } = item
  const { name } = item?.artistInfo

  const listnerCount = item?.listenerCountInfo?.total
  const totalListener = item?.listenerCountInfo?.increaseValue?.total
  const totalRanking = item?.rankInfo?.increaseValue?.melon
  const nowRanking = item?.rankInfo?.melon || 0

  const chartIn =
    item?.rankInfo?.increaseValue?.melon * -1 === item?.rankInfo?.melon

  return (
    <Link to={`/track/${_id}`}>
      <Flex style={{ flexDirection: 'row', height: '57px' }}>
        <Flex align="center" style={{ gap: 10, flex: 1, paddingLeft: '20px' }}>
          <Flex
            style={
              {
                width: 28,
                height: 28,
                overflow: 'hidden',
              }
            }
          >
            <img
              src={STORAGE_URL + item?.image64Path}
              alt="artist"
              width={28}
              height={28}
            />
          </Flex>
          <Flex style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <ArtistText style={{ width: 144 }}>{name}</ArtistText>
            <NameText style={{ width: 144 }}>{title}</NameText>
          </Flex>
        </Flex>
        <Flex
          style={
            {
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 80,
            }
          }
        >
          <CountText>{listnerCount?.toLocaleString?.() || 0}</CountText>
          {
            totalListener ? (
              <Flex style={{ alignItems: 'center', justifyItems: 'center' }}>
                {
                  totalListener > 0 ? (
                    <Flex style={{ marginBottom: 4, marginRight: 4 }}>
                      <IconUp />
                    </Flex>
                  ) : (
                    <Flex style={{ marginTop: 6, marginRight: 4 }}>
                      <IconDown />
                    </Flex>
                  )
                }
                <DiffText>{Math.abs(totalListener).toLocaleString()}</DiffText>
              </Flex>
            ) : (
              <DiffText>{'-'}</DiffText>
            )
          }
        </Flex>
        <Flex
          style={
            {
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 80,
            }
          }
        >
          <CountText>
            {nowRanking === 0 ? '-' : `${nowRanking.toLocaleString()}위`}
          </CountText>
          <Flex style={{ alignItems: 'center', justifyItems: 'center' }}>
            {
              chartIn ? (
                <DiffText>{'Chart In'}</DiffText>
              ) : totalRanking ? (
                <Flex style={{ alignItems: 'center', justifyItems: 'center' }}>
                  {
                    totalRanking > 0 ? (
                      <Flex style={{ marginBottom: 4, marginRight: 4 }}>
                        <IconUp />
                      </Flex>
                    ) : (
                      <Flex style={{ marginTop: 6, marginRight: 4 }}>
                        <IconDown />
                      </Flex>
                    )
                  }
                  <DiffText>{Math.abs(totalRanking).toLocaleString()}</DiffText>
                </Flex>
              ) : (
                <DiffText>{'-'}</DiffText>
              )
            }
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}

const TrackItemSkeleton = () => {
  return (
    <Flex style={{ flexDirection: 'row', height: '57px', lineHeight: 1 }}>
      <Flex align="center" style={{ gap: 10, flex: 1, paddingLeft: '20px' }}>
        <Flex
          style={
            {
              width: 28,
              height: 28,
              overflow: 'hidden',
            }
          }
        >
          <Skeleton width={28} height={28} />
        </Flex>
        <Flex style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <ArtistText style={{ width: 144 }}>
            <Skeleton />
          </ArtistText>
          <NameText style={{ width: 144, marginTop: '4px' }}>
            <Skeleton />
          </NameText>
        </Flex>
      </Flex>

      <Flex
        style={
          {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 80,
            lineHeight: 1,
          }
        }
      >
        <CountText>
          <Skeleton width={60} height={15} style={{ marginBottom: '2px' }} />
        </CountText>
        <CountText>
          <Skeleton width={40} height={12} />
        </CountText>
      </Flex>

      <Flex
        style={
          {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 80,
          }
        }
      >
        <CountText>
          <Skeleton width={60} height={15} style={{ marginBottom: '2px' }} />
        </CountText>
        <CountText>
          <Skeleton width={40} height={12} />
        </CountText>
      </Flex>
    </Flex>
  )
}

const IconUp = styled(Flex)`
  width: 0;
  height: 0;
  border-bottom: 5px solid #66bc6a;
  border-top: 5px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`
const IconDown = styled(Flex)`
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid #ea4653;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`

const DiffText = styled(Caption1)`
  font-family: NotoSansCJKkr-Regular;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #949494;
`

const CountText = styled(Caption1)`
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
`

const ArtistText = styled(Caption1)`
  display: block;
  font-family: NotoSansCJKkr-Regular;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.8; */
  letter-spacing: normal;
  text-align: left;
  color: #949494;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
`

const NameText = styled(Caption1)`
  display: block;
  font-family: NotoSansCJKkr-Regular;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.8; */
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleText = styled(Caption1)`
  display: block;
  width: 100%;
  font-family: NotoSansCJKkr-Regular;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SortText = styled(Caption1)`
  display: block;
  font-family: NotoSansCJKkr-Regular;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          font-family: NotoSansCJKkr-Medium;
          color: #242424;
        `
      : css`
          color: #949494;
        `}}
`
