import { Network } from '.'

export default class SearchNetwork {
  constructor(network: Network) {
    this.network = network
  }

  // 검색어 자동완성
  async getSearchAutoComplete(keyword) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/search?keyword=${keyword}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('SearchNetwork getSearchAutocomplete error', error)
    }
  }

  // 검색 결과 리스트 조회
  async getSearchKeyword(keyword) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/search/total?keyword=${keyword}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('SearchNetwork getSearchKeyword error', error)
    }
  }

  // 아티스트 검색 결과 조회
  async getSearchArtist(keyword, offset) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/search/artist?keyword=${keyword}&offset=${offset}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('SearchNetwork getSearchArtist error', error)
    }
  }

  // 앨범 검색 결과 조회
  async getSearchAlbum(keyword, offset) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/search/album?keyword=${keyword}&offset=${offset}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('SearchNetwork getSearchAlbum error', error)
    }
  }

  // 트랙 검색 결과 조회
  async getSearchTrack(keyword, offset) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/search/track?keyword=${keyword}&offset=${offset}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('SearchNetwork getSearchTrack error', error)
    }
  }

  // 자동완성 검색어 카운트
  async postSearchCount(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/search`,
        'post',
        params,
      )
      return data.data || null
    } catch (error) {
      console.warn('SearchNetwork postSearchCount error', error)
    }
  }
}
