import React, { useState } from 'react'
import {
  Flex,
  BodyWrapper,
  Typography,
  Input,
  Caption1,
} from '@components/atoms'
import { plamin_logo_img } from '@images/index'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'

const AuthChangePasswordTemplate = () => {
  const history = useHistory()
  const { authStore } = useStore()

  const { currentUser } = authStore
  const changePassword = authStore?.changePassword || (() => { })

  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [error, setError] = useState('')

  const handleFindAccount = async () => {
    const params = {
      _id: currentUser?._id,
      password,
      newPassword,
    }
    const res = await changePassword(params)
    if (res && !res.data.isModify) {
      if (res?.message === 'The current passwords do not match.') {
        setError('unmatch')
      } else {
        setError('nouser')
      }
    } else {
      setError('')
      window.location.href = '/'
    }
  }

  return (
    <BodyWrapper>
      <Flex
        type="column"
        style={{
          height: '100vh',
          padding: '56px 0',
          boxSizing: 'border-box',
        }}
      >
        <Flex
          type="column"
          style={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <img
            src={plamin_logo_img}
            width={126}
            height={36}
            alt="logo"
            onClick={() => (window.location.href = '/')}
          />
          <Typography
            size="38px"
            type="bold"
            style={{ margin: '32px 0 12px 0', textAlign: 'center' }}
          >
            새 비밀번호
            <br />
            설정
          </Typography>
          <Flex type="column" style={{ width: '100%', marginTop: 32, gap: 12 }}>
            <Flex type="column">
              <Input
                type="password"
                placeholder="현재 비밀번호"
                style={{ height: 48, width: '100%' }}
                value={password}
                onChange={e => {
                  setPassword(e.currentTarget.value)
                }}
              />
              {error === 'unmatch' && (
                <Caption1
                  color="#ea4653"
                  align="left"
                  style={{ marginTop: 8, width: '100%' }}
                >
                  현재 비밀번호가 일치하지 않습니다.
                </Caption1>
              )}
            </Flex>
            <Flex type="column">
              <Input
                type="password"
                placeholder="새 비밀번호"
                style={{ height: 48, width: '100%' }}
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.currentTarget.value)
                }}
              />
              {newPassword && newPassword.length < 8 && (
                <Caption1
                  color="#ea4653"
                  align="left"
                  style={{ marginTop: 8, width: '100%' }}
                >
                  비밀번호 8자 이상 입력해주세요.
                </Caption1>
              )}
            </Flex>
            <Flex type="column">
              <Input
                type="password"
                placeholder="새 비밀번호 확인"
                style={{ height: 48, width: '100%' }}
                value={confirmNewPassword}
                onChange={e => {
                  setConfirmNewPassword(e.currentTarget.value)
                }}
              />
              {newPassword &&
                confirmNewPassword &&
                newPassword !== confirmNewPassword && (
                  <Caption1
                    color="#ea4653"
                    align="left"
                    style={{ marginTop: 8, width: '100%' }}
                  >
                    이메일을 다시 확인해주세요.
                  </Caption1>
                )}
            </Flex>
          </Flex>
        </Flex>
        <Button
          disabled={
            !password ||
            !newPassword ||
            !confirmNewPassword ||
            newPassword !== confirmNewPassword
          }
          onClick={handleFindAccount}
        >
          비밀번호 재설정
        </Button>
      </Flex>
    </BodyWrapper>
  )
}

export default AuthChangePasswordTemplate

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  border: none;
  background-color: black;
  color: white;

  :disabled {
    color: #bfbfbf;
    background-color: #f4f4f4;
  }
`

const Box = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 104px;
  border-radius: 2px;
  border: solid 0.5px #eaeaea;
  background-color: #fafafa;
  padding: 0 36px;
`
