import { Caption1, Caption2, Flex, H6 } from '@components/atoms'
import {
  default_profile_img,
  like_on_img,
  like_off_img_gray,
  circle_kakao_img,
  circle_facebook_img,
  circle_google_img,
  check_img,
  check_empty_img,
} from '@images/'
import React, { useEffect, useState } from 'react'
import { colors } from '@colors/'
import styled, { css } from 'styled-components'
import { dateYMDMM } from '@utils/format'
import { useObserver } from 'mobx-react'
import TextareaAutosize from 'react-textarea-autosize'
import { LinkInText } from '@components/molecules'

const ReplyCommentCard = ({
  item,
  currentUserId,
  targetingReplyComment,
  handleLike,
  _updateComment,
  isArticle = false,
  articleWriterId = false,
}) => {
  const [isUpdate, setIsUpdate] = useState(false) // 수정 유무
  const [updateValue, setUpdateValue] = useState(item.text || '') // 수정 댓글 내용
  const [isAnonymous, setIsAnonymous] = useState(!!item.isAnonymous) // 익명 유무

  const handleUpdate = async () => {
    await _updateComment(updateValue, isAnonymous)
    setIsUpdate(false)
  }

  if (item) {
    return useObserver(() => (
      <CardBox isDelete={item.isViewHidden || item.deletedAt}>
        {/* Header */}
        <Flex justify="space-between" align="center">
          <UserImage
            src={
              item.writerInfo && !item.isAnonymous
                ? item.writerInfo.image128Uri
                  ? item.writerInfo.image128Uri
                  : item.writerInfo.socialType === 'kakao'
                    ? circle_kakao_img
                    : item.writerInfo.socialType === 'facebook'
                      ? circle_facebook_img
                      : circle_google_img
                : item.writerInfo.socialType === 'kakao'
                  ? circle_kakao_img
                  : item.writerInfo.socialType === 'facebook'
                    ? circle_facebook_img
                    : circle_google_img
            }
          />
          <Flex style={{ flex: 1 }} align="center" justify="space-between">
            <Flex align="center">
              <H6 style={{ marginRight: 8 }}>
                {
                  item.isAnonymous
                    ? isArticle
                      ? item.writerId === articleWriterId
                        ? '글쓴이'
                        : '익명'
                      : '익명'
                    : item.writerInfo && item.writerInfo.nickname
                }
              </H6>
              <Caption2 color={colors.brown_grey}>
                {item.updatedAt && dateYMDMM(item.updatedAt)}
              </Caption2>
            </Flex>
            {
              false && isUpdate && (
                <Flex
                  style={
                    {
                      curosor: 'pointer',
                    }
                  }
                  align="center"
                  onClick={() => setIsAnonymous(!isAnonymous)}
                >
                  <img
                    src={isAnonymous ? check_img : check_empty_img}
                    alt="check_box"
                    style={{ width: 16, height: 16, marginRight: 2 }}
                  />
                  <Caption1>익명</Caption1>
                </Flex>
              )
            }
          </Flex>
          {
            isUpdate ? (
              <></>
            ) : (
              <>
                {
                  item.isViewHidden || item.deletedAt ? (
                    false
                  ) : currentUserId && currentUserId === item.writerId ? (
                    <>
                      <Caption1
                        type="Regular"
                        style={
                          {
                            cursor: 'pointer',
                            padding: 8,
                            paddingRight: 0,
                            marginRight: 8,
                          }
                        }
                        color={colors.brown_grey}
                        onClick={
                          () => {
                            targetingReplyComment(item._id, 'update')
                            setIsUpdate(true)
                          }
                        }
                      >
                        수정
                      </Caption1>
                      <Caption1
                        type="Regular"
                        style={{ cursor: 'pointer', padding: 8, paddingRight: 0 }}
                        color={colors.brown_grey}
                        onClick={
                          () => {
                            targetingReplyComment(item._id, 'delete')
                          }
                        }
                      >
                        삭제
                      </Caption1>
                    </>
                  ) : (
                    <Caption1
                      type="Regular"
                      style={{ cursor: 'pointer', padding: 8, paddingRight: 0 }}
                      color={colors.brown_grey}
                      onClick={
                        () => {
                          targetingReplyComment(item._id, 'report')
                        }
                      }
                    >
                      신고
                    </Caption1>
                  )
                }
              </>
            )
          }
        </Flex>
        {/* Header end */}
        {/* Content */}
        {
          isUpdate ? (
            <CustomInput
              style={{ width: 'auto' }}
              value={updateValue}
              onChange={e => setUpdateValue(e.target.value)}
              maxLength={300}
              maxRows={3}
              autoCorrect="off"
              autoCapitalize="off"
            />
          ) : (
            <Content
              text={
                item.deletedAt
                  ? '삭제된 댓글입니다'
                  : item.isViewHidden
                    ? '비공개된 댓글입니다'
                    : item.text
              }
            />
          )
        }
        {/* Content end */}
        {/* Footer */}
        {
          isUpdate ? (
            <Flex justify="flex-end">
              <CancelBtn onClick={() => setIsUpdate(false)}>취소</CancelBtn>
              <UpdateSubmitBtn
                disabled={updateValue === ''}
                onClick={handleUpdate}
              >
                수정완료
              </UpdateSubmitBtn>
            </Flex>
          ) : (
            <>
              {
                !(item.isViewHidden || item.deletedAt) && (
                  <Flex align="center" onClick={() => handleLike(item)}>
                    <img
                      src={item.isLike ? like_on_img : like_off_img_gray}
                      style={{ width: 16, height: 16, cursor: 'pointer' }}
                      alt="like_icon"
                    />
                    <Caption1 style={{ margin: '0 16px 0 4px' }}>
                      {item.likeCount || 0}
                    </Caption1>
                  </Flex>
                )
              }
            </>
          )
        }
        {/* Footer end */}
      </CardBox>
    ))
  }
}

export default ReplyCommentCard

const CardBox = styled(Flex)`
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-bottom: solid 1px ${colors.whitef4};
  padding: 16px 0px 8px 0;
  box-sizing: border-box;

  ${props =>
    props.isDelete
    && css`
      background-color: #fafafa;
      width: calc(100% + 40px);
      margin-left: -20px;
      padding: 8px 20px;
      height: 90px;
    `}
`

const UserImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 16px;
  background-color: #d4d4d4;
`

const Content = styled(LinkInText)`
  font-family: NotoSansCJKkr-Regular;
  height: 100%;
  margin: 8px 0px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  background-color: transparent;

  border: none;
  resize: none;
`

const CustomInput = styled(TextareaAutosize)`
  font-family: NotoSansCJKkr-Regular;
  resize: none;
  width: calc(100% - 40px);
  font-size: 14px;
  margin: 7px 0 7px 5px;
  padding: 10px;
  border-radius: unset;
  min-height: 20px;
  line-height: 1.4;

  border: ${props =>
    props.value === '' ? 'solid 1px #f4f4f4' : 'solid 1px #242424'};

  :disabled {
    background-color: #ffffff;
    border: solid 1px #f4f4f4;
  }

  ::placeholder {
    background-color: trasparent;
    color: #949494;
  }
`

const CancelBtn = styled(Flex)`
  width: 48px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.whitef4};
  font-family: NotoSansCJKkr-Regular;
  font-size: 12px;
  cursor: pointer;
`

const UpdateSubmitBtn = styled(Flex)`
  font-size: 12px;
  width: 72px;
  height: 40px;
  box-sizing: border-box;
  border: ${props =>
    props.disabled ? 'solid 1px #f4f4f4' : 'solid 1px black'};
  color: ${props => (props.disabled ? '#d4d4d4' : 'white')};
  background-color: ${props => (props.disabled ? 'white' : 'black')};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`
