import { observable } from 'mobx'
import { STORAGE_URL } from '@consts/'
import TrackModel from './TrackModel'

export default class RewardModel {
  @observable _id
  @observable title
  @observable description
  @observable rewardTotalPoint
  @observable rewardRemainedPoint
  @observable rewardOncePoint
  @observable rewardCycle
  @observable rewardYoutubeUrl
  @observable expiredAt
  @observable isExpired
  @observable createdAt
  @observable updatedAt
  @observable deletedAt
  @observable trackId
  @observable trackInfo

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.title = props.title
      this.description = props.description
      this.rewardTotalPoint = props.rewardTotalPoint
      this.rewardRemainedPoint = props.rewardRemainedPoint
      this.rewardOncePoint = props.rewardOncePoint
      this.rewardCycle = props.rewardCycle
      this.rewardYoutubeUrl = props.rewardYoutubeUrl
      this.expiredAt = props.expiredAt
      this.isExpired = props.isExpired
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
      this.trackId = props.trackId
      this.trackInfo = new TrackModel(stores, props.trackInfo)

    }
  }
}
