import React, { useState } from 'react'
import { Typography, Flex } from '@components/atoms'
import { SharedBtn, LikeBtn, Image } from '@components/molecules'
import { LinkCopiedPopup } from '@components/organisms'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { handleAlbumImgError } from '@utils/handler'
import { DOMAIN_URL } from '@consts/'
import { default_album_img } from '@images'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const TrackCard = ({
  albumImg,
  title,
  artist,
  albumTitle,
  currentPath,
  albumId,
  uniqueName,
  likeInfo,
  updateLike,
  trackId,
  currentUser,
  setIsShowLoginPopup,
  setShowLikePopup,
  subdomainInfo,
  customUrlInfo,
  albumCustomUrlInfo,
  setIsDetailCover,
}) => {
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const handleLike = () => {
    if (currentUser) {
      if (likeInfo.isLike) {
        updateLike('delete', currentUser._id, trackId, 'track')
      } else {
        updateLike('add', currentUser._id, trackId, 'track')
        setShowLikePopup(true)
      }
    } else {
      setIsShowLoginPopup(true)
    }
  }

  return (
    // 음악 카드
    <>
      <Flex
        style={{
          padding: '46px 0 10px 0',
          justifyContent: 'space-between',
          zIndex: 1,
        }}
      >
        <Flex>
          <Image
            src={albumImg ? albumImg : default_album_img}
            size={['116px', '116px']}
            style={{
              borderRadius: '6px',
            }}
            onError={handleAlbumImgError}
            onClick={() => setIsDetailCover(true)}
          />
          <Flex type="column" style={{ paddingLeft: '16px' }}>
            <Typography
              color="#ffffff"
              type="Medium"
              size="16px"
              style={ellipsisStyle}
            >
              {title}
            </Typography>
            <Typography
              type="Medium"
              size="12px"
              color="#ffffff"
              style={{ ...ellipsisStyle, ...{ margin: '2px 0 4px 0' } }}
            >
              {artist &&
                artist.map((a, index) =>
                  a.isAuto ? (
                    <span key={a._id}>
                      {a.name}
                      {!(index === artist.length - 1) && ',\u00A0'}
                    </span>
                  ) : (
                    <Link
                      style={{
                        color: '#ffffff',
                        fontFamily: 'NotoSansCJKkr-Bold',
                      }}
                      to={`/artist/${a.uniqueName}`}
                      key={a._id}
                    >
                      {a.name}
                      {!(index === artist.length - 1) && ',\u00A0'}
                    </Link>
                  ),
                )}
            </Typography>
            {/* <Link to={'/album/' + albumId}> */}
            <Link
              to={`/album/${albumCustomUrlInfo && albumCustomUrlInfo.autoUrl
                  ? albumCustomUrlInfo.autoUrl
                  : albumId
                }`}
            >
              <Typography size="10px" color="#ffffff" style={ellipsisStyle}>
                {albumTitle}
              </Typography>
            </Link>
          </Flex>
        </Flex>
        <Flex
          type="column"
          style={{
            justifyContent: 'space-between',
          }}
        >
          <CopyToClipboard
            text={
              subdomainInfo &&
                subdomainInfo.subdomain &&
                subdomainInfo.isAccepted
                ? customUrlInfo && customUrlInfo.customUrl
                  ? `${subdomainInfo.subdomain}.${DOMAIN_URL}/${customUrlInfo &&
                  customUrlInfo.customUrl}`
                  : `${subdomainInfo.subdomain}.${DOMAIN_URL}/${customUrlInfo &&
                  customUrlInfo.autoUrl}`
                : currentPath
            }
            onCopy={() => linkCopy()}
          >
            <SharedBtn />
          </CopyToClipboard>
          <LikeBtn
            active={likeInfo && likeInfo.isLike}
            count={likeInfo && likeInfo.likeCount}
            onClick={() => handleLike()}
          />
        </Flex>
      </Flex>
      {isCopied && <LinkCopiedPopup />}
    </>
  )
}

export default TrackCard
