import { observable, computed } from 'mobx'
import { v4 } from 'uuid'

import { create } from 'mobx-persist'
import { IS_DEV } from '@consts/'
import { Network } from './networks'

import AuthStore from './AuthStore'
import ArtistStore from './ArtistStore'
import AlbumStore from './AlbumStore'
import RecordStore from './RecordStore'
import AppStateStore from './AppStateStore'
import DataMapStore from './DataMapStore'
import StatsStore from './StatsStore'
import SearchStore from './SearchStore'
import CommentStore from './CommentStore'
import ArticleStore from './ArticleStore'
import InplamStore from './InplamStore'
import TrendStore from './TrendStore'
import RewardStore from './RewardStore'

export {
  AuthStore,
  ArtistStore,
  AlbumStore,
  RecordStore,
  AppStateStore,
  DataMapStore,
  StatsStore,
  SearchStore,
  CommentStore,
  ArticleStore,
  InplamStore,
  TrendStore,
}

export class Store {
  @observable handlerObj = {}
  @computed get isLoading() {
    const list = Object.keys(this.handlerObj)
    let res = false
    if (this.handlerObj && list && list.length > 0) {
      res = true
    }

    return res
  }

  @observable handlerInitialLoadingObj = {}
  @computed get isInitialLoading() {
    const list = Object.keys(this.handlerInitialLoadingObj)
    let res = false
    if (this.handlerInitialLoadingObj && list && list.length > 0) {
      res = true
    }

    return res
  }

  _network: Network

  authStore: AuthStore
  artistStore: ArtistStore
  albumStore: AlbumStore
  recordStore: RecordStore
  appStateStore: AppStateStore
  dataMapStore: DataMapStore
  statsStore: StatsStore
  rewardStore: RewardStore
  searchStore: SearchStore
  commentStore: CommentStore
  articleStore: ArticleStore
  inplamStore: InplamStore
  trendStore: TrendStore

  constructor(hydrateCallback) {
    this._network = new Network({
      getJwt: () => this.authStore['jsonWebToken'],
    })

    this.authStore = new AuthStore(this, this._network)
    this.artistStore = new ArtistStore(this, this._network)
    this.albumStore = new AlbumStore(this, this._network)
    this.recordStore = new RecordStore(this, this._network)
    this.appStateStore = new AppStateStore(this, this._network)
    this.dataMapStore = new DataMapStore(this, this._network)
    this.statsStore = new StatsStore(this, this._network)
    this.rewardStore = new RewardStore(this, this._network)
    this.searchStore = new SearchStore(this, this._network)
    this.commentStore = new CommentStore(this, this._network)
    this.articleStore = new ArticleStore(this, this._network)
    this.inplamStore = new InplamStore(this, this._network)
    this.trendStore = new TrendStore(this, this._network)

    this.handlerObj = {}

    if (IS_DEV) console.log(`[DEV][LOG][Store][constructor] end`)

    const hydrate = create({
      storage: localStorage, // or AsyncStorage in react-native.
      // default: localStorage
      jsonify: true, // if you use AsyncStorage, here shoud be true
      // default: true
    })

    hydrate('searchStore', this.searchStore)
    hydrate('artistStore', this.artistStore)
    hydrate('authStore', this.authStore)
  }

  initClient = async () => {
    await Promise.all([
      this.authStore
      && this.authStore.initClient
      && this.authStore.initClient(),
    ])
    if (IS_DEV) console.log(`[DEV][LOG][Store][initClient] end`)
  }

  _isLoading = () => {
    return this.isLoading
  }

  useLoading = async (func, key) => {
    if (this && func) {
      let handlerKey = key ? key : v4()
      if (this.handlerObj?.[handlerKey]) {
        handlerKey = `${key}_${v4()}`
      }

      this.handlerObj[handlerKey] = { isLoading: true }

      let res = null
      try {
        res = await func()
      }
      catch (error) {
        console.log('[Store] [useLoading] error', error)
      }

      delete this.handlerObj[handlerKey]

      return res
    }
  }

  useInitialLoading = async (promise, key) => {
    if (this && promise) {
      let handlerKey = key ? key : v4()
      if (this.handlerInitialLoadingObj?.[handlerKey]) {
        handlerKey = `${key}_${v4()}`
      }

      this.handlerInitialLoadingObj[handlerKey] = { isLoading: true }

      let res = null
      try {
        res = await promise
      }
      catch (error) {
        console.log('[Store] [useInitialLoading] error', error)
      }

      delete this.handlerInitialLoadingObj[handlerKey]

      return res
    }
  }
}
