import { Caption1, Caption2, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const TableBox = styled.div`
  width: 100%;
  background-color: #ffffff;
`

const TrackSnsTableChart = ({ snsType, isFold = true }) => {
  const { trendStore } = useStore()

  const { artistTrendTrackList } = trendStore

  const parseData = useMemo(() => {
    // const sortedData = artistTrendTrackList?.sort((a, b) => b?.date - a?.date)
    const sortedData = artistTrendTrackList?.sort(
      (a, b) => b?.listenerCountInfo[snsType] - a?.listenerCountInfo[snsType],
    )

    if (isFold) return sortedData?.slice(0, 5)
    return sortedData
  }, [isFold, artistTrendTrackList, snsType])

  return (
    <TableBox>
      <Tr>
        <Th style={{ minWidth: '200px' }}>트랙</Th>
        <Th>{snsType === 'melon' ? '청취자 수' : '조회 수'}</Th>
        <Th>{snsType === 'melon' ? '순위' : '좋아요 수'}</Th>
      </Tr>
      {parseData?.map(rowData => {
        return (
          <Link
            to={`/track/${
              rowData.customUrlInfo
                ? rowData.customUrlInfo.autoUrl
                : rowData._id
            }`}
          >
            <Tr>
              <Td
                style={{
                  color: '#949494',
                  minWidth: '200px',
                  justifyContent: 'flex-start',
                }}
              >
                <Flex>
                  <img
                    src={STORAGE_URL + rowData?.albumInfo?.image64Path}
                    alt="cover_img"
                    style={{
                      width: '36px',
                      height: '36px',
                      borderRadius: '6px',
                      marginRight: '16px',
                    }}
                  />
                  <Flex type="column">
                    <Caption2
                      style={{
                        fontSize: 10,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                      align="left"
                      color="#949494"
                    >
                      {rowData?.albumInfo?.title}
                    </Caption2>
                    <Caption2
                      style={{
                        color: 'black',
                        fontSize: 12,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                      align="left"
                    >
                      {rowData?.title}
                    </Caption2>
                  </Flex>
                </Flex>
              </Td>
              <Td>
                <Flex type="column" align="center">
                  <Caption1>
                    {convertToPricingComma(
                      rowData?.listenerCountInfo[snsType] || 0,
                    )}
                  </Caption1>
                  <Flex align="center" style={{ gap: 4 }}>
                    {rowData?.listenerCountInfo?.increaseValue[snsType] > 0 ? (
                      <Up />
                    ) : rowData?.listenerCountInfo?.increaseValue[snsType] <
                      0 ? (
                      <Down />
                    ) : (
                      <></>
                    )}
                    <Caption2 color="#949494">
                      {rowData?.listenerCountInfo?.increaseValue[snsType] === 0
                        ? '-'
                        : convertToPricingComma(
                            Math.abs(
                              rowData?.listenerCountInfo?.increaseValue[
                                snsType
                              ],
                            ),
                          )}
                    </Caption2>
                  </Flex>
                </Flex>
              </Td>
              <Td>
                {snsType === 'melon' ? (
                  <Flex type="column" align="center">
                    {rowData?.rankInfo[snsType] && (
                      <Caption1>
                        {convertToPricingComma(rowData?.rankInfo[snsType])}
                      </Caption1>
                    )}
                    <Flex align="center" style={{ gap: 4 }}>
                      {rowData?.rankInfo?.increaseValue[snsType] > 0 ? (
                        <Up />
                      ) : rowData?.rankInfo?.increaseValue[snsType] < 0 ? (
                        <Down />
                      ) : (
                        <></>
                      )}
                      <Caption2 color="#949494">
                        {!rowData?.rankInfo?.increaseValue[snsType]
                          ? '-'
                          : convertToPricingComma(
                              Math.abs(
                                rowData?.rankInfo?.increaseValue[snsType],
                              ),
                            )}
                      </Caption2>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex type="column" align="center">
                    <Caption1>
                      {convertToPricingComma(
                        rowData?.likeCountInfo[snsType] || 0,
                      )}
                    </Caption1>
                    <Flex align="center" style={{ gap: 4 }}>
                      {rowData?.likeCountInfo?.increaseValue[snsType] > 0 ? (
                        <Up />
                      ) : rowData?.likeCountInfo?.increaseValue[snsType] < 0 ? (
                        <Down />
                      ) : (
                        <></>
                      )}
                      <Caption2 color="#949494">
                        {rowData?.likeCountInfo?.increaseValue[snsType] === 0
                          ? '-'
                          : convertToPricingComma(
                              Math.abs(
                                rowData?.likeCountInfo?.increaseValue[snsType],
                              ),
                            )}
                      </Caption2>
                    </Flex>
                  </Flex>
                )}
              </Td>
            </Tr>
          </Link>
        )
      })}
    </TableBox>
  )
}

export default observer(TrackSnsTableChart)

const Tr = styled(Flex)`
  flex: 1;
`

const Th = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;

  color: #646464;
  font-size: 12px;
`

const Td = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #66bc6a;

  margin-top: 2px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #ea4653;

  margin-top: 2px;
`
