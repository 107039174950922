import React from 'react'
import { BodyWrapper, Flex, H5 } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@colors'
import { Image } from '@components/molecules'
import { x_btn_black_img } from '@images/'
import { useHistory } from 'react-router-dom'
import { TermsOfUseInfo, PrivacyInfo } from '@components/organisms'

const PrivacyHeader = styled(Flex)`
  z-index: 2;
  position: fixed;
  top: 0;
  margin-left: -20px;
  width: 100%;
  min-width: 320px;
  max-width: 420px;
  height: 56px;
  box-shadow: 0 1px 2px 0 rgba(36, 36, 36, 0.16);
  background-color: ${colors.white};
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`

const PrivacyContent = styled(Flex)`
  position: relative;
  width: 100%;
  height: 100%;
  top: 38px;
  padding: 16px 0px;
`

const PrivacyTemplate = ({ privacy }) => {
  const history = useHistory()
  return (
    <BodyWrapper
      style={{ padding: '20px 20px 40px 20px', backgroundColor: '#fafafa' }}
    >
      <PrivacyHeader>
        <H5>
          {
            privacy === 'policy'
              ? '플램(PLAM) 개인정보처리방침'
              : '이용약관 동의'
          }
        </H5>
        <Image
          src={x_btn_black_img}
          size={['16px', '16px']}
          onClick={() => history.goBack()}
        />
      </PrivacyHeader>
      <PrivacyContent>
        {privacy === 'policy' ? <PrivacyInfo /> : <TermsOfUseInfo />}
      </PrivacyContent>
    </BodyWrapper>
  )
}

export default PrivacyTemplate
