import { BodyWrapper, Caption1, Flex, H4, H6 } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { arrow_left_img, check_img, check_empty_img } from '@images/index'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'

const SecessionTemplate = () => {
  const { authStore } = useStore()

  const { currentUser } = authStore
  const secession = authStore?.secession || (() => {})

  const [isAgree, setIsAgree] = useState(false)
  const history = useHistory()

  const handleSecession = async () => {
    const res = await secession()

    if (res) {
      alert('탈퇴가 완료되었습니다.')
      window.location.href = '/'
    }
  }

  useEffect(() => {
    if (!currentUser || currentUser?.artistId) {
      window.location.href = '/'
    }
  }, [currentUser])

  return (
    <>
      <BodyWrapper
        style={{
          padding: '16px 20px',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <Flex>
          <img
            src={arrow_left_img}
            alt="back button"
            width={24}
            height={24}
            onClick={() => history.goBack()}
          />
        </Flex>
        <H4 align="left" style={{ margin: '32px 0 16px 0' }} type="bold">
          회원탈퇴
        </H4>
        <NoticeBox>
          <H6
            align="left"
            style={{ lineHeight: '24px', textIndent: -18, marginLeft: 18 }}
          >
            • 회원님이 남기신 글과 댓글, 회원 정보 등
            <br />
            모두 삭제되어 복구할 수 없으며,
            <br />
            로그인이 필요한 서비스의 이용이
            <br />
            불가능하게 됩니다.
            <br />
            그래도 회원 탈퇴를 진행할까요?
          </H6>
        </NoticeBox>
        <AgreeBox onClick={() => setIsAgree(prev => !prev)}>
          <img
            src={isAgree ? check_img : check_empty_img}
            alt="check_box"
            style={{ width: 24, height: 24, cursor: 'pointer' }}
          />
          <Caption1 style={{ flex: 1 }} align="left">
            안내 사항을 모두 확인하였으며, 이에 동의합니다.
          </Caption1>
        </AgreeBox>
        <ButtonList>
          <Button
            disabled={!isAgree}
            style={{
              backgroundColor: isAgree ? '#fff' : '#f4f4f4',
              color: isAgree ? '#242424' : '#bfbfbf',
            }}
            onClick={handleSecession}
          >
            탈퇴하기
          </Button>
          <Button
            style={{ backgroundColor: '#242424', color: '#fff' }}
            onClick={history.goBack}
          >
            취소
          </Button>
        </ButtonList>
      </BodyWrapper>
    </>
  )
}

export default observer(SecessionTemplate)

const ButtonList = styled.div`
  position: absolute;
  bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(100% - 40px);
`

const Button = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 10px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #eaeaea;

  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 500;
  color: #242424;
`

const NoticeBox = styled.div`
  padding: 20px 16px 20px 30px;

  border-radius: 6px;
  border: 1px solid #eaeaea;
  background-color: #fafafa;

  white-space: pre-wrap;

  margin-bottom: 8px;
`

const AgreeBox = styled.div`
  cursor: pointer;
  padding: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #eaeaea;
  background-color: #fff;
`
