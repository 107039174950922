import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, BodyWrapper, H6, H4 } from '@components/atoms'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import { BurgerMenu } from '@components/organisms'
import { colors } from '@colors/'
import { useObserver, Observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Pagination from 'react-js-pagination'
import {
  InplamArtistListItem,
  InplamArtistListItemSkeleton,
  TotalInPlamListItem,
} from '@components/molecules'
import { useStore } from '@utils/hooks'

const InPlamTotalTemplate = ({
  offset,
  handlePagination,
  sortBy,
  handleChangeSortBy,
  artistInfo,
  totalArticleList,
  totalArticleListCount,
  activeTab,
  setActiveTab,
  totalInplamList,
  currentUser,
}) => {
  const history = useHistory()
  const { handlerObj } = useStore()

  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const handleMoveDetail = id => {
    if (!id) return
    history.push({
      pathname: `/fanspot/total/${id}`,
      search: `?offset=${offset}&sortBy=${sortBy}`,
      state: { fromActivity: 'all' },
    })
  }

  return useObserver(() => (
    <>
      <Helmet>
        <title>{`팬스팟 전체글`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pageWrapId={'comment_template'}
        outerContainerId={'App'}
        pageTitle="팬스팟"
        pageId="total"
        pageType="fanspot"
      />
      <BodyWrapper
        id="comment_template"
        style={{
          paddingTop: 56,
          paddingBottom: 64,
          minHeight: '100vh',
        }}
      >
        <Flex type="column">
          <Flex type="column" style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Flex
              justify="space-between"
              align="center"
              style={{ marginBottom: 16 }}
            >
              <H4 type="Bold">팬스팟 전체목록</H4>
            </Flex>
            <Observer>
              {() => {
                return (
                  <HomeInplamArtistList>
                    {/* <BottomGradient onClick={() => history.push('/fanspot')} /> */}
                    {handlerObj?.['fetchTotalInplamList']?.isLoading
                      ? Array(20)
                          .fill(1)
                          .map((item, index) => (
                            <InplamArtistListItemSkeleton
                              key={`inplamArtistSkel-${index}`}
                            />
                          ))
                      : totalInplamList?.map(item => (
                          <InplamArtistListItem
                            item={item}
                            key={item._id}
                            onClick={() => {
                              if (item?.isInplamOpen) {
                                history.push(
                                  // `/artist/${item.uniqueName}`,
                                  `/fanspot/${item.uniqueName}?offset=0&sortBy=createdAt`,
                                )
                              } else {
                                history.push(`/artist/${item.uniqueName}`)
                              }
                            }}
                          />
                        ))}
                  </HomeInplamArtistList>
                )
              }}
            </Observer>

            <Flex
              justify="center"
              align="center"
              style={{
                width: '100%',
                margin: '8px 0 0 0',
                height: 40,
                cursor: 'pointer',
                border: 'solid 1px #d4d4d4',
              }}
              onClick={() => history.push('/fanspot')}
            >
              <H6>더보기</H6>
            </Flex>
          </Flex>
          <Header>
            <Flex style={{ gap: 16, height: 40, alignItems: 'flex-end' }}>
              {!!currentUser &&
                totalInplamList?.findIndex(val => !!val?.isInplamFavorite) >
                  -1 && (
                  <H6
                    style={{
                      boxSizing: 'border-box',
                      height: 30,
                      paddingBottom: 8,
                      borderBottom:
                        activeTab === 'favorite' ? '2px solid black' : 'none',
                      color: activeTab === 'favorite' ? 'black' : '#bfbfbf',
                      cursor: 'pointer',
                    }}
                    onClick={() => setActiveTab('favorite')}
                  >
                    나의 팬스팟
                  </H6>
                )}
              <H6
                style={{
                  boxSizing: 'border-box',
                  height: 30,
                  paddingBottom: 8,
                  borderBottom:
                    activeTab === 'total' ? '2px solid black' : 'none',
                  color: activeTab === 'total' ? 'black' : '#bfbfbf',
                  cursor: 'pointer',
                }}
                onClick={() => setActiveTab('total')}
              >
                전체 글
              </H6>
              <H6
                style={{
                  boxSizing: 'border-box',
                  height: 30,
                  paddingBottom: 8,
                  borderBottom:
                    activeTab === 'notice' ? '2px solid black' : 'none',
                  color: activeTab === 'notice' ? 'black' : '#bfbfbf',
                  cursor: 'pointer',
                }}
                onClick={() => setActiveTab('notice')}
              >
                공지 사항
              </H6>
            </Flex>
            <Select
              value={sortBy}
              onChange={e => handleChangeSortBy(e.target.value)}
            >
              <option value="createdAt">최신순</option>
              <option value="viewCount">조회순</option>
              <option value="commentCount">댓글순</option>
            </Select>
          </Header>
          <Br />
          {totalArticleList &&
            totalArticleList.map(item => (
              <TotalInPlamListItem
                item={item}
                key={item._id}
                artistInfo={artistInfo}
                onClick={handleMoveDetail}
              />
            ))}
          <Pagination
            activePage={offset + 1}
            itemsCountPerPage={10}
            totalItemsCount={totalArticleListCount || 0}
            pageRangeDisplayed={5}
            onChange={page => {
              handlePagination(page - 1)
            }}
            innerClass="pagination-ul"
            activeClass="current-page"
            itemClass="pagination-li"
            // hideFirstLastPages
            hideNavigation
            // prevPageText={false}
            // nextPageText={false}
            firstPageText={
              <PrevNextPage
                src={offset + 1 !== 1 ? first_page_img : no_first_page_img}
              />
            }
            lastPageText={
              <PrevNextPage
                src={
                  offset + 1 !== Math.ceil(totalArticleListCount / 10)
                    ? last_page_img
                    : no_last_page_img
                }
              />
            }
          />
        </Flex>
      </BodyWrapper>
    </>
  ))
}

export default InPlamTotalTemplate

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 40px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 38px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 50px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`

const HomeInplamArtistList = styled(Flex)`
  position: relative;
  margin-left: -8px;
  flex-wrap: wrap;
  div + div {
    margin: 0 4px 8px 4px;
  }
  div:first-child {
    margin: 0px 4px;
  }

  max-height: 200px;
  overflow: hidden;
`

const BottomGradient = styled.div`
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`
