import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import './Popup.css'
import styled, { css, keyframes } from 'styled-components'
import { Typography } from '../../atoms'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const AnimatioWrapper = styled.div`
  animation-name: ${boxFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 9999;
`

const PopupInner = styled.div`
  position: fixed;
  max-width: 380px;
  min-width: 280px;
  width: calc(100% - 40px);
  height: 50px;
  box-shadow: 0 0 6px 0 rgba(36, 36, 36, 0.78);
  transform: translate(-50%, -50%);
  left: 50%;
  ${props =>
    props.scrollTop === 0
      ? css`
          top: 75%;
        `
      : css`
          top: 75%;
        `}
  background: #242424;
  z-index: 9999;
  border-radius: 6px;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
`

export default () => {
  const [isShowing, setIsShowing] = useState(false)

  const toggleSnackBar = () => {
    setIsShowing(true)
    setTimeout(() => setIsShowing(false), 3000)
  }

  const View = ({ text, backgroundColor = '#242424' }) => {
    if (isShowing) {
      return ReactDOM.createPortal(
        <AnimatioWrapper>
          <PopupInner style={{ backgroundColor }}>
            <Typography type="Medium" size="12px">
              {text && text}
            </Typography>
          </PopupInner>
        </AnimatioWrapper>,
        document.body,
      )
    }
    return <></>
  }

  return {
    View,
    toggleSnackBar,
  }
}
