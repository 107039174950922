import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import ArtistVideoItem from '@components/molecules/box/ArtistVideoItem'
import styled from 'styled-components'
import { move_down_gray_img } from '@images'
import { Typography, Flex } from '../../atoms'

const ArtistVideoList = ({
  list,
  sortBy,
  setSortBy,
  offset,
  setOffset,
  maxCount,
}) => {
  const countOffset = () => {
    setOffset(offset + 1)
  }

  return (
    <Flex type="column">
      <div
        style={{
          alignSelf: 'flex-end',
          margin: '-20px 0 20px 0',
        }}
      >
        <Typography
          type="Medium"
          size="12px"
          color={
            sortBy === 'youtubeVideoInfo.viewCount' ? '#242424' : '#c9c9c9'
          }
          style={{ paddingRight: '10px', cursor: 'pointer' }}
          onClick={() => setSortBy('youtubeVideoInfo.viewCount')}
        >
          인기순
        </Typography>
        <Typography
          type="Medium"
          size="12px"
          color={
            sortBy === 'youtubeVideoInfo.publishedAt' ? '#242424' : '#c9c9c9'
          }
          style={{ paddingRight: '10px', cursor: 'pointer' }}
          onClick={() => setSortBy('youtubeVideoInfo.publishedAt')}
        >
          최신순
        </Typography>
      </div>
      <InfiniteScroll
        dataLength={list.length}
        scrollThreshold={0.8}
        next={countOffset}
        hasMore={maxCount > list.length}
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={{
              width: '50px',
              height: '50px',
              margin: '0 auto 50px auto',
            }}
          />
        }
        // className="scroll_div"
      >
        {list &&
          list.map((item, index) => (
            <ArtistVideoItem item={item} index={index} key={index} />
          ))}
      </InfiniteScroll>
    </Flex>
  )
}

export default ArtistVideoList

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 50px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 62px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`
