// 주어진 배열에서 요소 1개를 랜덤하게 골라 반환하는 함수
export function randomItem(list) {
  return list[Math.floor(Math.random() * list.length)]
}

export const shuffleArray = arr => {
  for (let i = 0; i < arr.length; i++) {
    const j = Math.floor(Math.random() * (i + 1))
    // [arr[i], arr[j]] = [arr[j], arr[i]];
    const x = arr[i]
    arr[i] = arr[j]
    arr[j] = x
  }
  return arr
}
