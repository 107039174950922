import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Flex, BodyWrapper, H6, Caption1, Caption2 } from '@components/atoms'
import { comment_img, edit_img_black, share_img_black } from '@images/'
import {
  BurgerMenu,
  CommentCard,
  FanspotScoreCard,
  InPlamList,
  LinkCopiedPopup,
  LoginPopup,
  OkCancelPopup,
} from '@components/organisms'
import { colors } from '@colors/'
import { useObserver } from 'mobx-react'
import { converseCommentUnitEng, convertToPricingComma } from '@utils/format'
import { useHistory } from 'react-router-dom'
import { action } from 'mobx'
import { Helmet } from 'react-helmet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DOMAIN_URL } from '@consts/'
import { no_next_page_img } from '@images/index'

interface CommmentTemplateProps {
  currentUser: any;
  isLike: boolean;
  uniqueName: string;
  artistInfo: object;
  offset: Number;
  setOffset: Function;
  sortBy: string;
  setSortBy: Function;
  isFromArtist: string;
  toggleFromArtist: Function;
  artistArticleList: Array<any>;
  popularArtistArticleList: Array<any>;
  artistArticleListCount: Number;
  createComment: Function;
  removeComment: Function;
  _fetchCommentList: Function;
  updateCommentLike: Function;
  updateLike: Function;
  showLoginPopup: boolean;
  setShowLoginPopup: Function;
  currentPath: any;
}

// 인기 글 컴포넌트
const PopularList = ({ popularArtistArticleList, history, uniqueName }) => {
  if (popularArtistArticleList && popularArtistArticleList.length > 0) {
    return (
      <>
        <ListBox>
          <Flex align="center" style={{ marginBottom: 10 }}>
            <H6 type="Bold" align="left">
              인기 글
            </H6>
          </Flex>
          <PopularInPlamList>
            <InPlamListItem
              onClick={
                () =>
                  history.push(
                    `/fanspot/${uniqueName}/${popularArtistArticleList[0]._id}`,
                  )
              }
            >
              <TitleText>{popularArtistArticleList[0].title}</TitleText>
              <CommentCountText>
                {
                  popularArtistArticleList[0].commentCount
                  && converseCommentUnitEng(
                    popularArtistArticleList[0].commentCount,
                  )
                }
              </CommentCountText>
            </InPlamListItem>
            <InPlamListItem
              onClick={
                () =>
                  history.push(
                    `/fanspot/${uniqueName}/${popularArtistArticleList[1]._id}`,
                  )
              }
            >
              <TitleText>{popularArtistArticleList[1].title}</TitleText>
              <CommentCountText>
                {
                  popularArtistArticleList[1].commentCount
                  && converseCommentUnitEng(
                    popularArtistArticleList[1].commentCount,
                  )
                }
              </CommentCountText>
            </InPlamListItem>
            <InPlamListItem
              onClick={
                () =>
                  history.push(
                    `/fanspot/${uniqueName}/${popularArtistArticleList[2]._id}`,
                  )
              }
            >
              <TitleText>{popularArtistArticleList[2].title}</TitleText>
              <CommentCountText>
                {
                  popularArtistArticleList[2].commentCount
                  && converseCommentUnitEng(
                    popularArtistArticleList[2].commentCount,
                  )
                }
              </CommentCountText>
            </InPlamListItem>
          </PopularInPlamList>
        </ListBox>
      </>
    )
  }
  return <></>
}

const InPlamArtistTemplate = ({
  currentUser,
  uniqueName,
  offset,
  handlePagination,
  sortBy,
  handleChangeSortBy,
  isFromArtist,
  toggleFromArtist,
  artistInfo,
  artistArticleList,
  popularArtistArticleList,
  artistArticleListCount,
  currentPath,
  artistFanSpotPointInfo,
}: CommmentTemplateProps) => {
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const handleMoveDetail = id => {
    if (!id) return
    history.push(
      `/fanspot/${uniqueName}/${id}?offset=${offset}&sortBy=${sortBy}`,
    )
  }

  return useObserver(() => (
    <>
      <Helmet>
        <title>{`(${artistInfo && artistInfo.name}) 팬스팟`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // pageWrapId={'comment_template'}
        pageWrapId={'inplam_template'}
        outerContainerId={'App'}
        pageTitle={artistInfo && artistInfo.name}
        pageId={artistInfo && artistInfo.uniqueName}
        pageType="artist"
      />
      <BodyWrapper
        id="inplam_template"
        style={{ paddingTop: 0, paddingBottom: 64, minHeight: '100vh' }}
      >
        <Header>
          <FromArtist
            onClick={() => artistInfo.isWebPlamUser && toggleFromArtist()}
            isFromArtist={isFromArtist}
          >
            From. Artist
          </FromArtist>

          <Flex>
            <Flex
              align="center"
              justify="center"
              style={{ cursor: 'pointer' }}
              onClick={
                () =>
                  history.push(
                    `/fanspot/add/${uniqueName}/${artistInfo && artistInfo._id}`,
                  )
              }
            >
              <img
                src={edit_img_black}
                alt="edit_icon"
                style={{ width: 16, height: 16, marginRight: 2 }}
              />
              <Caption1>글쓰기</Caption1>
            </Flex>
            <CopyToClipboard
              text={
                artistInfo && artistInfo.subdomain
                  ? `${artistInfo.subdomain}.${DOMAIN_URL}/fanspot`
                  : currentPath
              }
              onCopy={
                () => {
                  linkCopy()
                }
              }
            >
              <img
                src={share_img_black}
                alt="share_icon"
                style={
                  {
                    height: 16,
                    marginLeft: 16,
                    padding: 2,
                    cursor: 'pointer',
                  }
                }
              />
            </CopyToClipboard>
          </Flex>
        </Header>

        <FanspotScoreCard
          artistFanspotScore={
            artistFanSpotPointInfo?.artistFanSpotPointInfo?.point
          }
          artistRank={artistFanSpotPointInfo?.artistFanSpotPointInfo?.rank}
          myFanspotScore={
            artistFanSpotPointInfo?.artistUserFanSpotPointInfo?.point
          }
          myRank={artistFanSpotPointInfo?.artistUserFanSpotPointInfo?.rank}
          isDetailPage={false}
          artistInfo={artistInfo}
          currentUser={currentUser}
        />
        {/* <PopularList
          popularArtistArticleList={popularArtistArticleList}
          history={history}
          uniqueName={uniqueName}
        /> */}
        <InPlamList
          offset={offset}
          handlePagination={handlePagination}
          artistArticleList={artistArticleList}
          artistArticleListCount={artistArticleListCount}
          sortBy={sortBy}
          handleChangeSortBy={handleChangeSortBy}
          artistInfo={artistInfo}
          handleMoveDetail={handleMoveDetail}
        />
        {isCopied && <LinkCopiedPopup />}
      </BodyWrapper>
    </>
  ))
}

export default InPlamArtistTemplate

const Header = styled(Flex)`
  margin-top: 56px;
  padding-top: 16px;
  height: 40px;
  justify-content: space-between;
  background-color: white;
  align-items: center;
`

const FromArtist = styled(Flex)`
  font-size: 12px;
  align-items: center;
  padding: 7px 10px;
  background-color: ${colors.whitef4};
  color: ${colors.light_pink};
  cursor: pointer;
  width: 88px;
  box-sizing: border-box;
  height: 32px;

  ${props =>
    props.isFromArtist === 'artist'
    && css`
      background-color: ${colors.black};
      color: ${colors.white};
    `}
`

/* popular List */
const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const PopularInPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  max-height: 90px;
  overflow: auto;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(Caption1)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
`

const CommentCountText = styled(Caption1)`
  font-family: NotoSansCJKkr-Regular;
  margin-left: 5px;
  min-width: 48px;
  max-width: 48px;
  padding: 3px 9px;
  border: solid 1px ${colors.light_pink};
  border-radius: 12px;
  height: 24px;
  box-sizing: border-box;
  margin-left: 7px;
  line-height: 1.35;
`

/* popular List end */
