import React, { useEffect, useRef, useState } from 'react'
import {
  MyIdolMainTemplate,
  MyIdolQuizTemplate,
  MyIdolLoadingTemplate,
} from '@components/templates'
import { Helmet } from 'react-helmet'

const MyIdolPage = () => {
  const [step, setStep] = useState(0)
  const [mbti, setMbti] = useState('')

  const progress = useRef({ IE: 0, SN: 0, TF: 0, PJ: 0 })

  const hanldeAnswer = (quiz, answer_yn) => {
    if (answer_yn === 'Y') progress.current[quiz.type] += 1
    if (answer_yn === 'N') progress.current[quiz.type] -= 1
    setStep(step + 1)
  }

  useEffect(() => {
    let mbtiProgress = ''
    if (step === 13) {
      if (progress.current['IE'] > 0) mbtiProgress = 'I'
      if (progress.current['IE'] < 0) mbtiProgress = 'E'

      if (progress.current['SN'] > 0) mbtiProgress += 'S'
      if (progress.current['SN'] < 0) mbtiProgress += 'N'

      if (progress.current['TF'] > 0) mbtiProgress += 'T'
      if (progress.current['TF'] < 0) mbtiProgress += 'F'

      if (progress.current['PJ'] > 0) mbtiProgress += 'P'
      if (progress.current['PJ'] < 0) mbtiProgress += 'J'

      setMbti(mbtiProgress)
    }
  }, [step, progress.current])

  return (
    <>
      <Helmet>
        <title>{`내가 만약 '아이돌' 이었다면? | 플램`}</title>
        <meta name="description" content={'MBTI 테스트 설명'} />
        <meta
          name="keywords"
          content="mbti,부캐,아이돌,디스코그라피,음악,음반,앨범,음원유통,음원유통사,CD,아이돌,뮤지션,아티스트,프로필,music,album,linkfire,플램인,플램,plam,plamin"
        />
      </Helmet>
      {
        step === 0 ? (
          <MyIdolMainTemplate setStep={setStep} />
        ) : step < 13 ? (
          <MyIdolQuizTemplate step={step} hanldeAnswer={hanldeAnswer} />
        ) : step === 13 ? (
          <MyIdolLoadingTemplate mbti={mbti.toLowerCase()} />
        ) : (
          false
        )
      }
    </>
  )
}

export default MyIdolPage
