import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import {
  ArtistFollowersInfoModel,
  AlbumModel,
  ChartModel,
  RewardModel,
} from './models'

export default class RewardStore {
  @observable rewardList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.rewardList = []
  }

  @action.bound
  async initClient() {}

  /**
   *
   * @returns
   * __limit: 페이지네이션, 한번 요청에 반환받을 음감회 갯수
   * __offset: 페이지네이션, 페이징 number, 0,1,2,3,4... 순서대로 0번페이지 1번페이지 2번페이지
   * __filterdRewardIdList: 응답에 포함시키지 않을 음감회 id. ex)음감회 상세 페이지에서 다른 진행중인 음감회 조회
   * isExpired: true | false, true: 종료된 음감회, false: 진행중인 음감회, 기본값:false
   */
  @action.bound
  fetchRewardList = async (params: {
    __offset?: Number,
    __limit?: Number,
    __filterdRewardIdList?: Object[],
    isExpired: Boolean,
  }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.rewardNetwork.getReward(params)
      if (!res) return

      if (params.__offset === 0) {
        this.rewardList = res.rewardList
          .filter(elem => !!elem)
          .map(elem => new RewardModel(this.store, elem))
      }
      else if (params.__offset > 0) {
        this.rewardList = this.rewardList.concat(
          (this.rewardList = res.rewardList
            .filter(elem => !!elem)
            .map(elem => new RewardModel(this.store, elem))),
        )
      }

      return res.rewardList
    }, 'fetchRewardList')
  }

  @action.bound
  fetchRewardPreview = async rewardId => {
    if (!rewardId) {
      return
    }

    return this.store.useLoading(async () => {
      const res = await this.network.rewardNetwork.getRewardPreview(rewardId)
      if (!res) return

      return res.reward
    })
  }
}
