import React, { useEffect, useState } from 'react'
import {
  ArtistTemplate,
  AlbumTemplate,
  TrackTemplate,
  NotFoundTemplate,
} from '@components/templates'
import { Observer, inject, observer } from 'mobx-react'
import { useLastLocation } from 'react-router-last-location'
import qs from 'qs'
import { IS_DEV, DOMAIN_URL_LENGTH } from '@consts'

const PAGE_TYPE = {
  ARTIST: 'artist',
  ALBUM: 'album',
  TRACK: 'track',
  NOT_FOUND: 'notFound',
}
const DATA_TYPE_TO_PAGE_TYPE = {
  artist: PAGE_TYPE['ARTIST'],
  album: PAGE_TYPE['ALBUM'],
  track: PAGE_TYPE['TRACK'],
  notFound: PAGE_TYPE['NOT_FOUND'],
}

interface RootPageProps {
  recordStore: RecordStore;
  dataMapStore: DataMapStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  match: Any;
  history: Any;
  location: Any;
}

const RootPage = ({
  match,
  history,
  location,
  recordStore,
  dataMapStore,
  authStore,
  statsStore,
}: RootPageProps) => {
  const { host } = window.location
  const { protocol } = window.location
  const parts = host.split('.')
  const [subdomain, setSubdomain] = useState('')
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  /* store */
  const enterRecord = recordStore.enterRecord || (() => { })
  const recorded = recordStore.recorded || null

  const { currentUser } = authStore
  /* store end */

  const lastLocation = useLastLocation()

  // put record
  const updateRecord = async (type, url) => {
    const params = {
      _id: sessionStorage.getItem('recordId'),
      clickType: type,
      clickUrl: url,
    }

    await recordStore.updateRecord(params)
  }

  useEffect(() => {
    if (!currentUser) {
      localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [])

  useEffect(() => {
    if (match.params.id === 'inplam') {
      if (IS_DEV && query?.subdomain) {
        // subdomain = query?.subdomain
        setSubdomain(query?.subdomain)
      }
      else {
        // subdomain = parts[0]
        setSubdomain(parts[0])
        parts.splice(0, 1)
      }

      window.location.assign(
        `${protocol}//${parts.join('.')}/${match.params.id}/${subdomain}`,
      )
      return false
    }

    dataMapStore.fetchData(match.params.id)

  }, [match])

  useEffect(() => {
    // post Record
    const enterPage = {
      referrer: document.referrer,
      domain: document.domain,
      URL: document.URL,
      lastLocation,
      history,
      performance,
      type: 'root',
      pathId: match.params.id,
    }

    if (!recorded) {
      enterRecord(enterPage)
    }
  }, [match])

  return (
    <>
      <Observer>
        {() => {
          /* store */
          const socialLogin = authStore.socialLogin || (() => { })
          const logout = authStore.logout || (() => { })
          const { likeInfo } = authStore
          const fetchLike = authStore.fetchLike || (() => { })
          const updateLike = authStore.updateLike || (() => { })

          const artistFollowerInfo = statsStore.artistFollowerInfo || null

          const pageType = DATA_TYPE_TO_PAGE_TYPE[dataMapStore.dataType]
          const { data } = dataMapStore
          /* store end */

          if (pageType !== PAGE_TYPE['NOT_FOUND']) {
            return (
              pageType === PAGE_TYPE['ARTIST'] ? (
                <ArtistTemplate
                  artist={data}
                  currentPath={host + match.url}
                  updateRecord={updateRecord}
                  currentUser={currentUser}
                  socialLogin={socialLogin}
                  logout={logout}
                  fetchLike={fetchLike}
                  likeInfo={likeInfo}
                  updateLike={updateLike}
                  artistFollowerInfo={artistFollowerInfo}
                />
              ) : pageType === PAGE_TYPE['ALBUM'] ? (
                <AlbumTemplate
                  album={data}
                  currentPath={host + match.url}
                  updateRecord={updateRecord}
                  currentUser={currentUser}
                  socialLogin={socialLogin}
                  logout={logout}
                  fetchLike={fetchLike}
                  likeInfo={likeInfo}
                  updateLike={updateLike}
                />
              ) : (
                pageType === PAGE_TYPE['TRACK'] && (
                  <TrackTemplate
                    track={data}
                    currentPath={host + match.url}
                    updateRecord={updateRecord}
                    currentUser={currentUser}
                    socialLogin={socialLogin}
                    logout={logout}
                    fetchLike={fetchLike}
                    likeInfo={likeInfo}
                    updateLike={updateLike}
                  />
                )
              )
            )
          }
          return <NotFoundTemplate />
        }}
      </Observer>
    </>
  )
}

export default inject(
  'recordStore',
  'dataMapStore',
  'authStore',
  'statsStore',
)(observer(RootPage))
