import { Caption2, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import React from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'

const COLORS = ['#0066ff', '#ea4653', '#66bc6a']

const SnsLineChart = ({ dailyListData, snsType }) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={dailyListData.reverse()}>
        <XAxis
          hide
          dataKey="date"
          tickFormatter={tick => {
            const strTick = `${tick}`
            return `${strTick.slice(0, 4)}년 ${strTick.slice(
              4,
              6,
            )}월 ${strTick.slice(6, 8)}일`
          }}
          allowDuplicatedCategory={false}
        />
        <YAxis
          dataKey={`listenerInfo.${snsType}`}
          domain={['auto', 'auto']}
          hide
        />
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip content={<CustomTooltip snsType={snsType} />} />
        <Line
          dataKey={`listenerInfo.${snsType}`}
          name="Listeners"
          type="monotone"
          stroke={snsType === 'youtube' ? COLORS[1] : COLORS[2]}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default SnsLineChart

const CustomTooltip = ({ active, payload, label: _label, snsType }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >{`${label.slice(0, 4)}년 ${label.slice(4, 6)}월 ${label.slice(
          6,
          8,
        )}일`}</Caption2>
        <Flex justify="space-between">
          <Caption2 type="Bold">
            {snsType === 'melon' ? 'Listen' : 'View'}
          </Caption2>
          <Caption2 align="left">
            {`${item &&
              convertToPricingComma(item.listenerInfo[snsType] || 0)}`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Caption2 type="Bold">Like</Caption2>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.likeInfo[snsType] || 0)}`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Caption2 type="Bold">Follwer</Caption2>
          <Caption2 align="left">
            {`${item &&
              convertToPricingComma(
                (item.followerInfo && item.followerInfo[snsType]) || 0,
              )}`}
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}

const ToolTipBox = styled(Flex)`
  width: 114px;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 8px;
`
