import { Network } from '.'

export default class TrackNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getTracks(params) {
    try {
      const { data } = await this.network._axiosApi(`/track`, 'get', params)
      return (data && data['data']) || []
    } catch (error) {
      console.warn('TrackNetwork getTrackList error', error)
    }
  }

  async getTrack(trackId) {
    try {
      const { data } = await this.network._axiosApi(
        `/track/${trackId}`,
        'get',
      )
      const track = data && data['data'] && data['data']['track']
      return track || null
    } catch (error) {
      console.warn('TrackNetwork getTrack error', error)
    }
  }
}
