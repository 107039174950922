import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { ArtistFollowersInfoModel, AlbumModel, ChartModel } from './models'

export default class StatsStore {
  @observable artistFollowerInfo

  @observable plamChartList
  @observable latestAlbumList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistFollowerInfo = {}

    this.plamChartList = []
    this.latestAlbumList = []
  }

  @action.bound
  async initClient() {}

  // @action.bound
  // fetchSnsListLike = async (artistId, periodType) => {
  //   return this.store.useLoading(async () => {
  //     if (!artistId || !periodType) return

  //     const { data: res } = await this.network.statsNetwork.getSnsListLike(
  //       artistId,
  //       periodType,
  //     )
  //     if (!res) return

  //     this.artistFollowerInfo = new ArtistFollowersInfoModel(
  //       this.store,
  //       res.artistFollowerInfo,
  //     )
  //   })
  // }

  @action.bound
  fetchPlamChart = async periodType => {
    return this.store.useLoading(async () => {
      if (!periodType) return

      const res = await this.network.statsNetwork.getPlamChart(periodType)

      if (!res) return

      this.plamChartList = res.chart
        .filter(elem => !!elem)
        .map(elem => new ChartModel(this.store, elem))

      return !!res.chart
    })
  }

  @action.bound
  fetchLatestAlbum = async options => {
    return this.store.useLoading(async () => {
      const albumList = await this.network.albumNetwork.getAlbums(options)

      if (!albumList) return

      this.latestAlbumList = albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem))
    }, 'fetchLatestAlbum')

    return !!albumList
  }
}
