import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { CommentTemplate } from '@components/templates'

interface CommentPageProps {
  commentStore: CommentStore;
  authStore: AuthStore;
  match: Any;
  history: Any;
  location: Any;
}

const CommentPage = ({
  match,
  history,
  location,
  authStore,
  commentStore,
}: CommentPageProps) => {
  /* store */
  const fetchLike = authStore.fetchLike || (() => {})
  const fetchCommentList = commentStore.fetchCommentList || (() => {})
  const createComment = commentStore.createComment || (() => {})
  const updateComment = commentStore.updateComment || (() => {})
  const removeComment = commentStore.removeComment || (() => {})
  const updateCommentLike = commentStore.updateCommentLike || (() => {})
  const reportComment = commentStore.reportComment || (() => {})
  const updateLike = authStore.updateLike || (() => {})

  const currentUser = authStore.currentUser || null
  const likeInfo = authStore.likeInfo || null
  const contentTitle = commentStore.contentTitle || null
  const commentList = commentStore.commentList || null
  const commentListCount = commentStore.commentListCount || null
  /* store end */

  const [offset, setOffset] = useState(0)

  const _fetchCommentList = () => {
    setOffset(0)
    fetchCommentList({
      dataObjectId: match.params.dataObjectId,
      dataCollectionName: match.params.dataCollectionName,
      userId: currentUser && currentUser._id,
      limit: 10,
      offset: 0,
    })
  }

  useEffect(() => {
    if (authStore.currentUser) {
      fetchLike(match.params.dataObjectId, currentUser._id)
    }
  }, [authStore.currentUser])

  useEffect(() => {
    fetchCommentList({
      dataObjectId: match.params.dataObjectId,
      dataCollectionName: match.params.dataCollectionName,
      userId: currentUser && currentUser._id,
      limit: 10,
      offset,
    })
    if (offset < 1) {
      window.scrollTo(0, 0)
    }

    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser, match, offset])

  return (
    <CommentTemplate
      currentUser={currentUser}
      isLike={likeInfo && likeInfo.isLike}
      dataObjectId={match.params.dataObjectId}
      dataCollectionName={match.params.dataCollectionName}
      contentTitle={contentTitle}
      commentList={commentList}
      commentListCount={commentListCount}
      setOffset={setOffset}
      offset={offset}
      createComment={createComment}
      removeComment={removeComment}
      _fetchCommentList={_fetchCommentList}
      updateCommentLike={updateCommentLike}
      reportComment={reportComment}
      updateLike={updateLike}
      updateComment={updateComment}
    />
  )
}

export default inject('authStore', 'commentStore')(observer(CommentPage))
