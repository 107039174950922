import React from 'react'
import { BodyWrapper, Flex } from '@components/atoms'
import { plam_mbti_main_gif, plam_app_logo_img } from '@images/'
import styled from 'styled-components'
import { MbtiBtn } from '@components/molecules'

const MyIdolMainTemplate = ({ setStep }) => {
  return (
    <BodyWrapper
      style={{ padding: 0, minHeight: '100vh', backgroundColor: '#FFCC00' }}
    >
      <Flex
        type="column"
        style={
          {
            height: '100%',
            alignItems: 'center',
            backgroundColor: '#FFCC00',
          }
        }
      >
        <Flex style={{ marginTop: 100, width: 360, height: 202 }}>
          <TitleImage src={plam_mbti_main_gif} />
        </Flex>
        <RixText align="center">
          당신과 ‘찰떡’인 아이돌은 누구일까요?
          <br />
          저희가 찾아드릴게요!
        </RixText>
        <MbtiBtn
          text="Start!"
          style={{ marginTop: 48 }}
          onClick={() => setStep(1)}
        />
        <LogoImg
          src={plam_app_logo_img}
          style={{ marginTop: 131, marginBottom: 40 }}
        />
      </Flex>
    </BodyWrapper>
  )
}

export default MyIdolMainTemplate

const TitleImage = styled.img`
  width: 360px;
  height: 202px;
`

const RixText = styled.p`
  /* font-family: NotoSansCJKkr-Bold; */
  font-family: RixYeoljeongdoPR;
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

const LogoImg = styled.img`
  width: 58px;
  height: 16px;
`
