import React, { useState, useEffect } from 'react'
import { Flex, H4, H6 } from '@components/atoms'
import styled from 'styled-components'
import { PlamChartItem } from '@components/molecules'
import { move_btn_bottom_img } from '@images'
import { colors } from '@colors'

const ListContent = styled(Flex)`
  flex-direction: column;
  > div:first-child {
    padding-top: 0px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
  > div {
    padding: 8px 0;
  }
`

const PlamChart = ({ fetchPlamChart, plamChartList }) => {
  const [showMore, setShowMore] = useState(false) // 더보기 유무
  const [sortChart, setSortChart] = useState('daily') // 차트 일/주/월

  useEffect(() => {
    fetchPlamChart(sortChart)
  }, [sortChart])

  return (
    <Flex type="column">
      <Flex
        style={
          {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
          }
        }
      >
        <H4 type="Bold">PLAM CHART</H4>
        <Flex>
          <H6
            color={sortChart === 'daily' ? colors.black : colors.inActive}
            onClick={() => setSortChart('daily')}
            style={{ cursor: 'pointer' }}
          >
            일
          </H6>
          <H6 color="#d4d4d4" style={{ margin: '0 8px' }}>
            •
          </H6>
          <H6
            color={sortChart === 'weekly' ? colors.black : colors.inActive}
            onClick={() => setSortChart('weekly')}
            style={{ cursor: 'pointer' }}
          >
            주
          </H6>
          <H6 color="#d4d4d4" style={{ margin: '0 8px' }}>
            •
          </H6>
          <H6
            color={sortChart === 'monthly' ? colors.black : colors.inActive}
            onClick={() => setSortChart('monthly')}
            style={{ cursor: 'pointer' }}
          >
            월
          </H6>
        </Flex>
      </Flex>
      <ListContent>
        {/* 차트 1~5위 */}
        {
          plamChartList
          && plamChartList.map(
            (item, index) =>
              index < 6 && <PlamChartItem item={item} key={item._id} />,
          )
        }

        {/* 차트 6~100위 */}
        {
          showMore
          && plamChartList
          && plamChartList.map(
            (item, index) =>
              index > 5 && <PlamChartItem item={item} key={item._id} />,
          )
        }

        {
          !showMore && (
            <Flex
              onClick={() => setShowMore(!showMore)}
              style={
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '26px',
                  cursor: 'pointer',
                }
              }
            >
              <H6>더보기</H6>
              <img
                src={move_btn_bottom_img}
                style={{ width: '16px', height: '16px', marginLeft: '4px' }}
              />
            </Flex>
          )
        }
      </ListContent>
    </Flex>
  )
}

export default PlamChart
