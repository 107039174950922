import React, { useEffect, useState } from 'react'
import { Flex, H4 } from '@components/atoms'
import styled from 'styled-components'
import {
  LatestAlbumItem,
  RewardItem,
  RewardItemSkeleton,
} from '@components/molecules'
import { move_btn_img } from '@images/'
import { Observer, inject, observer } from 'mobx-react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  POST_MESSAGES,
  getIsWebApp,
  sendWTAPostMessage,
} from '@utils/postMessage'
import { useStore } from '@utils/hooks'
import AppDownloadPopup from '../Popup/AppDownloadPopup'

const ListBox = styled(Flex)`
  flex-direction: column;
`

const ListContent = styled(Slider)`
  width: calc(100% + 40px);
  margin-left: -20px;

  .slick-slide:first-child {
    padding-left: 20px;
  }

  .slick-slide:last-child {
    padding-right: 20px;
  }

  .slick-slide {
    padding: 0 10px;
  }
`

const ProgressingRewardList = ({
  progressingRewardList = [],
  setShowDownloadPopup = () => {},
}) => {
  const { handlerObj } = useStore()

  return (
    <>
      <ListBox style={{ marginBottom: '24px' }}>
        <Flex
          style={
            {
              justifyContent: 'left',
              alignItems: 'center',
              marginBottom: '16px',
              cursor: 'pointer',
              height: '29px',
            }
          }
          onClick={
            () => {
              const isWebApp = getIsWebApp({ navigator })
              if (isWebApp) {
                sendWTAPostMessage({
                  window,
                  navigator,
                  postMessageData: POST_MESSAGES.WTA_DATA.MOVE_REWARD_LIST,
                })
              }
              else {
                setShowDownloadPopup(true)
              }
            }
          }
        >
          <H4 type="Bold" align="left">
            진행 중인 리워드 음감회
          </H4>
          <img src={move_btn_img} style={{ width: '20px', height: '20px' }} />
        </Flex>
        <Observer>
          {
            () => {
              return (
                <ListContent
                  dots={false}
                  infinite={false}
                  draggable
                  slide={'a'}
                  // autoplay
                  // autoplaySpeed={2000}
                  // pauseOnHover
                  // speed={500}
                  slidesToShow={2}
                  slidesToScroll={2}
                  variableWidth
                  adaptiveHeight
                  rows={1}
                >
                  {
handlerObj?.['fetchRewardList']?.isLoading
  ? Array(3)
    .fill(1)
    .map((item, index) => (
      <RewardItemSkeleton key={`RewardSkel-${index}`} />
    ))
  : progressingRewardList.map((item, index) => {
    return (
      <RewardItem
        item={item}
        key={item._id}
        setShowDownloadPopup={setShowDownloadPopup}
      />
    )
  })
                  }

                  {/* {
            progressingRewardList.map((item, index) => {
              return (
                <RewardItem item={item} key={item._id} setShowDownloadPopup={setShowDownloadPopup} />
              )
            }

            )
          } */}
                </ListContent>
              )
            }
          }
        </Observer>
      </ListBox>
    </>
  )
}

export default ProgressingRewardList
