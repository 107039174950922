import { computed, observable } from 'mobx'
import { UserModel } from '.'

export default class CommentModel {
  /* Database fields */
  @observable _id

  @observable likeCount
  @observable reportCount
  @observable isHidden
  @observable text
  @observable writerId
  @observable dataCollectionName
  @observable dataArtistId
  @observable parendId
  @observable writerInfo
  @observable isLike
  @observable isMine
  @observable isAnonymous
  @observable reply
  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  /* Database fields end */

  @computed get isViewHidden() {
    return !this.isMine && this.isHidden
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.likeCount = props.likeCount
      this.reportCount = props.reportCount
      this.isHidden = props.isHidden
      this.text = props.text
      this.writerId = props.writerId
      this.dataCollectionName = props.dataCollectionName
      this.dataArtistId = props.dataArtistId
      this.parendId = props.parendId
      this.writerInfo = new UserModel(this.store, props.writerInfo)
      this.isLike = props.isLike
      this.isMine = props.isMine
      this.isAnonymous = props.isAnonymous
      this.reply =
        props.reply
        && props.reply
          .filter(elem => !!elem)
          .map(elem => new CommentModel(this.store, elem))
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
    }
  }
}
