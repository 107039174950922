import { observable } from 'mobx'

export default class FanSpotPointHistoryModel {
  /* Database fields */
  @observable _id

  @observable artistId
  @observable userId
  @observable point

  @observable articleId

  @observable commentId
  @observable commentUserId

  @observable likeId
  @observable likeUserId

  /**
   * type
      'ARTICLE_POST',
      'ARTICLE_ADMIN_DELETE',
      'ARTICLE_ADMIN_DELETE_ROLLBACK',
      'ARTICLE_COMMENT_POST',
      'ARTICLE_COMMENT_ADMIN_DELETE',
      'ARTICLE_COMMENT_ADMIN_DELETE_ROLLBACK',
      'ARTICLE_LIKE',
   */
  @observable type
  @observable createdAt
  @observable createdAtKST
  @observable createdYearKST
  @observable createdMonthKST
  @observable createdDayKST
  @observable createdHourKST
  @observable createdMinuteKST
  @observable createdSecondKST

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.artistId = props.artistId
      this.userId = props.userId
      this.point = props.point
      this.articleId = props.articleId
      this.commentId = props.commentId
      this.commentUserId = props.commentUserId
      this.likeId = props.likeId
      this.likeUserId = props.likeUserId
      this.type = props.type
      this.createdAt = props.createdAt
      this.createdAtKST = props.createdAtKST
      this.createdYearKST = props.createdYearKST
      this.createdMonthKST = props.createdMonthKST
      this.createdDayKST = props.createdDayKST
      this.createdHourKST = props.createdHourKST
      this.createdMinuteKST = props.createdMinuteKST
      this.createdSecondKST = props.createdSecondKST
    }
  }
}
