import React, { useState } from 'react'
import { Typography, Flex } from '@components/atoms'
import { SharedBtn, LikeBtn, Image } from '@components//molecules'
import { LinkCopiedPopup } from '@components/organisms'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { handleAlbumImgError } from '@utils/handler'
import { DOMAIN_URL } from '@consts/'
import { default_album_img } from '@images'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const AlbumCard = ({
  albumImg,
  albumTitle,
  releaseArtistList,
  variousArtists,
  genre,
  releaseDate,
  currentPath,
  likeInfo,
  updateLike,
  albumId,
  currentUser,
  setIsShowLoginPopup,
  subdomainInfo,
  customUrlInfo,
  setIsDetailCover,
}) => {
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const handleLike = () => {
    if (currentUser) {
      if (likeInfo.isLike) {
        updateLike('delete', currentUser._id, albumId, 'album')
      } else {
        updateLike('add', currentUser._id, albumId, 'album')
      }
    } else {
      setIsShowLoginPopup(true)
    }
  }

  return (
    // 앨범 카드
    <>
      <Flex
        style={{
          padding: '46px 0 10px 0',
          justifyContent: 'space-between',
          zIndex: 1,
        }}
      >
        <Flex>
          <Image
            src={albumImg ? albumImg : default_album_img}
            size={['116px', '116px']}
            style={{
              borderRadius: '6px',
            }}
            onError={handleAlbumImgError}
            onClick={() => setIsDetailCover(true)}
          />
          <Flex type="column" style={{ paddingLeft: '16px' }}>
            <Typography
              type="Medium"
              size="16px"
              color="#ffffff"
              style={ellipsisStyle}
            >
              {albumTitle}
            </Typography>
            <Typography
              type="Medium"
              size="12px"
              color="#ffffff"
              style={{ ...ellipsisStyle, ...{ margin: '2px 0 4px 0' } }}
            >
              {variousArtists
                ? 'Various Artists'
                : releaseArtistList &&
                  releaseArtistList.map((a, index) =>
                    a.isAuto ? (
                      <span key={a._id}>
                        {a.name}
                        {!(index === releaseArtistList.length - 1) && ',\u00A0'}
                      </span>
                    ) : (
                      <Link
                        style={{
                          color: '#ffffff',
                          fontFamily: 'NotoSansCJKkr-Bold',
                        }}
                        to={`/artist/${a.uniqueName}`}
                        key={a._id}
                      >
                        {a.name}
                        {!(index === releaseArtistList.length - 1) && ',\u00A0'}
                      </Link>
                    ),
                  )}
            </Typography>
            <Typography size="10px" color="#ffffff" style={ellipsisStyle}>
              {`${genre} * ${new Date(releaseDate).getFullYear()}년`}
            </Typography>
          </Flex>
        </Flex>
        <Flex
          type="column"
          style={{
            justifyContent: 'flex-end',
            gap: '12px',
          }}
        >
          <CopyToClipboard
            text={
              subdomainInfo &&
              subdomainInfo.subdomain &&
              subdomainInfo.isAccepted
                ? customUrlInfo && customUrlInfo.customUrl
                  ? `${subdomainInfo.subdomain}.${DOMAIN_URL}/${customUrlInfo &&
                      customUrlInfo.customUrl}`
                  : `${subdomainInfo.subdomain}.${DOMAIN_URL}/${customUrlInfo &&
                      customUrlInfo.autoUrl}`
                : currentPath
            }
            onCopy={() => linkCopy()}
          >
            <SharedBtn />
          </CopyToClipboard>
          <LikeBtn
            active={likeInfo && likeInfo.isLike}
            count={likeInfo && likeInfo.likeCount}
            isShowCount={false}
            onClick={() => handleLike()}
          />
        </Flex>
      </Flex>
      {isCopied && <LinkCopiedPopup />}
    </>
  )
}

export default AlbumCard
