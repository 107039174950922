import React from 'react'
import styled from 'styled-components'
import { like_on_img, like_off_img } from '@images/'
import { Flex, Caption1, H6 } from '@components/atoms'
import { converseUnitEng } from '@utils/format'

const LikeImg = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const LikeBtn = ({ onClick, active, count, isShowCount = true }) => {
  return (
    <Flex type="column" style={{ alignItems: 'center' }}>
      <LikeImg
        onClick={onClick}
        src={active ? like_on_img : like_off_img}
        alt="like_img"
      />
      {isShowCount && (
        <H6 type="Bold" color="#ffffff">
          {!isNaN(Number(count)) && converseUnitEng(Number(count))}
        </H6>
      )}
    </Flex>
  )
}

export default LikeBtn
