import React, { useEffect, useState } from 'react'
import { AlbumTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { useLastLocation } from 'react-router-last-location'

interface AlbumPageProps {
  authStore: AuthStore;
  albumStore: AlbumStore;
  recordStore: RecordStore;
  commentStore: CommentStore;
  match: Any;
  history: Any;
  location: Any;
}

const AlbumPage = ({
  match,
  history,
  location,
  albumStore,
  recordStore,
  commentStore,
  authStore,
}: AlbumPageProps) => {
  /* store */
  const currentUser = authStore.currentUser || null
  const logout = authStore.logout || (() => {})

  const likeInfo = authStore.likeInfo || null
  const fetchLike = authStore.fetchLike || (() => {})
  const updateLike = authStore.updateLike || (() => {})

  const albumDetail = albumStore.albumDetail || null
  const enterRecord = recordStore.enterRecord || (() => {})

  const fetchCommentList = commentStore.fetchCommentList || (() => {})
  const commentList = commentStore.commentList || null
  const commentListCount = commentStore.commentListCount || null

  const lastLocation = useLastLocation()

  /* store end */

  // put Record
  const updateRecord = async (type, url) => {
    const params = {
      _id: sessionStorage.getItem('recordId'),
      clickType: type,
      clickUrl: url,
    }

    await recordStore.updateRecord(params)
  }

  useEffect(() => {
    // fetch Album
    albumStore.fetchAlbumDetail(match.params.id)

    // post Record
    const enterPage = {
      referrer: document.referrer,
      domain: document.domain,
      URL: document.URL,
      lastLocation,
      history,
      performance,
      type: 'album',
      pathId: match.params.id,
    }
    enterRecord(enterPage)

    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [match, albumStore])

  // fetch Album Comment
  useEffect(() => {
    if (albumDetail) {
      fetchCommentList({
        dataObjectId: albumDetail._id,
        dataCollectionName: 'albums',
        offset: 0,
      })
    }
  }, [albumDetail])

  return (
    <AlbumTemplate
      album={albumDetail}
      currentPath={document.domain + match.url}
      updateRecord={updateRecord}
      currentUser={currentUser}
      logout={logout}
      fetchLike={fetchLike}
      likeInfo={likeInfo}
      updateLike={updateLike}
      commentList={commentList}
      commentListCount={commentListCount}
    />
  )
}

export default inject(
  'recordStore',
  'albumStore',
  'authStore',
  'commentStore',
)(observer(AlbumPage))
