import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, BodyWrapper } from '@components/atoms'
import { ArtistTabs, DetailCoverImage } from '@components/molecules'
import {
  AlbumCard,
  StreamingList,
  TrackList,
  AlbumInfo,
  BurgerMenu,
  LoginPopup,
  CommentInput,
} from '@components/organisms'
import { Helmet } from 'react-helmet'

import { default_album_img } from '@images'

const BackWrapper = styled.div`
  background-color: rgb(36, 36, 36);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 202px;
`

const BackColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 202px;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px -110px;
  opacity: 0.2;
`

const AlbumTemplate = ({
  album,
  currentPath,
  updateRecord,
  currentUser,
  logout,
  fetchLike,
  likeInfo,
  updateLike,
  commentList,
  commentListCount,
}) => {
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [isDetailCover, setIsDetailCover] = useState(false) // 상세 이미지

  useEffect(() => {
    if (album && !currentUser) {
      fetchLike(album._id)
    } else if (album && currentUser) {
      fetchLike(album._id, currentUser._id)
    } else {
    }
  }, [album, currentUser])

  return (
    <>
      <Helmet>
        <title>{album && `${album.title} | 플램`}</title>
        <meta
          name="description"
          content={`${album &&
            album.releaseArtistList &&
            album.releaseArtistList[0] &&
            album.releaseArtistList[0].name}의 음악에 한걸음 더, 플램`}
        />
      </Helmet>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pageWrapId={'album_template'}
        outerContainerId={'App'}
        pageTitle={album && album.title}
      />
      <BodyWrapper
        id="album_template"
        style={{ minHeight: '100vh', paddingBottom: 90 }}
      >
        {isShowLoginPopup && <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />}
        <BackWrapper>
          <BackColor
            background={(album && album.image128Uri) || default_album_img}
          />
        </BackWrapper>
        <Flex type="column" style={{ zIndex: '2' }}>
          <AlbumCard
            albumImg={(album && album.image128Uri) || default_album_img}
            albumTitle={album && album.title}
            albumId={album && album._id}
            releaseArtistList={album && album.releaseArtistList}
            variousArtists={album && album.variousArtists}
            genre={album && album.mainGenre}
            releaseDate={album && album.releasedAt}
            currentPath={currentPath}
            currentUser={currentUser}
            setIsShowLoginPopup={setIsShowLoginPopup}
            likeInfo={likeInfo}
            updateLike={updateLike}
            subdomainInfo={album && album.subdomainInfo}
            customUrlInfo={album && album.customUrlInfo}
            setIsDetailCover={setIsDetailCover}
          />
          <StreamingList
            melon={album && album.melonUrl}
            genie={album && album.genieUrl}
            bugs={album && album.bugsUrl}
            flo={album && album.floUrl}
            vibe={album && album.vibeUrl}
            youtubeMusic={album && album.youtubeMusicUrl}
            appleMusic={album && album.appleMusicUrl}
            spotify={album && album.spotifyUrl}
            amazonMusic={album && album.amazonMusicUrl}
            etc={album && album.etcUrl}
            tidalUrl={album?.tidalUrl}
            deezerUrl={album?.deezerUrl}
            jooxUrl={album?.jooxUrl}
            mymusicUrl={album?.mymusicUrl}
            kkboxUrl={album?.kkboxUrl}
            linejpUrl={album?.linejpUrl}
            linetwUrl={album?.linetwUrl}
            yandexUrl={album?.yandexUrl}
            nctUrl={album?.nctUrl}
            zingUrl={album?.zingUrl}
            anghmiUrl={album?.anghmiUrl}
            updateRecord={updateRecord}
          />
          <ArtistTabs
            menu={['수록곡', '상세정보']}
            contents={[
              <TrackList list={album && album.trackList} />,
              <AlbumInfo
                albumType={album && album.albumType}
                albumGenre={album && album.mainGenre}
                releaseCompanyName={album && album.releaseCompanyName}
                releaseDate={album && album.releasedAt}
                planningCompanyName={album && album.planningCompanyName}
                albumText={album && album.description}
              />,
            ]}
          />
        </Flex>
        {isDetailCover && (
          <DetailCoverImage
            setIsDetailCover={setIsDetailCover}
            imgSrc={album.imageUri}
          />
        )}
        <CommentInput
          isLike={likeInfo && likeInfo.isLike}
          dataObjectId={album && album._id}
          dataCollectionName="albums"
          commentList={commentList}
          commentListCount={commentListCount}
        />
      </BodyWrapper>
    </>
  )
}

export default AlbumTemplate
