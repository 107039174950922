import { BodyWrapper, Caption1, Caption2, Flex, H5 } from '@components/atoms'
import React from 'react'
import styled from 'styled-components'
import { arrow_left_img, move_down_gray_img } from '@images/index'
import { useHistory, useParams } from 'react-router-dom'
import { convertToPricingComma } from '@utils/format'
import {
  TrackGenieBugsTableChart,
  TrackMelonTableChart,
  TrackYoutubeTableChart,
} from '@components/organisms'

const TrackSnsListenDetailTemplate = () => {
  const history = useHistory()
  const params = useParams()

  const snsType = params?.snsType

  return (
    <>
      <Header>
        <img
          src={arrow_left_img}
          alt="back button"
          width={24}
          height={24}
          onClick={() => history.goBack()}
        />
        <H5 align="left" style={{ textTransform: 'capitalize' }}>
          {snsType}
        </H5>
      </Header>
      <BodyWrapper
        style={{ paddingTop: 56, paddingBottom: 64, minHeight: '100vh' }}
      >
        {snsType === 'melon' ? (
          <TrackMelonTableChart isFold={false} />
        ) : snsType === 'youtube' ? (
          <TrackYoutubeTableChart isFold={false} />
        ) : (
          <TrackGenieBugsTableChart isFold={false} />
        )}
      </BodyWrapper>
    </>
  )
}

export default TrackSnsListenDetailTemplate

const Header = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  min-width: 320px;
  max-width: 420px;
  height: 56px;
  z-index: 2;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  gap: 8px;
  border-bottom: 1px solid #f4f4f4;

  > h5 {
    flex: 1;
  }

  img {
    cursor: pointer;
  }
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 50px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 62px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #66bc6a;

  margin-top: 2px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #ea4653;

  margin-top: 2px;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 10px;
  border-radius: 2px;
  background-color: #f8f8f8;
  border: none;
`

const CountCard = ({ text, increaseValue }) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        height: 48,
        borderRadius: '4px',
        backgroundColor: '#f8f8f8',
        padding: 12,
        boxSizing: 'border-box',
      }}
    >
      <Caption1 type="Bold">{text}</Caption1>
      <Flex align="center" style={{ gap: 4 }}>
        {increaseValue > 0 ? <Up /> : increaseValue < 0 ? <Down /> : <></>}
        <Caption2 color="#949494">
          {increaseValue === 0
            ? '-'
            : convertToPricingComma(Math.abs(increaseValue))}
        </Caption2>
      </Flex>
    </Flex>
  )
}
