import { Network } from '.'

export default class TrendNetwork {
  constructor(network: Network) {
    this.network = network
  }

  /**
   *
   * @param {} artistObjectId  @string
   * @param {*} params @__periodDays
   * @returns
   */
  async getTrendArtistAPI(artistObjectId, params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/trend/artist/${artistObjectId}`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('TrendNetwork getTrendArtistAPI error', error)
    }
  }

  /**
   *
   * @param {} artistObjectId  @string
   * @param {*} params @__periodDays
   * @returns
   */
  async getTrendArtistTrackAPI(artistObjectId, params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/trend/artist/${artistObjectId}/track`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('TrendNetwork getTrendArtistTrackAPI error', error)
    }
  }

  /**
   *
   * @param {} artistObjectId  @string
   * @param {*} params @__periodDays
   * @returns
   */
  async getTrendArtistTrackDetailAPI(artistObjectId, trackObjectId, params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/trend/artist/${artistObjectId}/track/${trackObjectId}`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('TrendNetwork getTrendArtistTrackDetailAPI error', error)
    }
  }
}
