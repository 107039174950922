import { Network } from '.'

export default class RecordNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getRecord(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/record`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('RecordNetwork getRecord error', error)
    }
  }

  async postRecord(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/record',
        'post',
        params,
      )
      const record = data && data
      return record || null
    } catch (error) {
      console.warn('RecordNetwork postRecord error', error)
    }
  }

  async putRecord(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/record',
        'put',
        params,
      )
      const record = data && data
      return record || null
    } catch (error) {
      console.warn('RecordNetwork putRecord error', error)
    }
  }
}
