import React, { useEffect, useState } from 'react'
import { BodyWrapper, Flex } from '@components/atoms'
import { plam_mbti_loading_gif } from '@images/'
import styled, { keyframes } from 'styled-components'
import { MbtiBtn } from '@components/molecules'
import { useHistory } from 'react-router-dom'

const MyIdolLoadingTemplate = ({ mbti }) => {
  const history = useHistory()
  const [showGoResult, setShowGoResult] = useState(false)

  useEffect(() => {
    const resultInterval = setInterval(() => {
      setShowGoResult(true)
    }, 3000)

    return () => {
      clearInterval(resultInterval)
    }
  }, [])

  return (
    <BodyWrapper style={{ padding: 0, height: '100vh' }}>
      <Flex
        type="column"
        style={
          {
            height: '100%',
            alignItems: 'center',
            backgroundColor: '#FFCC00',
            padding: '0 24px',
          }
        }
      >
        <Flex style={{ margin: '94px 0 48px 0', width: '100%' }}>
          <LoadingGif src={plam_mbti_loading_gif} />
        </Flex>
        <GGText size={20}>‘누가 만들었을까?’</GGText>

        <GGText type="Medium" size={12} style={{ margin: '24px 0 16px 0' }}>
          ‘찰떡 아이돌 테스트’는 플램 앱에서 제작된 테스트에요.
        </GGText>
        <GGText type="Medium" size={12}>
          플램은 소장하고 있는 앨범을 앱에서 인증만 하면
          <br />
          누구나 쉽게 이용할 수 있는 ‘인증형 커뮤니티’ 랍니다.
        </GGText>
        <GGText type="Medium" size={12} style={{ margin: '16px 0 56px 0' }}>
          평소에 내가 관심있던 아티스트가 있었다면
          <br />
          책장에 꽂아두기만 했던 앨범을 꺼내보는건 어떠세요?
        </GGText>
        {
          showGoResult && (
            <AnimeBox>
              <MbtiBtn
                text="결과 보러가기"
                onClick={
                  () => {
                    window.gtag('event', 'play_mbti_test', {
                      mbti_type: mbti,
                    })
                    history.push(`/myidol/${mbti}`)
                  }
                }
                style={{ width: 210 }}
              />
            </AnimeBox>
          )
        }
      </Flex>
    </BodyWrapper>
  )
}

export default MyIdolLoadingTemplate

const LoadingGif = styled.img`
  width: 100%;
  height: 100%;
`

const GGText = styled.p`
  font-family: ${({ type }) =>
    type ? `GongGothicOTF${type}` : 'GongGothicOTFBold'};
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};

  word-break: break-all;
  line-height: 1.83;
  margin: 0;
  padding: 0;
`

const boxFade = keyframes`
  0% { opacity: 0;}
  40% { opacity: 1;}
  60% { opacity: 0;}
  80% { opacity: 1;}
  100% { opacity: 1;}
`

const AnimeBox = styled.div`
  animation-name: ${boxFade};
  animation-duration: 1s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
`
