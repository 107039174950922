import { observable } from 'mobx'

export default class RecordModel {
  /* Database fields */
  @observable _id
  @observable origin
  @observable path
  @observable referer
  @observable fromAt
  @observable plamUrl
  @observable pageType
  @observable pageId
  @observable clickType
  @observable clickUrl
  @observable artistId
  @observable toAt
  @observable createdAt
  @observable updatedAt

  /* Database fields end */

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.origin = props.origin
      this.path = props.path
      this.referer = props.referer
      this.fromAt = props.fromAt
      this.plamUrl = props.plamUrl
      this.pageType = props.pageType
      this.pageId = props.pageId
      this.clickType = props.clickType
      this.clickUrl = props.clickUrl
      this.artistId = props.artistId
      this.toAt = props.toAt
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
    }
  }
}
