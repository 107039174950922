import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamArtistTemplate } from '@components/templates'
import qs from 'qs'
import { DOMAIN_URL } from '@consts/'
import FanspotScoreTemplate from '@components/templates/FanspotScoreTemplate'

interface FanspotScorePageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  match: Any;
  history: Any;
  location: Any;
}

const FanspotScorePage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
  inplamStore,
}: FanspotScorePageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  /* store */

  const fetchArtistFanSpotPointInfo =
    inplamStore.fetchArtistFanSpotPointInfo || (() => {})
  const fetchArtistFanSpotPointRank =
    inplamStore.fetchArtistFanSpotPointRank || (() => {})

  const currentUser = authStore.currentUser || null
  const likeInfo = authStore.likeInfo || null
  const artistInfo = articleStore.artistInfo || null
  const artistArticleList = articleStore.artistArticleList || null
  const popularArtistArticleList = articleStore.popularArtistArticleList || null
  const artistArticleListCount = articleStore.artistArticleListCount || null
  /* store end */

  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)
  const [sortBy, setSortBy] = useState(
    query.sortBy ? query.sortBy : 'createdAt',
  )
  const [isFromArtist, setIsFromArtist] = useState(null) // FromArtist

  const [artistFanSpotPointInfo, setArtistFanSpotPointInfo] = useState(null)
  const [artistFanSpotPointRankList, setArtistFanSpotPointRankList] = useState(
    null,
  )

  const toggleFromArtist = () => {
    if (isFromArtist === 'artist') {
      setIsFromArtist(null)
    }
    else {
      setIsFromArtist('artist')
    }
    // setOffset(0)
    handlePagination(0)
  }

  const handlePagination = _offset => {
    history.push(
      `/fanspot/${match.params.uniqueName}?offset=${_offset}&sortBy=${sortBy}`,
    )
  }

  const handleChangeSortBy = _sortBy => {
    history.push(
      `/fanspot/${match.params.uniqueName}?offset=${offset}&sortBy=${_sortBy}`,
    )
  }

  useEffect(() => {
    if (authStore.currentUser && articleStore.artistInfo) {
      authStore.fetchLike(
        articleStore.artistInfo._id,
        authStore.currentUser._id,
      )
    }
  }, [articleStore.artistInfo, authStore.currentUser])

  useEffect(() => {
    if (query.offset) setOffset(Number(query.offset))
    if (query.sortBy) setSortBy(query.sortBy)
  }, [query.offset, query.sortBy])

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchPointData = async () => {
      const result = await fetchArtistFanSpotPointInfo({
        uniqueName: match.params.uniqueName,
      })
      if (result) {
        setArtistFanSpotPointInfo(result)
      }

      const rankResult = await fetchArtistFanSpotPointRank({
        uniqueName: match.params.uniqueName,
      })

      if (rankResult?.rankList) {
        setArtistFanSpotPointRankList(rankResult?.rankList)
      }
    }

    fetchPointData()
  }, [match.params.uniqueName])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <FanspotScoreTemplate
      isLike={likeInfo && likeInfo.isLike}
      uniqueName={match.params.uniqueName}
      artistInfo={artistInfo}
      artistArticleList={artistArticleList}
      popularArtistArticleList={popularArtistArticleList}
      artistArticleListCount={artistArticleListCount}
      offset={offset}
      handlePagination={handlePagination}
      sortBy={sortBy}
      handleChangeSortBy={handleChangeSortBy}
      isFromArtist={isFromArtist}
      toggleFromArtist={toggleFromArtist}
      currentPath={DOMAIN_URL + match.url}
      artistFanSpotPointInfo={artistFanSpotPointInfo}
      artistFanSpotPointRankList={artistFanSpotPointRankList}
    />
  )
}

export default inject(
  'authStore',
  'articleStore',
  'inplamStore',
)(observer(FanspotScorePage))
