import { observable } from 'mobx'
import { STORAGE_URL } from '@consts'
import { ArtistModel, TrackModel } from '.'

export default class AlbumModel {
  /* Database fields */
  @observable _id

  @observable UPC

  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable title

  @observable releaseArtistIdList

  @observable description
  @observable albumType
  @observable releasedAt
  @observable mainGenre
  @observable subGenre
  @observable releaseCompanyId
  @observable planningCompanyId

  @observable spotifyUrl
  @observable youtubeMusicUrl
  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl
  @observable vibeUrl
  @observable floUrl
  @observable appleMusicUrl
  @observable amazonMusicUrl
  @observable tidalUrl
  @observable deezerUrl
  @observable jooxUrl
  @observable mymusicUrl
  @observable kkboxUrl
  @observable linejpUrl
  @observable linetwUrl
  @observable yandexUrl
  @observable nctUrl
  @observable zingUrl
  @observable anghmiUrl

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable artistId
  @observable userId
  @observable likeCount
  @observable variousArtists
  /* Database fields end */

  @observable customUrlInfo
  @observable subdomainInfo

  @observable _releaseArtistList = []
  set releaseArtistList(value) {
    if (Array.isArray(value)) {
      this._releaseArtistList = value.sort((a, b) =>
        a['_id'] && this.artistId && a['_id'] === this.artistId ? -1 : 0,
      )
    } else {
      this._releaseArtistList = value
    }
  }

  @observable releaseCompanyList
  @observable planningCompanyList

  get releaseArtistList() {
    return this._releaseArtistList
  }
  @observable trackList = []

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }
  get image512Uri() {
    return `${STORAGE_URL}${this.image512Path || this.imageOriginalPath}`
  }
  get image128Uri() {
    return `${STORAGE_URL}${this.image128Path || this.imageOriginalPath}`
  }
  get image64Uri() {
    return `${STORAGE_URL}${this.image64Path || this.imageOriginalPath}`
  }

  get releaseArtistName() {
    if (this.variousArtists) {
      return 'Various Artists'
    }
    if (Array.isArray(this.releaseArtistList)) {
      return this.releaseArtistList.map(artist => artist['name']).join(', ')
    }
    return ''
  }
  get releaseCompanyName() {
    if (Array.isArray(this.releaseCompanyList)) {
      return this.releaseCompanyList.map(company => company['name']).join(', ')
    }
    return ''
  }
  get planningCompanyName() {
    if (Array.isArray(this.planningCompanyList)) {
      return this.planningCompanyList.map(company => company['name']).join(', ')
    }
    return ''
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.UPC = props.UPC

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      this.title = props.title

      this.releaseArtistIdList = props.releaseArtistIdList

      this.description = props.description
      this.albumType = props.albumType
      this.releasedAt = props.releasedAt
      this.mainGenre = props.mainGenre
      this.subGenre = props.subGenre
      this.releaseCompanyId = props.releaseCompanyId
      this.planningCompanyId = props.planningCompanyId

      this.spotifyUrl = props.spotifyUrl
      this.youtubeMusicUrl = props.youtubeMusicUrl
      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
      this.vibeUrl = props.vibeUrl
      this.floUrl = props.floUrl
      this.appleMusicUrl = props.appleMusicUrl
      this.amazonMusicUrl = props.amazonMusicUrl
      this.tidalUrl = props.tidalUrl
      this.deezerUrl = props.deezerUrl
      this.jooxUrl = props.jooxUrl
      this.mymusicUrl = props.mymusicUrl
      this.kkboxUrl = props.kkboxUrl
      this.linejpUrl = props.linejpUrl
      this.linetwUrl = props.linetwUrl
      this.yandexUrl = props.yandexUrl
      this.nctUrl = props.nctUrl
      this.zingUrl = props.zingUrl
      this.anghmiUrl = props.anghmiUrl

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.artistId = props.artistId
      this.userId = props.userId
      this.likeCount = props.likeCount
      this.variousArtists = props.variousArtists

      this.customUrlInfo = props.customUrlInfo
      this.subdomainInfo = props.subdomainInfo

      this.releaseArtistList =
        (Array.isArray(props.releaseArtistList) &&
          props.releaseArtistList.map(elem => new ArtistModel(stores, elem))) ||
        []

      this.releaseCompanyList = props.releaseCompanyList
      this.planningCompanyList = props.planningCompanyList

      this.trackList =
        (Array.isArray(props.trackList) &&
          props.trackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(stores, elem))) ||
        []
    }
  }
}
