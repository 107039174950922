export const mbtiData = {
  isfj: {
    rate: 8, // 상위 비율
    best_partner: ['esfp', 'estp'], // 잘맞는 궁합
    description: [
      '공감 능력이 뛰어난 당신! 상대방의 이야기를 잘 들어주지만, 내 마음속 깊은 이야기는 잘 하지 않아요.',
      '남에게는 프리하지만 내 자신에게는 확실하게 엄격하고 싶어요.',
      '끈기있고 성실하며, 돈, 시간, 감정 등의 쓸모없는 낭비를 싫어해요.',
      '남의 주장이 강한 경우 그냥 그 주장을 따르는 편 이에요.',
      '낯선 것은 불편해요. 적응할 시간이 어느정도 필요해요.',
    ],
    member: [
      // 유형 별 연예인 3명
      {
        name: '‘트와이스’의 ‘다현’', // 연예인 이름
        uniqueName: 'dahyun_twice',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8klul1gs8_다현.png', // 연예인 이미지 경로
      },
      {
        name: '신비(여자친구)', // 연예인 이름
        uniqueName: 'sinb',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8kl14c3fo_858239_20201109141444_500.jpg', // 연예인 이미지 경로
      },
      {
        name: '정한(세븐틴)', // 연예인 이름
        uniqueName: 'junghan',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klal86pp_874338_20201013113041_500.jpg', // 연예인 이미지 경로
      },
    ],
  },
  infp: {
    rate: 3, // 상위 비율
    best_partner: ['enfj', 'entj'], // 잘맞는 궁합
    description: [
      '감정의 기복이 심한 경우가 많아요.',
      '공상적인 성격 때문에 내가 하고 있는 일에 불만족을 느낄 확률이 커요.',
      '밀당에 매우 약해요. 상대방이 조금만 밀어내도 마음의 문을 닫을 수 있어요.',
      '평소에 몽상가라는 말을 자주 들어요.',
      '자기 자신의 사적 영역을 침해받는 건 정말 싫어요.',
      '가끔 친구들이랑 노는 건 재밌고 좋아요. 그치만 내 개인 시간도 꼭 필요해요. 집 최고.',
    ],
    member: [
      {
        name: '니엘(틴탑)',
        uniqueName: 'niel',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klaikhu3_니엘.jpg',
      },
      {
        name: '정국(방탄소년단)',
        uniqueName: 'jungkook',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klait643_정국.jpg',
      },
      {
        name: '문빈(아스트로)',
        uniqueName: 'moonbin',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klaix85d_946270_20200914154839_500.jpg',
      },
    ],
  },
  enfp: {
    rate: 4,
    best_partner: ['intj', 'infj'],
    description: [
      '사람을 기쁘게 하는 능력이 있어요.',
      '새로운 사람 만나는 것을 좋아해요.',
      '평소에 화를 잘 안내요. 대신 잘 토라지는 편인데, 달래주면 또 금방 풀려요.',
      '무언가에 쉽게 열중했다가 쉽게 그만두는편이에요.',
      '항상 수다스럽고 밝은 성격으로 여겨지지만 진지해야 할 상황에는 극도로 진지해져요.',
      '감수성이 풍부하고 정이 많아요.',
    ],
    member: [
      {
        name: '로제(블랙핑크)',
        uniqueName: 'rose',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klajat00_995171_20201002120824_500.jpg',
      },
      {
        name: '뷔(방탄소년단)',
        uniqueName: 'v',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8kfc44t0a_80180888.jpg',
      },
      {
        name: '화사(마마무)',
        uniqueName: 'hwasa',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9kl3shezc_756531_20201103144434_500.jpg',
      },
    ],
  },
  infj: {
    rate: 2,
    best_partner: ['enfp', 'entp'],
    description: [
      '계획적인 것을 좋아해서 항상 계획을 세워요.',
      '눈치가 빨라서 거짓말, 가식을 잘 간파하지만 대부분 모르는 척 해요.',
      '친해지고 나면 첫인상과 다른 경우가 많아요.',
      '인내심이 많고 배려가 습관화 되어있어요.',
      '사람이나 일에 있어서 호불호가 분명해요.',
      '분쟁과 다툼은 싫어서 피하고싶어요.',
    ],
    member: [
      {
        name: '로운(SF9)',
        uniqueName: 'rowoon_sf9',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klrs2sqj_1202375_20200630165446_500.jpg',
      },
      {
        name: '카이(엑소)',
        uniqueName: 'kai',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8kis6kqh5_80124632.jpg',
      },
      {
        name: '최강창민(동방신기)',
        uniqueName: 'changmin',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8klak2vkv_최강.jpg',
      },
    ],
  },
  enfj: {
    rate: 1,
    best_partner: ['infp', 'isfp'],
    description: [
      '상처를 매우 잘 받고, 오랜 시간 가슴 아파해요.',
      '걱정이 매우 많으나 티를 잘 내지 않아요.',
      '사람을 좋아하고 남에게 잘 맞춰줘요.',
      '남의 말을 의심 없이 잘 믿는 편이에요. 보이스피싱 조심해야해요.',
      '인간 리트리버라 불릴 만큼 사람이 너무 좋아요.',
      '모든 사람에게 사랑받고 싶어요.',
    ],
    member: [
      {
        name: '강다니엘(워너원)',
        uniqueName: 'kangdaniel',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9kkhko0l3_1865969_20200804141431_500.jpg',
      },
      {
        name: '유노윤호(동방신기)',
        uniqueName: 'uknow',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhp2qkkbww225_52613_20210111105404_500.jpg',
      },
      {
        name: '조슈아(세븐틴)',
        uniqueName: 'joshua',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klakdzkl_874339_20201013112950_500.jpg',
      },
    ],
  },
  intj: {
    rate: 4,
    best_partner: ['enfp', 'entp'],
    description: [
      '일관성 있게 정돈된 체계를 세우고 실행해요.',
      '나무보다 숲을 먼저 보는 관점을 가지고 있어요.',
      '어떤 것이든 신중히 분석하고 숨은 이면의 의미를 생각하려는 경향이 있어요.',
      '예외 상황이 많은 체계를 싫어해요.',
      '논리적이고 객관적인 성격이에요.',
      '아무렇게나 나열된 것의 포함 관계를 살피고, 자신만의 목차를 만들어 정리하려고 해요.',
    ],
    member: [
      {
        name: '류진(ITZY)',
        uniqueName: 'ryujin_itzy',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbw600x_2622594_20200805171600_500.jpg',
      },
      {
        name: '정수빈(빅톤)',
        uniqueName: 'jeongsubin_victon',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbwdc9d_1229238_20210111142120_500.jpg',
      },
      {
        name: '에릭(신화)',
        uniqueName: 'eric_shinhwa',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbwjje3_1739_500.jpg',
      },
    ],
  },
  entj: {
    rate: 2,
    best_partner: ['infp', 'intp'],
    description: [
      '호기심이 많고 지적 욕구가 강해요.',
      '감정 표현이 솔직해서 타인이 상처 받을 수도 있어요.',
      '일상적인 반복되는 일은 싫어요.',
      '눈물을 잘 흘리지 않아요.',
      '조직적, 체계적, 계획적이에요.',
      '일반적으로 혼자 있는 것을 싫어해요.',
    ],
    member: [
      {
        name: '주학년(더보이즈)',
        uniqueName: 'zoohaknyun',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbu1lq7_2020036_20200921161855_500.jpg',
      },
      {
        name: '키(샤이니)',
        uniqueName: 'key_shinee',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbwo8cw_614026_20210215111524_500.jpg',
      },
      {
        name: '희진(이달의소녀)',
        uniqueName: 'heejin_loona',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbx6icj_1183761_20201019110822_500.jpg',
      },
    ],
  },
  intp: {
    rate: 3,
    best_partner: ['entj', 'estj'],
    description: [
      '이런 유형의 테스트를 통한 자아 분석을 좋아해요.',
      '사람들에 대한 관심이 적어요.',
      '반항심이 강해요. 특히 비논리적이고 불합리한 것에 강하게 반항하는 경향이 있어요.',
      '한 번 내뱉거나, 들은 말을 또 말하거나, 듣는 걸 싫어해요.',
      '마음에 없는 소리를 잘 못해요.',
      '정서 표현이 별로 없어 친해지기 전에는 잘 이해하기 어려워요.',
    ],
    member: [
      {
        name: '임현식(BTOB)',
        uniqueName: 'hyunsik_btob',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbxg7s6_672289_500.jpg',
      },
      {
        name: '슈가(방탄소년단)',
        uniqueName: 'suga',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klt70kul_슈가쿤.png',
      },
      {
        name: '정은지(에이핑크)',
        uniqueName: 'jeongeunji',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klt782pp_ㅏ.ㅣㅑㅣㅣㅣ.png',
      },
    ],
  },
  entp: {
    rate: 2,
    best_partner: ['infj', 'intj'],
    description: [
      '남의 사생활 등에는 관심이 없고, 그 사람이 건네는 대화의 주제에 더 관심있어요.',
      '보통은 겉과 속이 같은, 가식이 적은 사람이에요.',
      '개인주의 성향이 강해서 주변에선 이기적이라고 느낄 수 있어요.',
      '한 시간이면 할 일을 일주일에 걸쳐서 하고, 일주일 걸릴 일은 한 시간만에 해내요.',
      '많은 관심사를 가지고 있지만, 그것들에 금방 싫증이 나버려요.',
      '자기 사람이 아닌 사람의 일에 매우 무관심한 편이에요.',
    ],
    member: [
      {
        name: '김세정(ioi)',
        uniqueName: 'sejeong',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klbymrsd_80259078.jpg',
      },
      {
        name: '산들(B1A4)',
        uniqueName: 'sandeul',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9kkuij84q_672392_20201109164745_500.jpg',
      },
      {
        name: '육성재(비투비)',
        uniqueName: '6sungjae',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8kles4x8m_672296_20200221180229_500.jpg',
      },
    ],
  },
  isfp: {
    rate: 7,
    best_partner: ['esfj', 'estj'],
    description: [
      '일반적인 이미지는 조용하고 착한 사람이에요.',
      '딱딱하고 사무적인 사람은 정말 싫어요.',
      '싫은 소리를 잘 못해요. 속으로 많이 삭혀요.',
      '다른 사람의 부탁을 거절하기 어려워해요.',
      '일을 미룰 때 까지 미루지만 제한시간 안에는 다 끝내요.',
      '헬스장을 등록해 놓고 얼마 지나지않아 유령 회원이 돼요.',
    ],
    member: [
      {
        name: '김진환(IKON)',
        uniqueName: 'kimjinhwan',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klt5k9te_742767_20200206175855_500.jpg',
      },
      {
        name: '미나(트와이스)',
        uniqueName: 'mina_twice',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8klt8eb99_mina.png',
      },
      {
        name: '버논(세븐틴)',
        uniqueName: 'vernon',
        imagePath:
          'https://storage.plam.kr/upload/image/original/7kfdghow6_038.jpg',
      },
    ],
  },
  esfp: {
    rate: 5,
    best_partner: ['isfj', 'istj'],
    description: [
      '내 자신의 이야기를 상대방을 가리지 않고 아무에게나 잘 털어놔요.',
      '청소나 빨래를 제시간에 하지 않고 몰아서 해요.',
      '내일은 내일의 태양이 뜬다!',
      '개성적인 매력이 있는 성격이에요.',
      '집에 있으면 무기력한 느낌이 들어 쉬는 날은 거의 외출을 해요.',
      '발등에 불이 떨어져야 행동에 옮기지만 중요한 사항에 대해서는 즉시 처리하는 편이에요.',
    ],
    member: [
      {
        name: '주이(모모랜드)',
        uniqueName: 'jooie_momo',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbunfec_1229548_20201117135235_500.jpg',
      },
      {
        name: '큐(더보이즈)',
        uniqueName: 'q_theboyz',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbu7lrc_2020044_20200921162422_500.jpg',
      },
      {
        name: '재현(NCT)',
        uniqueName: 'jaehyun_nct',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbuxfsb_830329_20201119145015_500.jpg',
      },
    ],
  },
  istp: {
    rate: 9,
    best_partner: ['esfj', 'estj'],
    description: [
      '내가 하는 일에 간섭 받는 것 싫어요.',
      '마음에 없는 소리를 잘 못해요.',
      '한 번 싫어지거나 내친 대상은 다시는 쳐다보지 않아요.',
      '평소에 내 사람들 외에는 남에게 관심 없어요.',
      '친해지면 따뜻한 반전 매력이 있는 성격이에요.',
      '연애할 때 사생활 존중은 필수에요.',
    ],
    member: [
      {
        name: '나연(트와이스)',
        uniqueName: 'nayeon_twice',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klt721ut_나연쿤.png',
      },
      {
        name: '은하(여자친구)',
        uniqueName: 'eunha_gf',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhp2qkkdpm0vj_858237_20201109141322_500.jpg',
      },
      {
        name: '진호(펜타곤)',
        uniqueName: 'jinho',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klt79var_ㄹㅇ마지막.png',
      },
    ],
  },
  estp: {
    rate: 6,
    best_partner: ['isfj', 'istj'],
    description: [
      '일을 마지막에 폭발적으로 몰아서 해요.',
      '분위기를 밝게 띄우는데 매우 능숙해요.',
      '주위 사람들이나 일어나는 일에 관심이 많아요.',
      '거짓말을 잘 못하고 하고싶은 말을 필터링 없이 내뱉어요.',
      '자신감이 항상 철철 넘쳐요.',
      '남에게 선입견이 별로 없고 개방적이에요.',
    ],
    member: [
      {
        name: '성찬(NCT)',
        uniqueName: 'sungchan_nct',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klbva8dv_2892718_20201119150332_500.jpg',
      },
      {
        name: '연정(우주소녀)',
        uniqueName: 'yeonjung',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhe0qkjmn4hcj_968276_20200609163631_500.jpg',
      },
      {
        name: '김준수(JYJ)',
        uniqueName: 'xia',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klt6tuqf_xia.png',
      },
    ],
  },
  esfj: {
    rate: 5,
    best_partner: ['isfp', 'istp'],
    description: [
      '아무 생각 없이 멍 때리기를 잘해요.',
      '수업 들을 때 내용을 이해했는지와 무관하게 고개를 많이 끄덕여요.',
      '현실주의자, 세상 물정에 밝아요.',
      '다같이 사이좋게 지내는 것이 좋아요.',
      '자존감이 높은 편이지만 딱히 티는 내지 않아요.',
      '준비성이 철저하고, 참을성이 많고 타인을 잘 도와요.',
    ],
    member: [
      {
        name: '시온(원어스)',
        uniqueName: 'xion_oneus',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klc1e7zg_2299050_20210119153351_500.jpg',
      },
      {
        name: '규현(슈퍼주니어)',
        uniqueName: 'kyuhyun',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8kiq3jeuo_79921403_1595315788039_16_600x600.jpg',
      },
      {
        name: '피오(블락비)',
        uniqueName: 'po',
        imagePath:
          'https://storage.plam.kr/upload/image/original/9klc1slgb_80097987.jpg',
      },
    ],
  },
  istj: {
    rate: 25,
    best_partner: ['esfp', 'estp'],
    description: [
      '주어진 업무나 책임을 끝까지 완수해요.',
      '예고없이 갑작스러운 변화를 매우 싫어해요.',
      '이유 없이 돌아다니지 않아요.',
      '교통체증을 미리 계산해서 약속 시간을 지켜요.',
      '참고 참았다가 확 터뜨리는 편이에요.',
      '정리정돈을 해놓는 것이 우선이에요.',
    ],
    member: [
      {
        name: '마크(GOT7)',
        uniqueName: 'marklee',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8kisla1v8_80259111.jpg',
      },
      {
        name: '혁(빅스)',
        uniqueName: 'hyuk',
        imagePath:
          'https://storage.plam.kr/upload/image/original/8klc28z49_80127907.jpg',
      },
      {
        name: '찬미(AOA)',
        uniqueName: 'chanmi_aoa',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klc2ife5_686998_20191122151705_500.jpg',
      },
    ],
  },
  estj: {
    rate: 15,
    best_partner: ['isfp', 'istp'],
    description: [
      '모든 것이 제자리에 있어야 하고, 계획이 틀어지는 건 싫어요.',
      '외로움을 별로 타지 않아요.',
      '싸움을 싫어하지만 싸워서 지는 것도 싫어요.',
      '모든 일을 계획적으로 구상하고 실행해요.',
      '호불호가 확실해요.',
      '분명한 규칙을 중요시하고 그에 따라 행동하고 일을 추진하고 완성해요.',
    ],
    member: [
      {
        name: '도운(Day6)',
        uniqueName: 'dowoon_day6',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klc2lw4p_895391_20200827113302_500.jpg',
      },
      {
        name: '제인(모모랜드)',
        uniqueName: 'jane_momo',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klburk6b_1229550_20201117135341_500.jpg',
      },
      {
        name: '지수(블랙핑크)',
        uniqueName: 'jisoo_bp',
        imagePath:
          'https://storage.plam.kr/upload/image/original/crawl-6rhlf5klc2orkf_995174_20201002120652_500.jpg',
      },
    ],
  },
}
