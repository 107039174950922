import React from 'react'
import { Flex, Typography, Caption1 } from '../../atoms'
import { Link } from 'react-router-dom'
import { handleTrackImgError } from '@utils/handler'
import { like_gray_img } from '@images'
import { converseUnitEng } from '@utils/format'
import Image from './Image'

const onelineStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
}

const ArtistTrackItem = ({ item, index }) => {
  return (
    // <Link to={'/track/' + item._id}>
    <Link
      to={`/track/${
        item.customUrlInfo ? item.customUrlInfo.autoUrl : item._id
      }`}
    >
      <Flex style={{ paddingBottom: '24px' }}>
        <Flex
          style={{
            paddingRight: '14px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            size={['48px', '48px']}
            src={item && item.image64Uri}
            style={{ borderRadius: '6px' }}
            onError={handleTrackImgError}
          />
        </Flex>
        <Flex
          type="column"
          style={{
            justifyContent: 'center',
            flex: 1,
            maxWidth: '197px',
          }}
        >
          <Typography
            style={{ ...onelineStyle, paddingBottom: '2px' }}
            type="Medium"
            size="14px"
          >
            {item && item.title}
          </Typography>
          {/* {item && item.releaseArtistInfo.name} */}
          <Typography
            type="Medium"
            size="12px"
            color="#949494"
            style={onelineStyle}
          >
            {item &&
              item.releaseArtistList &&
              item.releaseArtistList.map((artist) => artist['name']).join(', ')}
          </Typography>
        </Flex>
        <Flex
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
            paddingRight: '10px',
          }}
        >
          <img
            src={like_gray_img}
            style={{ width: '16px', height: '16px', marginRight: '4px' }}
          />
          <Caption1 color="#646464">{converseUnitEng(item.likeCount)}</Caption1>
        </Flex>
      </Flex>
    </Link>
  )
}

export default ArtistTrackItem
