import React, { useRef, useState, useEffect } from "react";
import styled from 'styled-components'

const SegmentedContainer = styled.div`
  margin: 0;
  font-family: 'NotoSansCJKkr-Medium';
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  .selected-item {
    text-align: center;
  }



  .controls-container {
    --highlight-width: auto;
    --highlight-x-pos: 0;

    display: flex;
  }



  .controls {
    display: inline-flex;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #f4f4f4;
    max-width: 320px;
    width: 100vw;
    height: 32px;
    padding: 2px;
    margin: auto;
    overflow: hidden;
    position: relative;
  }

  .controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .segment {
    /* width: 100%; uncomment for each segment to have matching width */
    /* min-width: 120px; */
    flex: 1;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  .segment label {
    cursor: pointer;
    display: block;
    font-weight: 500;
    color: #949494;
    padding: 5px;
    transition: color 0.5s ease;
  }

  .segment.active label {
    color: #242424;
    font-family: 'NotoSansCJKkr-Bold';
  }

  .controls::before {
    content: "";
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    /* max-width: 100%; */
    /* width: var(--highlight-width); */
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    z-index: 0;
  }

  /* Only allow transitions once component is ready */
  .controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }
`
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({
  name,
  segments,
  callback,
  defaultIndex = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef();
  const controlRef = useRef();
  const tsetIndex = useRef(defaultIndex);

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const handleResize = () => {
    const activeSegmentRef = segments?.[tsetIndex.current]?.ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;
    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;
    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value, index) => {
    setActiveIndex(index);
    tsetIndex.current = index
    callback(value, index);
  };

  return (
    <SegmentedContainer>
      <div className="controls-container" ref={controlRef}>
        <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
          {
            segments?.map((item, i) => (
              <div
                key={item.label}
                className={`segment ${i === activeIndex ? "active" : "inactive"}`}
                ref={item.ref}
              >
                <input
                  type="radio"
                  value={item.value}
                  id={item.label}
                  name={name}
                  onChange={() => onInputChange(item.value, i)}
                  checked={i === activeIndex}
                />
                <label htmlFor={item.label}>{item.label}</label>
              </div>
            ))
          }
        </div>
      </div>
    </SegmentedContainer>
  );
}

export default SegmentedControl;
