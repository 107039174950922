import React from 'react'
import { Flex, Caption1, H6 } from '@components/atoms'
import { default_album_img } from '@images'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { handleAlbumImgError } from '@utils/handler'
import { colors } from '@colors'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const LatestAlbumItem = ({ item, itemStyle }) => {
  return (
    <Link to={`/album/${item.customUrlInfo.autoUrl}`}>
      <Flex
        type="column"
        style={{ ...itemStyle, width: '150px', height: '194px' }}
      >
        <Image
          src={item.image128Uri ? item.image128Uri : default_album_img}
          onError={e => handleAlbumImgError(e)}
          size={['150px', '150px']}
          style={{
            borderRadius: '10px',
            border: `solid 0.5px ${colors.inActive}`,
          }}
        />
        <H6 align="left" style={ellipsisStyle}>
          {item.title}
        </H6>
        <Caption1 align="left" color="#949494" style={ellipsisStyle}>
          {item.releaseArtistList.map(
            (artist, index) =>
              `${artist.name}
              ${
                !(index === item.releaseArtistList.length - 1) ? ',\u00A0' : ''
              }`,
          )}
        </Caption1>
      </Flex>
    </Link>
  )
}

export default LatestAlbumItem
