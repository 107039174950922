import styled from 'styled-components'

/**
 * 모바일 웹의 레이아웃을 잡는 Template Wrapper 컴포넌트
 */
const FlexibleBodyWrapper = styled.div.attrs({
  className: 'flexible_body_wrapper',
})`
  width: 100vw;
  padding: 20px;
  background-color: white;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  max-width: none !important;
  min-width: 320px;
  display: flex;
`

export default FlexibleBodyWrapper
