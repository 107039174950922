import { ArtistYoutubeTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ArtistYoutubePage = () => {
  const params = useParams()

  const { trendStore, artistStore } = useStore()
  const { artistTrend } = trendStore
  const { artistDetail } = artistStore
  const fetchArtistTrend = trendStore.fetchArtistTrend || (() => {})
  const fetchArtistTrendTrack = trendStore.fetchArtistTrendTrack || (() => {})
  const fetchArtistDetail = artistStore.fetchArtistDetail || (() => {})

  const [periodType, setPeriodType] = useState(1)

  useEffect(() => {
    if (params?.id) {
      fetchArtistDetail(params.id)
    }
  }, [params])

  useEffect(() => {
    if (artistDetail) {
      fetchArtistTrend(artistDetail?._id, { __periodDays: periodType })
      fetchArtistTrendTrack(artistDetail?._id, { __periodDays: periodType })
    }
  }, [artistDetail, periodType, fetchArtistTrend, fetchArtistTrendTrack])

  return (
    <ArtistYoutubeTemplate
      artist={artistDetail}
      artistTrend={artistTrend}
      periodType={periodType}
      setPeriodType={setPeriodType}
    />
  )
}

export default observer(ArtistYoutubePage)
