import {
  default_album_img,
  default_music_img,
  default_profile_img,
} from '@images'

export const handleArtistImgError = (e) => {
  e.target.src = default_profile_img
}

export const handleAlbumImgError = (e) => {
  e.target.src = default_album_img
}

export const handleTrackImgError = (e) => {
  e.target.src = default_music_img
}
