import React, { useEffect, useState } from 'react'
import { Flex, H4 } from '@components/atoms'
import styled from 'styled-components'
import { LatestAlbumItem, LatestAlbumItemSkeleton } from '@components/molecules'
import { move_btn_img } from '@images/'
import { observer } from 'mobx-react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useStore } from '@utils/hooks'

const ListBox = styled(Flex)`
  flex-direction: column;
`

const ListContent = styled(Slider)`
  width: calc(100% + 40px);
  margin-left: -20px;

  .slick-slide:first-child {
    padding-left: 20px;
  }

  .slick-slide:last-child {
    padding-right: 20px;
  }

  .slick-slide {
    padding: 0 10px;
  }
`

const LatestAlbumList = ({ latestAlbumList }) => {
  const { handlerObj } = useStore()

  return (
    <ListBox style={{ marginBottom: '24px' }}>
      <Flex
        style={
          {
            justifyContent: 'left',
            alignItems: 'center',
            marginBottom: '16px',
            height: '29px',
          }
        }
      >
        <H4 type="Bold" align="left">
          최신 앨범
        </H4>
        {/* <img src={move_btn_img} style={{ width: '20px', height: '20px' }} /> */}
      </Flex>
      <ListContent
        dots={false}
        infinite={false}
        draggable
        slide={'a'}
        slidesToShow={2.8}
        slidesToScroll={2}
        swipeToSlide={false}
        variableWidth={false}
        adaptiveHeight
        rows={2}
        responsive={
          [
            {
              breakpoint: 320,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 375,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
              },
            },
          ]
        }
      >
        {
handlerObj?.['fetchLatestAlbum']?.isLoading
  ? Array(3)
    .fill(1)
    .map((item, index) => (
      <LatestAlbumItemSkeleton key={`LAlbumSkel-${index}`} />
    ))
  : latestAlbumList
            && latestAlbumList.map((item, index) => {
              return (
                <LatestAlbumItem
                  item={item}
                  key={item._id}
                  itemStyle={{ marginBottom: '6px' }}
                />
              )
            })
        }

        {/* {
          latestAlbumList
          && latestAlbumList.map(item => (
            <LatestAlbumItem item={item} key={item._id} />
          ))
        } */}
      </ListContent>
    </ListBox>
  )
}

export default observer(LatestAlbumList)
