import React from 'react'
import { Flex, Caption1, H5, Caption2 } from '@components/atoms'
import { colors } from '@colors'
import moment from 'moment'
import { STORAGE_URL } from '@consts'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const NotificationItem = ({
  item,
  index,
  onClickNotificationItem = () => {},
}) => {
  const dateFromString = date => {
    const diffDay = moment().diff(moment(date), 'days')
    const diffTime = moment().diff(moment(date), 'minute')

    if (diffDay > 7) {
      if (moment(date).isSame(moment(), 'years')) {
        return moment(date).format('M월 D일 hh:mm')
      }

      return moment(date).format('YYYY-MM-DD')
    }
    if (diffDay === 0) {
      if (diffTime < 60) {
        return `${diffTime}분 전`
      }
      return `${Math.floor(diffTime / 60)}시간 전`
    }
    return `${diffDay}일 전`
  }

  return (
    <Flex
      style={
        {
          padding: '10px 24px',
          // borderBottom: `0.5px solid ${colors.light_pink}`,
          cursor: 'pointer',
        }
      }
      onClick={
        () => {
          onClickNotificationItem(item)
        }
      }
    >
      {
        (!!item?.trackInfo?.albumInfo?.image64Path
        || !!item?.albumInfo?.image64Path) && (
          <img
            src={
              STORAGE_URL
            + (item?.trackInfo?.albumInfo?.image64Path
              || item?.albumInfo?.image64Path)
            }
            alt="notification_img"
            style={
              {
                marginTop: '4px',
                width: '48px',
                height: '48px',
                borderRadius: '12px',
                marginRight: '12px',
              }
            }
          />
        )
      }
      <Flex
        style={
          {
            gap: '4px',
            flex: 1,
            userSelect: 'none',
          }
        }
        justify={'center'}
        type={'column'}
      >
        <H5 align={'left'}>{item.body}</H5>
        <Flex align="center">
          <Caption1 color={colors.brown_grey} align={'left'}>
            {dateFromString(item?.createdAt)}
          </Caption1>
          {
            moment().diff(moment(item.createdAt), 'days') <= 3 && !item.isRead && (
              <Caption2
                color={'red'}
                style={
                  {
                    marginLeft: '4px',
                    paddingBottom: '2px',
                  }
                }
              >
              *new
              </Caption2>
            )
          }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NotificationItem
