import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet'
import { AppDownloadTemplate } from '@components/templates'
import { download_preview_img } from '@images/index'

const AppDownloadPage = () => {
  return (
    <>
      <Helmet>
        <title>{`플램 - 리워드 음감회, PLAM`}</title>
        <meta
          name="description"
          content={
            '음악을 감상하면 돈이 벌리는 음악 앱테크 리워드 서비스, 리워드 음감회로 여러분을 초대합니다. 지금 다운로드 받고, 새로운 음악을 발견하며 돈도 벌어보세요.'
          }
        />
        {/* <meta property="og:image" content={download_preview_img} /> */}
      </Helmet>
      <AppDownloadTemplate />
    </>
  )
}

export default AppDownloadPage
