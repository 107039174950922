import React from 'react'
import { Flex } from '@components/atoms'

const Image = ({ size, src, style, onError, onClick }) => {
  return (
    <Flex
      onClick={onClick}
      style={
        {
          ...style,
          width: '100%',
          height: '100%',
          minWidth: size && size[0] && size[0],
          minHeight: size && size[1] && size[1],
          maxWidth: size && size[0] && size[0],
          maxHeight: size && size[1] && size[1],
          overflow: 'hidden',
        }
      }
    >
      <img
        src={src}
        alt={`img_${src}`}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        loading="lazy"
        onError={onError}
      />
    </Flex>
  )
}

export default Image
