import React from 'react'
import { Flex, H6 } from '@components/atoms'
import { default_album_img } from '@images'
import styled, { keyframes } from 'styled-components'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 1; }
`

const AnimationPopup = styled.div`
  animation-name: ${boxFade};
  animation-duration: 1s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;

  position: absolute;
  z-index: 9999;
  /* top: ${props => props.scrollHeight}px; */
  top:0;
  left: 0;
  width: 100%;
  height: 5000vh;
  backdrop-filter: blur(4.1px);
  background-color: rgba(36, 36, 36, 0.7);
`

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  top: calc(0.45% + ${props => props.scrollHeight}px);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Overlay = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`

const DetailCoverImage = ({ setIsDetailCover, imgSrc }) => {
  // const [scrollPosition, setScrollPosition] = useState(window.pageYOffset)
  // const handleScroll = () => {
  //   const position = window.pageYOffset
  //   // setScrollPosition(position)
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true })

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  return (
    <AnimationPopup scrollHeight={window.pageYOffset}>
      <Overlay onClick={() => setIsDetailCover(false)} />
      <PopupInner scrollHeight={window.pageYOffset}>
        <img
          src={imgSrc ? imgSrc : default_album_img}
          style={{ width: '100%', zIndex: '9999' }}
        />

        <H6
          onClick={() => setIsDetailCover(false)}
          color="#ffffff"
          style={
            {
              textDecoration: 'underline',
              cursor: 'pointer',
              marginTop: '30px',
            }
          }
        >
          닫기
        </H6>
      </PopupInner>
    </AnimationPopup>
  )
}

export default DetailCoverImage
