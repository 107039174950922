import React, { useEffect } from 'react'
import { kakao_share_btn, plam_mbti_kakao } from '@images/'
import { DOMAIN_URL } from '@consts/'

const KakaoShareBtn = () => {
  useEffect(() => {
    createKakaoButton()
  }, [])
  const createKakaoButton = () => {
    // kakao sdk script이 정상적으로 불러와졌으면 window.Kakao로 접근이 가능합니다
    if (window.Kakao) {
      const kakao = window.Kakao
      // 중복 initialization 방지
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY)
      }
      kakao.Link.createDefaultButton({
        // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
        container: '#kakao-link-btn',
        objectType: 'feed',
        content: {
          title: `내가 만약 '아이돌' 이었다면?`,
          description: `나와 찰떡같이 잘 어울리는 멤버는 누구일까?`,
          imageUrl:
            'https://plam-storage-dev.s3.ap-northeast-2.amazonaws.com/upload/mbti/plam_mbti_share.jpg', // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            mobileWebUrl: `https://${DOMAIN_URL}/myidol`,
            webUrl: `https://${DOMAIN_URL}/myidol`,
          },
        },
        // social: {
        //   likeCount: 77,
        //   commentCount: 55,
        //   sharedCount: 333,
        // },
        buttons: [
          {
            title: '테스트 하러가기',
            link: {
              mobileWebUrl: `https://${DOMAIN_URL}/myidol`,
              webUrl: `https://${DOMAIN_URL}/myidol`,
            },
          },
          // {
          //   title: '앱으로 보기',
          //   link: {
          //     mobileWebUrl: window.location.href,
          //     webUrl: window.location.href,
          //   },
          // },
        ],
      })
    }
  }
  return (
    <div
      className="kakao-share-button"
      style={
        {
          width: 48,
          height: 48,
        }
      }
    >
      {/* Kakao share button */}
      <button
        id="kakao-link-btn"
        style={
          {
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            margin: 0,
            padding: 0,
          }
        }
      >
        <img
          src={kakao_share_btn}
          alt="kakao-share-icon"
          style={
            {
              width: 48,
              height: 48,
              borderRadius: 24,
              border: '2px solid #ffffff',
              boxSizing: 'border-box',
            }
          }
        />
      </button>
    </div>
  )
}
export default KakaoShareBtn
