import { Network } from '.'

export default class CommentNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getComments = async params => {
    try {
      const { data } = await this.network._axiosApi(`/comments`, 'get', params)
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('CommentNetwork getComments error', error)
    }
  }

  postComments = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments`,
        'post',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('CommentNetwork postComments error', error)
    }
  }

  putComments = async (commentId, params) => {
    if (!commentId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/${commentId}`,
        'put',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('CommentNetwork putComments error', error)
    }
  }

  deleteComments = async (commentId, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/${commentId}`,
        'delete',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('CommentNetwork deleteComments error', error)
    }
  }

  postCommentsLikes = async (commentId, userId) => {
    try {
      const {
        data,
      } = await this.network._axiosApiAuth(
        `/comments/${commentId}/likes`,
        'post',
        { userId },
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('CommentNetwork postCommentsLikes error', error)
    }
  }

  deleteCommentsLikes = async (commentId, userId) => {
    try {
      const {
        data,
      } = await this.network._axiosApiAuth(
        `/comments/${commentId}/likes`,
        'delete',
        { userId },
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('CommentNetwork deleteCommentsLikes error', error)
    }
  }

  postCommentsReports = async (commentId, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/${commentId}/reports`,
        'post',
        params,
      )
      return (data && data) || null
    }
    catch (error) {
      console.warn('CommentNetwork postCommentsReports error', error)
    }
  }
}
