import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { Store } from '.'
import { Network } from './networks'
import { AlbumModel, ArtistModel, TrackModel } from './models'
import YoutubeVideoModel from './models/YoutubeVideoModel'

export default class ArtistStore {
  @observable artistDetail
  @observable autocompleteArtistList
  @observable isListLoading

  @observable artistSmartLinkList

  @persist @observable isReadedOpenInplam
  @persist @observable isReadedShareInplam

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistDetail = null
    this.autocompleteArtistList = []
    this.isListLoading = false

    this.artistSmartLinkList = []
  }

  @action.bound
  async initClient() {}

  @action.bound
  fetchArtistDetail = async artistId => {
    return this.store.useLoading(async () => {
      if (!artistId) return

      const fetchedArtist = await this.network.artistNetwork.getArtist(artistId)
      if (fetchedArtist) {
        this.artistDetail = new ArtistModel(this.store, fetchedArtist)
      }
      // // if (this.artistDetail.isDisplayFollowerData) {
      // await this.store.statsStore.fetchSnsListLike(
      //   this.artistDetail._id,
      //   'monthly',
      // )

      // // }
    })
  }

  @action.bound
  fetchArtistDetailById = async artistId => {
    return this.store.useLoading(async () => {
      if (!artistId) return

      const fetchedArtist = await this.network.artistNetwork.getArtistById(
        artistId,
      )
      if (fetchedArtist) {
        return fetchedArtist
      }

      // }
    })
  }

  @action.bound
  fetchAritstTrackList = async (artistId, option) => {
    if (!artistId) return
    this.isListLoading = true
    const res = await this.network.trackNetwork.getTracks({
      artistId,
      limit: option && option.limit ? option.limit : 20, // default : 20
      offset: option && option.offset ? option.offset : 0, // default : 0
      type: option && option.type ? option.type : 'release', // default : release
      sort: option && option.sortBy === 'title' ? 1 : -1, // default : desc
      sortBy: option && option.sortBy ? option.sortBy : 'albumInfo.releasedAt', // default : latest
    })

    if (!res) return

    if (option && option.type === 'participate') {
      if (option && option.offset > 0) {
        this.artistDetail['participationTrackList'] = this.artistDetail[
          'participationTrackList'
        ].concat(
          res.trackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(this.store, elem)),
        )
      } else {
        this.artistDetail['participationTrackList'] = res.trackList
          .filter(elem => !!elem)
          .map(elem => new TrackModel(this.store, elem))
      }
    } else if (option && option.type === 'release') {
      if (option && option.offset > 0) {
        this.artistDetail['releaseTrackList'] = this.artistDetail[
          'releaseTrackList'
        ].concat(
          res.trackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(this.store, elem)),
        )
      } else {
        this.artistDetail['releaseTrackList'] = res.trackList
          .filter(elem => !!elem)
          .map(elem => new TrackModel(this.store, elem))
      }
    } else if (option && option.offset > 0) {
      this.artistDetail['totalTrackList'] = this.artistDetail[
        'totalTrackList'
      ].concat(
        res.trackList
          .filter(elem => !!elem)
          .map(elem => new TrackModel(this.store, elem)),
      )
    } else if (this.artistDetail['totalTrackList']) {
      this.artistDetail['totalTrackList'] = res.trackList
        .filter(elem => !!elem)
        .map(elem => new TrackModel(this.store, elem))
    }
    this.isListLoading = false
  }

  @action.bound
  fetchAritstVideoList = async (artistId, option) => {
    if (!artistId) return
    this.isListLoading = true
    const res = await this.network.artistNetwork.getArtistYoutube(artistId, {
      limit: option && option.limit ? option.limit : 10, // default : 10
      offset: option && option.offset ? option.offset : 0, // default : 0
      type: option && option.type ? option.type : 'total', // default : total
      sort: option && option.sortBy === 'title' ? 1 : -1, // default : desc
      sortBy:
        option && option.sortBy
          ? option.sortBy
          : 'youtubeVideoInfo.publishedAt', // default : latest
    })

    if (!res) return

    if (option && option.type === 'participate') {
      if (option && option.offset > 0) {
        this.artistDetail['participationVideoList'] = this.artistDetail[
          'participationVideoList'
        ].concat(
          res.youtubeVideoList
            .filter(elem => !!elem)
            .map(elem => new YoutubeVideoModel(this.store, elem)),
        )
      } else {
        this.artistDetail[
          'participationVideoList'
        ] = res.youtubeVideoList
          .filter(elem => !!elem)
          .map(elem => new YoutubeVideoModel(this.store, elem))
      }
    } else if (option && option.type === 'release') {
      if (option && option.offset > 0) {
        this.artistDetail['releaseVideoList'] = this.artistDetail[
          'releaseVideoList'
        ].concat(
          res.youtubeVideoList
            .filter(elem => !!elem)
            .map(elem => new YoutubeVideoModel(this.store, elem)),
        )
      } else {
        this.artistDetail['releaseVideoList'] = res.youtubeVideoList
          .filter(elem => !!elem)
          .map(elem => new YoutubeVideoModel(this.store, elem))
      }
    } else if (option && option.offset > 0) {
      this.artistDetail['totalVideoList'] = this.artistDetail[
        'totalVideoList'
      ].concat(
        res.youtubeVideoList
          .filter(elem => !!elem)
          .map(elem => new YoutubeVideoModel(this.store, elem)),
      )
    } else if (this.artistDetail['totalVideoList']) {
      this.artistDetail['totalVideoList'] = res.youtubeVideoList
        .filter(elem => !!elem)
        .map(elem => new YoutubeVideoModel(this.store, elem))
    }
    this.isListLoading = false
  }

  @action.bound
  fetchAritstAlbumList = async (artistId, option) => {
    if (!artistId) return
    this.isListLoading = true
    const albumList = await this.network.albumNetwork.getAlbums({
      artistId,
      limit: option && option.limit ? option.limit : 20, // default : 20
      offset: option && option.offset ? option.offset : 0, // default : 0
      type: option && option.type ? option.type : 'release', // default : release
      sort: option && option.sortBy === 'title' ? 1 : -1, // default : desc
      sortBy: option && option.sortBy ? option.sortBy : 'releasedAt', // default : latest
    })

    if (!albumList) return

    if (option && option.type === 'participate') {
      if (option && option.offset > 0) {
        this.artistDetail['participationAlbumList'] = this.artistDetail[
          'participationAlbumList'
        ].concat(
          albumList
            .filter(elem => !!elem)
            .map(elem => new AlbumModel(this.store, elem)),
        )
      } else {
        this.artistDetail['participationAlbumList'] = albumList
          .filter(elem => !!elem)
          .map(elem => new AlbumModel(this.store, elem))
      }
    } else if (option?.type === 'release') {
      if (option && option.offset > 0) {
        this.artistDetail['releaseAlbumList'] = this.artistDetail[
          'releaseAlbumList'
        ].concat(
          albumList
            .filter(elem => !!elem)
            .map(elem => new AlbumModel(this.store, elem)),
        )
      } else {
        this.artistDetail['releaseAlbumList'] = albumList
          .filter(elem => !!elem)
          .map(elem => new AlbumModel(this.store, elem))
      }
    } else if (option && option.offset > 0) {
      this.artistDetail['totalAlbumList'] = this.artistDetail[
        'totalAlbumList'
      ].concat(
        albumList
          .filter(elem => !!elem)
          .map(elem => new AlbumModel(this.store, elem)),
      )
    } else if (this.artistDetail?.totalAlbumList) {
      this.artistDetail['totalAlbumList'] = albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem))
    } else {
      this.artistDetail['totalAlbumList'] = []
    }

    this.isListLoading = false
  }

  @action.bound
  fetchAutocompleteArtistList = async () => {
    return this.store.useLoading(async () => {
      const fetchedArtistList = await this.network.artistNetwork.getArtistList()
      if (fetchedArtistList) {
        this.autocompleteArtistList =
          fetchedArtistList
            .filter(elem => !!elem)
            .map(elem => new ArtistModel(this.store, elem)) || []
      }
    })
  }

  @action.bound
  updateArtist = async artist => {
    return this.store.useLoading(async () => {
      if (!artist) return

      const updatedArtist = await this.network.artistNetwork.putArtist(artist)
      if (!updatedArtist) return

      this.artistDetail = new ArtistModel(this.store, updatedArtist)
    })
  }

  // 오픈 팬스팟 가이드 읽음 유무
  @action.bound
  setIsReadedOpenInplam = value => {
    this.isReadedOpenInplam = value
  }

  // 오픈 팬스팟 공유 가이드 읽음 유무
  @action.bound
  setIsReadedShareInplam = value => {
    this.isReadedShareInplam = value
  }

  @action.bound
  async fetchSmartLinkList() {
    return this.store.useLoading(async () => {
      const res = await this.network.artistNetwork.getSmartLinkList(
        this.artistDetail._id,
      )

      if (res) {
        this.artistSmartLinkList = res
      }
      return !!res
    })
  }
}
