import React, { useEffect, useRef, useState } from 'react'
import Slider from 'rc-slider';
import { Flex, Typography, FlexibleBodyWrapper, Caption1, Input } from '@components/atoms'
import { beyond_plam_img } from '@images'
import { convertToPricingComma } from '@utils/format';
import { SegmentedControl } from '@components/molecules';
import styled from 'styled-components'
import { desktop } from '@styles/media';
import EvaluationBtn from '@components/molecules/button/EvaluationBtn';
import { plam_app_logo_img, plam_logo_img, value_img } from '@images/index';
import LoanRequestBtn from '@components/molecules/button/LoanRequestBtn';
import TextareaAutosize from 'react-textarea-autosize'
import DirectInputBtn from '@components/molecules/button/DirectInputBtn';
import { Observer } from 'mobx-react';
import { amplitudeEvent } from '@utils/amplitude';


const Comment1 = styled.div`
  display: flex;
  flex-direction: ${props => props.type && props.type};
  justify-content: ${props => props.justify && props.justify};
  align-items: ${props => props.align && props.align};
  width: 182px;
  @media ${desktop}{
    width: 100%;
  }
`

const Comment2 = styled.span`
font-family: ${props => props.type && `NotoSansCJKkr-${props.type}`};
font-size: ${props => props.size && props.size};
color: ${props => props.color && props.color};

white-space: pre-line;
text-align: center;

@media ${desktop}{
  text-align: left;
}
`
const SliderContainer = styled.div`
  .rc-slider {
    position: relative;
    width: 100%;
    height: 14px;
    padding: 5px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #e9e9e9;
    border-radius: 6px;
  }
  .rc-slider-track {
    position: absolute;
    height: 4px;
    background-color: #abe2fb;
    border-radius: 6px;
  }
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #24242480;
    border-radius: 50%;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    opacity: 0.8;
    touch-action: pan-x;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #57c5f7;
    box-shadow: 0 0 0 4px #24242480;
  }
  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }
  .rc-slider-handle:focus-visible {
    border-color: #2db7f5;
    box-shadow: 0 0 0 3px #24242480;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #24242480;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: #999;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }
  .rc-slider-mark-text-active {
    color: #666;
  }
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
  }
  .rc-slider-dot {
    position: absolute;
    bottom: -2px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    background-color: #fff;
    border: 2px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer;
  }
  .rc-slider-dot-active {
    border-color: #24242480;
  }
  .rc-slider-dot-reverse {
    margin-right: -4px;
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    width: 4px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-track {
    bottom: 0;
    left: 5px;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-top: 0;
    margin-left: -5px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    width: 4px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-dot {
    margin-left: -2px;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-leave {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      transform: scale(0, 0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
    }
  }
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
    }
    100% {
      transform: scale(0, 0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .rc-slider-tooltip {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip-hidden {
    display: none;
  }
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  .rc-slider-tooltip-inner {
    min-width: 24px;
    height: 24px;
    padding: 6px 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
  }
`

const CustomInput = styled.input`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  color: #242424;
  border: none;
  min-width: 86px;
  height: 27px;
  padding:0;
  background-color: transparent;


  ::placeholder {
    background-color: trasparent;
    color: #d4d4d4;
  }


`

const MIN_VALUE = 1000000
const MAX_VALUE = 300000000
const STEP_VALUE = 1000000
const DEFAULT_PERIOD_INDEX = 4

const ValuationTemplate = () => {
  const periodData = [
    {
      label: "1년",
      value: {
        eValue: 1.41771,
        loanValue: 1.33
      },
      ref: useRef()
    },
    {
      label: "3년",
      value: {
        eValue: 5.73973,
        loanValue: 1.77
      },
      ref: useRef()
    },
    {
      label: "5년",
      value: {
        eValue: 7.97133,
        loanValue: 2.33
      },
      ref: useRef()
    },
    {
      label: "10년",
      value: {
        eValue: 8.17377,
        loanValue: 3.33
      },
      ref: useRef()
    },
    {
      label: "20년",
      value: {
        eValue: 9.89713,
        loanValue: 4.47
      },
      ref: useRef()
    },

  ]



  const [revenue, setRevenue] = useState(1000000) // 수익
  const [period, setPeriod] = useState(periodData?.[DEFAULT_PERIOD_INDEX]?.value) // 발매 후 평균 경과에 따른 계산값
  const [isDeskTop, setIsDeskTop] = useState(window.innerWidth > 840) // 화면너비 840px 초과 상태
  const [innerHeight, setInnerHeight] = useState(window.innerHeight) // 화면너비 840px 초과 상태

  const scrollRef = useRef()



  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);



  const handleResize = (e) => {
    setInnerHeight(window.innerHeight)
    if (window.innerWidth > 840) {
      setIsDeskTop(true)
    }
    else {
      setIsDeskTop(false)
    }
  }

  return (
    <FlexibleBodyWrapper style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: 0, paddingBottom: 0 }}>
      <Flex
        ref={scrollRef}
        type={"row"}
        style={{ minHeight: '640px', flex: 1 }}
      >
        <Flex
          type={"column"}
          style={{ maxWidth: "840px", width: '100%', paddingTop: isDeskTop ? '56px' : '20px', paddingBottom: '12px', paddingLeft: isDeskTop ? '52px' : '0px' }}
        >
          <Flex type={"column"} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            {/* 상단 로고 */}
            <img
              src={plam_app_logo_img}
              style={{ width: '56px', height: '16px' }}
              alt="header_logo"
            />

            {/* 헤더 문구 */}
            <Flex type="column" style={{ marginTop: '20px' }}>
              <Typography size="12px" type="Medium">지난 6개월간 유통사를 통해 정산받은,</Typography>
              <Typography size="12px" type="Medium">월 평균 음원 수익이 얼마인가요?</Typography>
            </Flex>

            <Flex type="column" style={{ paddingTop: '20px', marginTop: '4px', paddingBottom: '20px', marginBottom: '4px', maxWidth: '680px', minWidth: '220px' }}>

              <InputFormComponent revenue={revenue} setRevenue={setRevenue} scrollRef={scrollRef} isDeskTop={isDeskTop} />

              {/* 수익 슬라이더 */}
              <div style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                <SliderContainer>
                  <Slider
                    className='slider'
                    min={MIN_VALUE}
                    max={MAX_VALUE}
                    onChange={
                      (val) => {
                        setRevenue(val)
                      }
                    }
                    onAfterChange={
                      (val) => {
                        amplitudeEvent.ENTER_DIRECTLY_COMPLITE({ profit: val, type: 'slider' })
                      }
                    }
                    step={STEP_VALUE}
                    value={revenue || MIN_VALUE}
                    railStyle={{ height: '8px', borderRadius: '4px', backgroundColor: '#f4f4f4' }}
                    trackStyle={{ height: '8px', borderRadius: '4px', backgroundColor: '#242424' }}
                    handleStyle={
                      {
                        width: '20px',
                        height: '20px',
                        borderRadius: '10px',
                        backgroundColor: '#242424',
                        opacity: '1',
                        borderWidth: '0px',
                      }
                    }
                  />
                </SliderContainer>
              </div>
              <Flex type="row" justify="space-between" style={{ marginTop: '20px' }}>
                <Typography size="10px" type={"Medium"} color={"#646464"}>{`${convertToPricingComma(MIN_VALUE)}원`}</Typography>
                <Typography size="10px" type={"Medium"} color={"#646464"}>{`${convertToPricingComma(MAX_VALUE)}원`}</Typography>
              </Flex>
            </Flex>

          </Flex>

          {/* 구분선 */}
          <div style={{ height: '1px', backgroundColor: '#eaeaea', width: '100%' }} />
          <Flex type="column" style={{ height: isDeskTop ? 'auto' : `${innerHeight}px` }}>
            {/* 하단 컨텐츠 */}
            <Flex
              type="column"
              style={
                {
                  // height: '100vh',
                }
              }
            >
              <Flex
                type="column"
                style={
                  {
                    paddingTop: '23px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }
                }
              >

                <Comment1 type={'column'}>
                  <Caption1 align="left" style={{ lineHeight: '20px' }} color={"#242424"}>보유하고 계신 음악 IP들이 발매된 후 평균 어느 정도 경과하셨나요?</Caption1>
                </Comment1>

                <Flex type={'column'} style={{ marginTop: '24px', alignSelf: 'flex-start', }}>
                  <SegmentedControl
                    name="group-1"
                    callback={
                      (val) => {
                        setPeriod(val)
                        const _val = periodData.find(item => item?.value?.eValue === val.eValue)
                        amplitudeEvent.CLICK_EVERAGE_YEAR(_val.label)
                      }
                    }
                    defaultIndex={DEFAULT_PERIOD_INDEX}
                    segments={
                      periodData
                    }
                  />
                </Flex>
              </Flex>

              {
                isDeskTop
                  ? (
                    <Flex type={'column'} style={{ width: '100%', textAlign: 'left', marginTop: '51px', paddingTop: '10px', paddingBottom: '10px', alignItems: 'flex-start' }}>
                      <Comment2
                        type={"Regular"}
                        size={'12px'}
                        color={'#646464'}
                      >
                        {`더 정확한 가치 평가를 원하시나요? 무료로 상세한 가치 평가를 받아 보세요.\n원하실 경우 앨범 선급 투자 연결 및 음악 IP 판매 컨설팅을 제공 해 드립니다.`}
                      </Comment2>
                    </Flex>
                  )
                  : (
                    <Flex type={'column'} style={{ width: '100%', backgroundColor: '#fafafa', textAlign: 'center', borderTop: '1px solid', borderBottom: '1px solid', borderColor: '#f4f4f4', marginTop: '51px', paddingTop: '10px', paddingBottom: '10px', alignItems: 'center' }}>
                      <Comment2
                        type={"Regular"}
                        size={'12px'}
                        color={'#242424'}
                      >
                        {`더 정확한 가치 평가를 원하시나요? 무료로 상세한 가치 평가를 받아 보세요.\n원하실 경우 앨범 선급 투자 연결 및 음악 IP 판매 컨설팅을 제공 해 드립니다.`}
                      </Comment2>
                    </Flex>
                  )
              }

            </Flex>
            <Flex type={'column'} style={{ paddingTop: isDeskTop ? '40px' : '20px', paddingBottom: '12px', paddingLeft: '20px', paddingRight: '20px' }} justify={'flex-end'} align={isDeskTop ? 'flex-start' : 'center'}>

              <Flex type={isDeskTop ? 'column' : 'row'} justify={'center'} align={isDeskTop ? 'flex-start' : 'center'}>
                <Typography type={'Medium'} size={'12px'}>
                  음악 IP의 예상 가치 평가 금액
                </Typography>
                <Typography type={'Bold'} size={'16px'} color={"#0066ff"} style={{ marginLeft: isDeskTop ? '0px' : '31px', marginTop: isDeskTop ? '6px' : '0px' }}>
                  {`${convertToPricingComma(Math.ceil((period.eValue * (revenue * 6)) / 100000) * 100000)}원`}
                </Typography>
              </Flex>

              <EvaluationBtn
                text={'상세 가치평가 받기'}
                style={{ marginTop: '20px', marginBottom: '40px' }}
                onClick={
                  () => {
                    amplitudeEvent.CLICK_DETAIL_VALUATION()
                    window.open('https://plam.kr/#s20230526be02f92de9faf')
                  }
                }
              />

              <Flex type={isDeskTop ? 'column' : 'row'} justify={'center'} align={isDeskTop ? 'flex-start' : 'center'}>
                <Typography type={'Medium'} size={'12px'}>
                  예상 선급 투자 금액
                </Typography>
                <Typography type={'Bold'} size={'16px'} color={"#0066ff"} style={{ marginLeft: isDeskTop ? '0px' : '31px', marginTop: isDeskTop ? '6px' : '0px' }}>
                  {`${convertToPricingComma(Math.ceil(((period.eValue * (revenue * 6)) / period.loanValue) / 100000) * 100000)}원`}
                </Typography>
              </Flex>
              <LoanRequestBtn
                text={'선급 투자 신청하기'}
                style={{ marginTop: '20px', }}
                onClick={
                  () => {
                    amplitudeEvent.CLICK_PREPAID()
                    window.open('https://plam.kr/investment')
                  }
                }
              />

            </Flex>
          </Flex>
        </Flex>
        <Flex type={'column'} style={{ backgroundColor: '#f5f5f7', flex: 1 }} justify={'center'} align={'center'}>
          <img
            src={value_img}
            style={{ width: '50%', height: 'auto', maxWidth: '480px', maxHeight: '480px' }}
            alt="move_icon"
          />
        </Flex>
      </Flex>
    </FlexibleBodyWrapper>
  )
}

export default ValuationTemplate


const InputFormComponent = ({ revenue, setRevenue, scrollRef, isDeskTop }) => {

  const [inputWidth, setInputWidth] = useState() // 인풋 텍스트 width 자동맞추기용
  const [isEditable, setIsEditable] = useState(false) // 인풋 수정 가능상태

  const inputRef = useRef()
  const inputTextRef = useRef()

  useEffect(() => {
    setInputWidth(inputTextRef.current.offsetWidth);
  }, [revenue]);

  useEffect(() => {
    if (isEditable) {
      inputRef?.current?.focus?.()
    }
    else if (!isEditable) {
      if (!revenue) {
        setRevenue(MIN_VALUE)
      }
      else if (revenue > 0 && revenue < 100000) {
        setRevenue(100000)
      }
      else if (revenue > MAX_VALUE) {
        setRevenue(MAX_VALUE)
      }
      inputRef?.current?.blur?.()

    }
  }, [isEditable]);

  const handleEditable = () => {
    if (!isEditable) {
      setRevenue(null)
      setIsEditable(true)
      amplitudeEvent.ENTER_DIRECTLY()
    }
    else {
      setIsEditable(false)
      if (revenue) {
        amplitudeEvent.ENTER_DIRECTLY_COMPLITE({ profit: revenue, type: 'click' })
      }
      if (revenue && !isDeskTop) {
        scrollRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        // alert(`마지막으로 아래에서 해당 IP의 평균 발매일을 입력 후\n선급 신청하기를 해보세요!`)
      }
    }
  }


  return (
    <div style={{ marginBottom: '20px' }}>
      <Flex
        type={'column'}
        style={{ minWidth: '200px', maxWidth: '320px', height: '72px', border: '1px solid #242424', backgroundColor: '#ffffff', borderRadius: '12px', paddingLeft: '16px', paddingRight: '12px' }}
        justify={'center'}
        onClick={
          handleEditable
        }
      >
        <Flex style={{ marginBottom: '5px' }}>
          <Typography type={'Regular'} size="12px" color="#949494" style={{ lineHeight: '20px' }}>
            평균 음원 수익
          </Typography>
        </Flex>
        <Flex type={'row'} justify={'space-between'}>
          <Flex type={'row'} align={'center'}>
            {
              isEditable && (
                <CustomInput
                  // type="number"
                  type="text"
                  ref={inputRef}
                  onChange={
                    (e) => {
                      const parseValue = parseInt(e.target.value.replaceAll(',', ''), 10)

                      if (typeof parseValue !== 'number') {
                        return
                      }


                      if (Number.isNaN(parseValue)) {
                        setRevenue(null)
                        return
                      }

                      if (parseValue > MAX_VALUE) {
                        return
                      }

                      setRevenue(parseValue)

                    }
                  }
                  // max={300000000}
                  // min={1000000}
                  value={convertToPricingComma(revenue)}
                  // defaultValue={convertToPricingComma(revenue)}
                  placeholder={convertToPricingComma(MIN_VALUE)}
                  style={
                    {
                      width: inputWidth,
                    }
                  }
                  // disabled={!isEditable}
                  onKeyDown={
                    e => {
                      if (e.keyCode === 13) {

                        handleEditable()
                      }
                    }
                  }
                  autoFocus
                />
              )
            }

            <span
              ref={inputTextRef}
              style={
                {
                  position: isEditable ? 'absolute' : 'relative',
                  opacity: isEditable ? 0 : 1,
                  fontFamily: 'NotoSansCJKkr-Bold',
                  fontSize: '18px',
                  color: '#242424',
                  padding: 0,
                  border: 'none',
                  minWidth: '86px',
                  backgroundColor: 'transparent',
                  height: '27px',
                  // zIndex: -1
                }
              }
            >
              {convertToPricingComma(revenue)}

            </span>
            <Typography type={"Bold"} size={"18px"} color={typeof revenue === 'number' ? '#242424' : '#d4d4d4'}>{`원`}</Typography>
          </Flex>
          <DirectInputBtn
            text={isEditable ? '입력 완료' : '직접 입력'}
            isComplete={isEditable}
            value={revenue}
          // onClick={
          //   handleEditable
          // }
          />


        </Flex>
      </Flex>
    </div>

  )
}