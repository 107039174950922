import { Caption1, Flex, H6 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { InPlamListItem } from '@components/molecules'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import Pagination from 'react-js-pagination'

const InPlamList = ({
  style,
  offset,
  handlePagination,
  artistArticleList,
  artistArticleListCount,
  sortBy,
  handleChangeSortBy,
  artistInfo,
  handleMoveDetail,
}) => {
  return (
    <Flex type="column" style={style && style}>
      <Header>
        <H6 type="Bold">{`전체 ${artistArticleListCount || 0}`}</H6>
        <Select
          value={sortBy}
          onChange={e => handleChangeSortBy(e.target.value)}
        >
          <option value="createdAt">최신순</option>
          <option value="viewCount">조회순</option>
          <option value="commentCount">댓글순</option>
        </Select>
      </Header>
      <Br />
      {
        artistArticleList
        && artistArticleList.map(item => (
          <InPlamListItem
            item={item}
            key={item._id}
            artistInfo={artistInfo}
            onClick={handleMoveDetail}
          />
        ))
      }
      <Pagination
        activePage={offset + 1}
        itemsCountPerPage={10}
        totalItemsCount={artistArticleListCount || 0}
        pageRangeDisplayed={5}
        onChange={
          page => {
            handlePagination(page - 1)
          }
        }
        innerClass="pagination-ul"
        activeClass="current-page"
        itemClass="pagination-li"
        // hideFirstLastPages
        hideNavigation
        // prevPageText={false}
        // nextPageText={false}
        firstPageText={
          <PrevNextPage
            src={offset + 1 !== 1 ? first_page_img : no_first_page_img}
          />
        }
        lastPageText={
          <PrevNextPage
            src={
              offset + 1 !== Math.ceil(artistArticleListCount / 10)
                ? last_page_img
                : no_last_page_img
            }
          />
        }
      />
    </Flex>
  )
}

export default InPlamList

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 52px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 38px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 50px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
