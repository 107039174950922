import React from 'react'
import { kakao_login_btn_img } from '@images/'
import { Flex } from '@components/atoms'

const RoundBtn = ({ onClick, style, children }) => {
  return (
    <Flex
      onClick={onClick}
      style={
        {
          ...style,
          width: '230px',
          height: '48px',
          cursor: 'pointer',
          backgroundColor: '#242424',
          color: '#ffffff',
          borderRadius: 24,
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    >
      {children && children}
    </Flex>
  )
}

export default RoundBtn
