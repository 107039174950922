import { Network } from '.'

export default class ArtistNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getArtist(account) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/unique-name/${account}`,
        'get',
      )
      const artist = data && data['data'] && data['data']['artist']
      return artist || null
    }
    catch (error) {
      console.warn('ArtistNetwork getArtist error', error)
    }
  }

  async getArtistById(artistId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistId}`,
        'get',
      )
      const artist = data && data['data'] && data['data']['artist']
      return artist || null
    }
    catch (error) {
      console.warn('ArtistNetwork getArtistById error', error)
    }
  }

  async getSmartLinkList(artistObjectId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistObjectId}/smart-link`,
        'get',
        query,
      )
      const smartLinkList =
        data && data['data'] && data['data']['smartLinkList']
      return smartLinkList || []
    }
    catch (error) {
      console.warn('ArtistNetwork getSmartLinkList error', error)
    }
  }

  async getArtistYoutube(artistId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistId}/youtube`,
        'get',
        query,
      )
      const res = data && data['data'] && data['data']
      return res || null
    }
    catch (error) {
      console.warn('ArtistNetwork getArtistYoutube error', error)
    }
  }
}
