import { Flex, H4, Caption1 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { next_page_img } from '@images/index'

const MostArticleList = ({ style, title = '팬스팟', list }) => {
  const history = useHistory()

  return (
    <Flex type="column" style={style && style}>
      <Flex
        align="center"
        style={{ cursor: 'pointer', margin: '16px 0 0 0' }}
        onClick={() => history.push('/fanspot/total?offset=0&sortBy=createdAt')}
      >
        <H4 type="Bold" align="left">
          {title}
        </H4>
        <img
          src={next_page_img}
          style={{ width: 20, height: 20, marginLeft: '4px' }}
          alt="move_icon"
        />
      </Flex>
      <InPlamList>
        {list && list.length ? (
          list.map(item => (
            <InPlamListItem key={item._id}>
              {item.isNotice ? (
                <ArtistText
                  style={{
                    color: '#ea4653',
                    border: 'solid 1px #ea4653',
                    cursor: 'pointer',
                    borderRadius: 2,
                    fontFamily: 'NotoSansCJKkr-Medium',
                    padding: '2px 0',
                  }}
                  onClick={() =>
                    history.push(
                      `/fanspot/total/${item._id}?offset=0&sortBy=createdAt`,
                    )
                  }
                >
                  공지사항
                </ArtistText>
              ) : (
                <ArtistText
                  onClick={() =>
                    history.push(
                      `/fanspot/${item.uniqueName}?offset=0&sortBy=createdAt`,
                    )
                  }
                >
                  {item.artistName}
                </ArtistText>
              )}
              <TitleText
                onClick={() => {
                  if (item.isNotice) {
                    history.push(
                      `/fanspot/total/${item._id}?offset=0&sortBy=createdAt`,
                    )
                  } else {
                    history.push(
                      `/fanspot/${item.uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
                    )
                  }
                }}
              >
                {item.title}
              </TitleText>
            </InPlamListItem>
          ))
        ) : (
          <Flex>게시글이 없습니다</Flex>
        )}
      </InPlamList>
    </Flex>
  )
}

export default MostArticleList

const InPlamList = styled(Flex)`
  flex-direction: column;
  height: fit-content;
  max-height: 170px;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 34px;
  border-bottom: solid 1px ${colors.whitef4};
  align-items: center;
  box-sizing: border-box;
`

const ArtistText = styled(Caption1)`
  min-width: 107px;
  max-width: 107px;
  font-family: NotoSansCJKkr-Bold;
  /* text-align: left; */
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const TitleText = styled(Caption1)`
  width: 100%;
  font-family: NotoSansCJKkr-Regular;
  text-align: left;
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
