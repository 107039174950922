import React from 'react'
import { Flex, BodyWrapper, Typography } from '@components/atoms'

const NotFoundTemplate = () => {
  return (
    <BodyWrapper>
      <Flex
        type="column"
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography size="20px">Not Found 404 : </Typography>
        <Typography>찾을 수 없는 페이지입니다!</Typography>
      </Flex>
    </BodyWrapper>
  )
}

export default NotFoundTemplate
