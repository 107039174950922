import { TrackSnsListenDetailTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const TrackSnsListenDetailPage = () => {
  const params = useParams()

  const _id = params?._id
  const artistId = params?.artistId

  const { trendStore } = useStore()

  const fetchArtistTrackTrend = trendStore?.fetchArtistTrackTrend || (() => {})

  useEffect(() => {
    if (artistId && _id) {
      fetchArtistTrackTrend(artistId, _id, { periodDays: 28 })
    }
  }, [_id, artistId, fetchArtistTrackTrend])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <TrackSnsListenDetailTemplate />
}

export default observer(TrackSnsListenDetailPage)
