import { Caption1, Caption2, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const TableBox = styled.div`
  width: 100%;
  background-color: #ffffff;
`

const TrackYoutubeTableChart = ({ isFold = true }) => {
  const { trendStore } = useStore()

  const artistTrackTrend = trendStore?.artistTrackTrend

  const parseData = useMemo(() => {
    const sortedData = artistTrackTrend?.youtubeDailyList?.sort(
      (a, b) => b?.date - a?.date,
    )

    if (isFold) return sortedData?.slice(0, 5)
    return sortedData
  }, [isFold, artistTrackTrend?.youtubeDailyList])

  return (
    <TableBox>
      <Tr>
        <Th>날짜</Th>
        <Th>조회수</Th>
        <Th>좋아요</Th>
      </Tr>
      {parseData?.map(rowData => {
        const dateStr = `${rowData?.date || ''}`
        return (
          <Tr>
            <Td>
              <Caption1>
                {`${dateStr.slice(2, 4)}. ${dateStr.slice(
                  4,
                  6,
                )}. ${dateStr.slice(6, 8)}`}
              </Caption1>
            </Td>
            <Td>
              <Flex type="column" align="center">
                <Caption1>
                  {convertToPricingComma(
                    rowData?.listenerCountInfo?.youtube || 0,
                  )}
                </Caption1>
                <Flex align="center" style={{ gap: 4 }}>
                  {rowData?.listenerCountInfo?.increaseValue?.youtube > 0 ? (
                    <Up />
                  ) : rowData?.listenerCountInfo?.increaseValue?.youtube < 0 ? (
                    <Down />
                  ) : (
                    <></>
                  )}
                  <Caption2 color="#949494">
                    {rowData?.listenerCountInfo?.increaseValue?.youtube === 0
                      ? '-'
                      : convertToPricingComma(
                          Math.abs(
                            rowData?.listenerCountInfo?.increaseValue?.youtube,
                          ),
                        )}
                  </Caption2>
                </Flex>
              </Flex>
            </Td>
            <Td>
              <Flex type="column" align="center">
                <Caption1>
                  {convertToPricingComma(rowData?.likeCountInfo?.youtube || 0)}
                </Caption1>
                <Flex align="center" style={{ gap: 4 }}>
                  {rowData?.likeCountInfo?.increaseValue?.youtube > 0 ? (
                    <Up />
                  ) : rowData?.likeCountInfo?.increaseValue?.youtube < 0 ? (
                    <Down />
                  ) : (
                    <></>
                  )}
                  <Caption2 color="#949494">
                    {rowData?.likeCountInfo?.increaseValue?.youtube === 0
                      ? '-'
                      : convertToPricingComma(
                          Math.abs(
                            rowData?.likeCountInfo?.increaseValue?.youtube,
                          ),
                        )}
                  </Caption2>
                </Flex>
              </Flex>
            </Td>
          </Tr>
        )
      })}
    </TableBox>
  )
}

export default observer(TrackYoutubeTableChart)

const Tr = styled(Flex)`
  flex: 1;
`

const Th = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;

  color: #646464;
  font-size: 12px;
`

const Td = styled(Flex)`
  border-bottom: 1px solid #f0f0f0;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #66bc6a;

  margin-top: 2px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #ea4653;

  margin-top: 2px;
`
