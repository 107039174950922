import React from 'react'
import { TrackItem } from '@components/molecules'

const TrackList = ({ list }) => {
  return (
    <div>
      {list &&
        list.filter(elem => !!elem).map((item, index) => (
          <TrackItem
            item={item}
            index={index}
            key={index}
            artistName={item && item.releaseArtistName}
          />
        ))}
    </div>
  )
}

export default TrackList
