import { Network } from '.'

export default class StatsNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getArtistLike(artistId, periodType) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/like/insight/artist?artistId=${artistId}&periodType=${periodType}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getArtistLike error', error)
    }
  }

  async getTracksLike(artistId, periodType) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/like/insight/tracks?artistId=${artistId}&periodType=${periodType}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getTracksLike error', error)
    }
  }

  async getTrackLike(trackId, periodType) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/like/insight/track?trackId=${trackId}&periodType=${periodType}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatNetwork getTrackLike error', error)
    }
  }

  // async getSnsListLike(artistId, periodType) {
  //   try {
  //     const { data } = await this.network._axiosApi(
  //       `/sns/insight/artist?artistId=${artistId}&periodType=${periodType}`,
  //       'get',
  //     )
  //     return data || null
  //   } catch (error) {
  //     console.warn('StatNetwork getSnsListLike error', error)
  //   }
  // }

  // async getSnsLike(snsType, artistId, periodType) {
  //   try {
  //     const { data } = await this.network._axiosApiAuth(
  //       `/sns/insight/${snsType}?artistId=${artistId}&periodType=${periodType}`,
  //       'get',
  //     )
  //     return data || null
  //   } catch (error) {
  //     console.warn('StatNetwork getSnsLike error', error)
  //   }
  // }

  // 플램 차트 조회
  async getPlamChart(periodType) {
    try {
      const { data } = await this.network._axiosApi(
        `/artist/rank/chart?periodType=${periodType}`,
        'get',
      )
      return data.data || null
    } catch (error) {
      console.warn('StatsNetwork getPlamChart error', error)
    }
  }
}
