// import React from 'react'
// import { Flex, Caption1 } from '@components/atoms'
// import { Link } from 'react-router-dom'

// const Footer = () => {
//   return (
//     <Flex
//       type="column"
//       style={{
//         backgroundColor: '#242424',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: '116px',
//       }}
//     >
//       <Flex style={{ marginBottom: '10px' }}>
//         <Link to="/privacy/policy">
//           <Caption1 color="#949494" style={{ marginRight: '20px' }}>
//             Privacy Policy
//           </Caption1>
//         </Link>
//         <Link to="/privacy/termsOfUse">
//           <Caption1 color="#949494">Terms of Use</Caption1>
//         </Link>
//       </Flex>
//       <Caption1 color="#949494">All rights reserved 2019.naivy Inc.</Caption1>
//     </Flex>
//   )
// }

// export default Footer
import React from 'react'
import { Flex, Caption1 } from '@components/atoms'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <Flex
      type="column"
      style={
        {
          backgroundColor: '#242424',
          alignItems: 'center',
          justifyContent: 'center',
          // height: '170px',
          height: '116px',
        }
      }
    >
      <Flex style={{ marginBottom: '20px' }}>
        <Link to="/privacy/policy">
          <Caption1 color="#949494" style={{ marginRight: '20px' }}>
            Privacy Policy
          </Caption1>
        </Link>
        <Link to="/privacy/termsOfUse">
          <Caption1 color="#949494">Terms of Use</Caption1>
        </Link>
      </Flex>
      {/* <Caption1 color="#949494">
        회사명 : (주)나이비 | 대표이사 : 김동현
      </Caption1>
      <Caption1 color="#949494">사업자등록번호 : 724-88-00593</Caption1>
      <Caption1 color="#949494">
        주소 : 서울특별시 성북구 보문동5가 173
      </Caption1>
      <Caption1 color="#949494">대표번호 : 1833-6845</Caption1>
      <Caption1 color="#949494">E-mail : info@plam.in</Caption1> */}
      <Caption1 color="#949494">All rights reserved 2019.naivy Inc.</Caption1>
    </Flex>
  )
}

export default Footer
