import React from 'react'
import { kakao_login_btn_img } from '@images/'

const KakaoLoginBtn = ({ kakaoLogin, style }) => {
  return (
    <img
      onClick={() => kakaoLogin()}
      src={kakao_login_btn_img}
      alt="kakao_login_img"
      style={{ width: '280px', height: '48px', cursor: 'pointer', ...style }}
    />
  )
}

export default KakaoLoginBtn
