import React, { useEffect, useCallback, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { LoginArtistTemplate, LoginTemplate } from '@components/templates'
import {  useLocation, useParams } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

interface Props {
  authStore: AuthStore;
  match: Any;
  history: Any;
  last: Any;
}

const LoginPage = ({ authStore, history, match, last }: Props) => {
  const location = useLocation()
  const matchParams = useParams()

  const currentUser = authStore.currentUser || null
  const jsonWebToken = authStore.jsonWebToken || null
  const duplicateUser = authStore.duplicateUser || null

  const socialLogin = authStore.socialLogin || (() => {})
  const socialAuth = authStore.socialAuth || (() => {})
  const login = authStore.login || (() => {})

  useEffect(() => {
    if (!currentUser && location.search !== null) {
      const asyncSocialLogin = async () => {
        if (matchParams.platform === 'facebook') {
          const res = await socialAuth('facebook', location.search)
          if (res) history.push(localStorage.getItem('plam_current_page'))
        }
        else if (matchParams.platform === 'kakao') {
          const res = await socialAuth('kakao', location.search)
          if (res) history.push(localStorage.getItem('plam_current_page'))
        }
        else if (matchParams.platform === 'google') {
          const res = await socialAuth('google', location.search)
          if (res) history.push(localStorage.getItem('plam_current_page'))
        }
        else if (matchParams.platform === 'apple') {
          const res = await socialAuth('apple', location.search)
          if (res) history.push(localStorage.getItem('plam_current_page'))
        }
      }
      asyncSocialLogin()
    }
  }, [location.search])

  if (location.search !== null && matchParams.platform === 'artist') {
    return (
      <LoginArtistTemplate
        currentUser={currentUser}
        jsonWebToken={jsonWebToken}
        match={match}
        location={location}
        login={login}
        history={history}
        duplicateUser={duplicateUser}
      />
    )
  }
  return (
    <LoginTemplate
      socialLogin={socialLogin}
      currentUser={currentUser}
      jsonWebToken={jsonWebToken}
      match={match}
      location={location}
      login={login}
      history={history}
      duplicateUser={duplicateUser}
    />
  )
}

export default inject('authStore')(observer(LoginPage))
