import React, { useRef } from 'react'
import styled from 'styled-components'
import { STORAGE_URL, ItemTypes } from '@consts/'
import { remove_item_img } from '@images/'
import { useDrag, useDrop } from 'react-dnd'

const UploadedImage = styled.li`
  display: flex;
  width: 96px;
  height: 96px;
  position: relative;

  img:first-child {
    width: 96px;
    height: 96px;
  }

  img:last-child {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -12px;
    top: -12px;
  }
`

const FileItem = ({
  file,
  index,
  setDetailCover,
  removeImageIndex,
  moveImage,
}) => {
  const imageRef = useRef()

  // drop
  const [, drop] = useDrop({
    accept: ItemTypes.FILEITEM,
    hover(item, monitor) {
      if (!imageRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      // 스크린 상에서 영역 지정
      const hoverBoundingRect =
        imageRef.current && imageRef.current.getBoundingClientRect()

      // 수직 중앙지점
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2

      // 마우스 위치
      const clientOffset = monitor.getClientOffset()

      // 오른쪽 끝까지 픽셀값
      const hoverClientX = clientOffset.x - hoverBoundingRect.right

      // 마우스가 아이템 너비의 절반지점 넘어가야 위치 바꿈
      // Dragging right
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }

      // Dragging left
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      moveImage(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.FILEITEM, index, id: file },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.5 : 1

  drag(drop(imageRef))

  return (
    <div ref={imageRef} style={{ cursor: 'move', opacity }}>
      <UploadedImage>
        <img
          src={STORAGE_URL + file}
          alt="image_url"
          onClick={() => setDetailCover(STORAGE_URL + file)}
        />
        <img
          src={remove_item_img}
          alt="remove"
          onClick={() => removeImageIndex(file)}
        />
      </UploadedImage>
    </div>
  )
}

export default FileItem
