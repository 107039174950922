import React from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { Typography } from '../../atoms'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const AnimatioWrapper = styled.div`
  animation-name: ${boxFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 100;
`

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  height: 50px;
  box-shadow: 0 0 6px 0 rgba(234, 70, 83, 0.78);
  left: 20px;
  top: 70vh;
  background: #ea4653;
  z-index: 1000;
  border-radius: 6px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
`

const NoYoutubePopup = () => {
  return (
    <AnimatioWrapper>
      <PopupInner>
        <Typography type="Medium" size="12px">
        재생 가능한 유튜브 동영상이 없습니다.
        </Typography>
      </PopupInner>
    </AnimatioWrapper>
  )
}

export default NoYoutubePopup
