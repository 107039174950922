import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { AlbumModel, TrackModel } from './models'

export default class AlbumStore {
  @observable releaseAlbumList
  @observable albumDetail

  @observable trackDetail

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.releaseAlbumList = []
    this.albumDetail = null
    this.trackDetail = null
  }

  @action.bound
  async initClient() {}

  @action.bound
  async fetchReleaseAlbumList(artistId) {
    return this.store.useLoading(async () => {
      if (!artistId) return

      const fetchedArtist = await this.network.artistNetwork.getArtist(artistId)
      if (!fetchedArtist || !fetchedArtist.releaseAlbumList) return

      const releaseAlbumList = await Promise.all(
        fetchedArtist.releaseAlbumList.map((releaseAlbum) =>
          this.network.albumNetwork.getAlbum(releaseAlbum._id),
        ),
      )

      // 발매일순 정렬
      releaseAlbumList.sort((a, b) =>
        a.releasedAt && b.releasedAt
          ? new Date(b.releasedAt).getTime() - new Date(a.releasedAt).getTime()
          : 0,
      )

      this.releaseAlbumList = releaseAlbumList
        .filter((releaseAlbum) => !!releaseAlbum)
        .map((releaseAlbum) => new AlbumModel(this.store, releaseAlbum))
    })
  }

  @action.bound
  async fetchAlbumDetail(albumId) {
    return this.store.useLoading(async () => {
      if (!albumId) {
        this.albumDetail = null
        return
      }

      const fetchedAlbum = await this.network.albumNetwork.getAlbum(albumId)
      if (!fetchedAlbum) return

      // if (
      //   fetchedAlbum &&
      //   fetchedAlbum.trackList &&
      //   fetchedAlbum.trackList.length > 0
      // ) {
      //   const trackList = await Promise.all(
      //     fetchedAlbum.trackList
      //       .filter((track) => !!track)
      //       .map((track) => this.network.trackNetwork.getTrack(track._id)),
      //   )

      //   trackList
      //     .sort((a, b) => !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no)) ? parseInt(a.no) - parseInt(b.no) : 0)

      //   fetchedAlbum.trackList = trackList
      //     .filter((track) => !!track)
      //     .map((track) => new TrackModel(this.store, track))
      // }
      if (
        fetchedAlbum &&
        fetchedAlbum.trackList &&
        fetchedAlbum.trackList.length > 0
      ) {
        fetchedAlbum.trackList = fetchedAlbum.trackList
          .filter((track) => !!track)
          .sort((a, b) =>
            !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no))
              ? parseInt(a.no) - parseInt(b.no)
              : 0,
          )
          .map((track) => new TrackModel(this.store, track))
      }

      this.albumDetail = new AlbumModel(this.store, fetchedAlbum)
    })
  }

  @action.bound
  async fetchTrackDetail(trackId) {
    return this.store.useLoading(async () => {
      if (!trackId) {
        this.trackDetail = null
        return
      }

      const fetchedTrack = await this.network.trackNetwork.getTrack(trackId)
      if (!fetchedTrack) return

      if (
        fetchedTrack &&
        fetchedTrack.trackList &&
        fetchedTrack.trackList.length > 0
      ) {
        const trackList = await Promise.all(
          fetchedTrack.trackList
            .filter((elem) => !!elem)
            .map((elem) => this.network.trackNetwork.getTrack(elem._id)),
        )
        fetchedTrack.trackList = trackList
          .filter((elem) => !!elem)
          .map((elem) => new TrackModel(this.store, elem))
      }

      this.trackDetail = new TrackModel(this.store, fetchedTrack)
    })
  }
}
