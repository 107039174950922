import React, { useState } from 'react'
import { apple_login_btn_img } from '@images/'

const AppleLoginBtn = ({ appleLogin, style }) => {
  return (
    <img
      onClick={() => appleLogin()}
      src={apple_login_btn_img}
      alt="apple_login_btn_img"
      style={
        {
          width: '280px',
          height: '48px',
          cursor: 'pointer',
          ...style,
        }
      }
    />
  )
}

export default AppleLoginBtn
