import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamTemplate } from '@components/templates'

interface InPlamPageProps {
  inplamStore: InplamStore;
  authStore: AuthStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamPage = ({
  match,
  location,
  history,
  authStore,
  inplamStore,
  articleStore,
}: InPlamPageProps) => {
  const [searchOffset, setSearchOffset] = useState(0) // 검색어 offset

  const [isFavorite, setIsFavorite] = useState(1)
  const [offset, setOffset] = useState(0)
  const [sortBy, setSortBy] = useState('newest')
  const [more, setMore] = useState(true) // 더보기 유무

  /* store */
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})
  const fetchSearchInplamList = inplamStore.fetchSearchInplamList || (() => {})
  const fetchlatestActicleList =
    articleStore.fetchlatestActicleList || (() => {})
  const fetchPopularTotalArticleList =
    articleStore.fetchPopularTotalArticleList || (() => {})

  const currentUser = authStore.currentUser || null
  const totalInplamList = inplamStore.totalInplamList || null
  const searchInplamList = inplamStore.searchInplamList || null
  const latestActicleList = articleStore.latestActicleList || null
  const popularTotalArticleList = articleStore.popularTotalArticleList || null
  /* store end */

  const handleChangeSortBy = _sortBy => {
    setSortBy(_sortBy)
    setOffset(0)
  }

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])

  const fetchTotalList = async ({
    __limit = 20,
    __isFavorite = 1,
    __offset = 0,
  }) => {
    const result = await fetchTotalInplamList({
      offset: __offset,
      limit: __limit,
      isFavorite: __isFavorite,
    })
    if (__isFavorite === 1 && result?.length < 20) {
      setIsFavorite(0)
      const result2 = await fetchTotalInplamList({
        offset: 0,
        limit: 20,
        isFavorite: 0,
      })
      setOffset(1)

      if (result?.length + result2?.length >= 20) {
        setMore(true)
      }
      else {
        setMore(false)
      }
    }
    else if (result?.length >= 20) {
      setMore(true)
    }
    else {
      setMore(false)
    }
  }

  useEffect(() => {
    fetchTotalList({ __limit: 20, __isFavorite: 1, __offset: 0 })
    setOffset(1)
  }, [])

  useEffect(() => {
    fetchlatestActicleList()
    fetchPopularTotalArticleList({ limit: 5 })
  }, [])

  return (
    <InPlamTemplate
      totalInplamList={totalInplamList}
      setOffset={setOffset}
      setIsFavorite={setIsFavorite}
      isFavorite={isFavorite}
      fetchTotalList={fetchTotalList}
      offset={offset}
      latestActicleList={latestActicleList}
      popularTotalArticleList={popularTotalArticleList}
      searchOffset={searchOffset}
      setSearchOffset={setSearchOffset}
      fetchSearchInplamList={fetchSearchInplamList}
      searchInplamList={searchInplamList}
      sortBy={sortBy}
      handleChangeSortBy={handleChangeSortBy}
      history={history}
      more={more}
    />
  )
}

export default inject(
  'authStore',
  'inplamStore',
  'articleStore',
)(observer(InPlamPage))
