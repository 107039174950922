import React, { useEffect, useState } from 'react'
import { BodyWrapper, Flex, H6 } from '@components/atoms'
import {
  plam_logo_img,
  goback_img,
  circle_facebook_img,
  circle_google_img,
  circle_kakao_img,
} from '@images/'
import {
  KakaoLoginBtn,
  FacebookLoginBtn,
  GoogleLoginBtn,
  ArtistLoginBtn,
  AppleLoginBtn,
} from '@components/molecules'
import { Redirect, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useScript, appleAuthHelpers } from 'react-apple-signin-auth';


const LoginTemplate = ({
  socialLogin,
  currentUser,
  history,
  jsonWebToken,
  location,
  match,
  login,
  redirectPath,
  duplicateUser,
}) => {
  // useEffect(() => {
  //   if (currentUser !== null) {
  //     history.push(localStorage.getItem('plam_current_page'))
  //   }
  // }, [currentUser])
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  return (
    <>
      <BodyWrapper
        style={
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }
        }
      >
        <img
          src={goback_img}
          alt="go_back"
          style={
            {
              width: '24px',
              height: '24px',
              alignSelf: 'end',
              cursor: 'pointer',
            }
          }
          onClick={
            () =>
              history.push(localStorage.getItem('plam_current_page'))
          }
        />
        <img
          src={plam_logo_img}
          alt="logo_img"
          style={{ width: '167px', height: '48px', margin: '53px 0 50px 0' }}
        />
        <Flex type="column">
          <GoogleLoginBtn
            googleLogin={() => socialLogin('google')}

          />
          <KakaoLoginBtn kakaoLogin={() => socialLogin('kakao')} style={{ margin: '20px 0' }} />
          <AppleLoginBtn appleLogin={() => socialLogin('apple')} />
          <ArtistLoginBtn
            onClick={() => history.push('/login/artist')}
            style={{ marginTop: 20 }}
          />
        </Flex>
        {
          duplicateUser && (
            <>
              <hr
                style={
                  {
                    margin: '50px 0 30px 0',
                    width: '100%',
                    border: '1px solid #f4f4f4',
                  }
                }
              />
              <Flex type="column">
                <H6>이미 가입된 이메일 정보가 있습니다.</H6>
                <Flex
                  style={
                    {
                      marginTop: '16px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '24px',
                    }
                  }
                >
                  <img
                    style={{ width: '24px', height: '24px', marginRight: '8px' }}
                    src={
                      duplicateUser.socialType == 'facebook'
                        ? circle_facebook_img
                        : duplicateUser.socialType == 'google'
                          ? circle_google_img
                          : circle_kakao_img
                    }
                  />
                  <H6>{duplicateUser.email}</H6>
                </Flex>
              </Flex>
            </>
          )
        }
      </BodyWrapper>
    </>
  )
}

export default LoginTemplate
