import { Caption1, Caption2, Flex, H4 } from '@components/atoms'
import React from 'react'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Observer } from 'mobx-react'
import { LikeAristItemSkeleton } from '@components/molecules'

const LikeArtistList = ({ list, isLoading = false }) => {
  return (
    <Flex type="column" style={{ gap: 16, paddingBottom: 31 }}>
      <Flex type="column" style={{ marginLeft: -20 }}>
        <div
          style={
            {
              width: 'calc(100vw + 20px)',
              height: 1,
              backgroundColor: '#eaeaea',
            }
          }
        />
        <div
          style={
            {
              width: 'calc(100vw + 20px)',
              height: 8,
              backgroundColor: '#fafafa',
            }
          }
        />
      </Flex>
      <H4 type="Bold" align="left" style={{ height: '29px' }}>
        나의 아티스트
      </H4>
      <Observer>
        {
          () => {
            return (
              <List>
                {
                  isLoading
                    ? Array(8)
                      .fill(1)
                      ?.map(item => <LikeAristItemSkeleton />)
                    : list?.map(item => (
                      <LikeAristItem item={item} key={item._id} />
                    ))
                }
              </List>
            )
          }
        }
      </Observer>
    </Flex>
  )
}

export default LikeArtistList

const List = styled(Flex)`
  overflow-x: scroll;
  gap: 10px;
  margin-left: -20px;
  padding-left: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Name = styled(Caption1)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const LikeAristItem = ({ item }) => {
  return (
    <Link to={`/artist/${item?.uniqueName}`}>
      <Flex
        type="column"
        align="center"
        style={{ gap: 5, width: 64, position: 'relative', paddingTop: '2px' }}
      >
        {
item?.isReleasedNewTrack && (
            <Caption2
              style={
                {
                  position: 'absolute',
                  top: 0,
                  alignSelf: 'center',
                  backgroundColor: '#EA4653',
                  borderRadius: '4px',
                  padding: '2px 6px 3px 6px',
                  zIndex: 100,
                }
              }
              type={'Regular'}
              color={'#fff'}
            >
            New Music
            </Caption2>
          )
        }
        <Flex
          style={
            {
              width: 64,
              height: 64,
              borderRadius: '50%',
              overflow: 'hidden',
              position: 'relative',
            }
          }
        >
          {
item?.isReleasedNewTrack && (
              <div
                style={
                  {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'linear-gradient(to bottom, #FFA7AE, #EA4653)',
                    padding: '3px', // 안쪽 stroke-width
                    boxSizing: 'border-box',
                    WebkitMask:
                  'radial-gradient(circle, transparent 61%, black 62%)', // 원형 마스크로 안쪽에만 테두리 효과
                    mask: 'radial-gradient(circle, transparent 61%, black 62%)',
                    zIndex: 1,
                  }
                }
              />
            )
          }
          <img
            src={STORAGE_URL + item?.image64Path}
            alt="artist"
            width={64}
            height={64}
          />
        </Flex>
        <Name>{item?.artistName || item?.uniqueName}</Name>
      </Flex>
    </Link>
  )
}
