import { Caption1, Flex } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import { STORAGE_URL } from '@consts'
import styled, { css } from 'styled-components'
import { favorite_img } from '@images/index'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const InplamArtistListItemSkeleton = ({ item, onClick = () => {} }) => {

  const [width, setWidth] = useState(25)

  useEffect(() => {
    const randomWidth =  Math.floor(Math.random() * (100 - 25 + 1)) + 25;

    setWidth(randomWidth)
  },[])


  return (
    <Box
      isOpen={item?.isInplamOpen}
      isFavorite={item?.isInplamFavorite}
      onClick={onClick}
    >
      <Skeleton count={1} width={'24px'} height={'24px'} borderRadius={'50%'} />
      <Caption1 style={{ wordBreak: 'nowrap' }}>
        <Skeleton count={1} width={width} />
      </Caption1>
    </Box>
  )
}

export default InplamArtistListItemSkeleton

const Box = styled(Flex)`
  gap: 6px;
  width: fit-content;
  height: 32px;
  padding: 7px 12px 7px 4px;
  background-color: #f4f4f4;
  border: solid 1px #d4d4d4;
  color: #242424;
  line-height: 1;

  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;

  justify-content: center;
  align-items: center;
`
