import React from 'react'
import { Flex, Caption1, H6 } from '@components/atoms'
import { getIsWebApp, sendWTAPostMessage } from '@utils/postMessage'
import { point_img } from '@images/index'
import Skeleton from 'react-loading-skeleton'
import Image from './Image'
import 'react-loading-skeleton/dist/skeleton.css'


const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
  lineHeight: 1,
  marginTop: '2px'
}

const RewardItemSkeleton = ({ itemStyle }) => {
  return (
    <Flex
      type="column"
      style={{ ...itemStyle, width: '150px', lineHeight: 1 }}
    >
      <Skeleton count={1} width={'150px'} height={'150px'} borderRadius={'10px'} />
      <H6 align="left" style={ellipsisStyle}>
        <Skeleton count={1} width={120} height={20} />
      </H6>
      <Caption1 align="left" color="#949494" style={ellipsisStyle}>
        <Skeleton count={1} width={50} height={16} />
      </Caption1>
      <H6 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2px' }} type={'Bold'} align="left">
        <Image
          src={point_img}
          size={['16px', '16px']}
          style={{ marginRight: '2px' }}
        />
        <Skeleton count={1} width={70} height={20} />
      </H6>
    </Flex>
  )
}

export default RewardItemSkeleton
