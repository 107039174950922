import React, { useEffect, useState } from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { H6, Flex } from '@components/atoms'
import {
  KakaoLoginBtn,
  FacebookLoginBtn,
  GoogleLoginBtn,
  AppleLoginBtn,
  ArtistLoginBtn,
} from '@components/molecules'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 1; }
`

const AnimationPopup = styled.div`
  animation-name: ${boxFade};
  animation-duration: 1s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;

  position: absolute;
  z-index: 9999;
  /* top: 0; */
  top: ${props => props.scrollHeight - 90}px;
  left: 0;
  width: 100%;
  height: 120vh;
  backdrop-filter: blur(4.1px);
  background-color: rgba(36, 36, 36, 0.7);
`

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  height: 366px;
  left: 20px;
  /* top: 116px; */
  top: 20%;
  background: white;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoginPopup = ({ handleClose }) => {
  const { authStore } = useStore()
  const socialLogin = authStore.socialLogin || (() => {})
  const history = useHistory()

  const _facebookLogin = async () => {
    const user = await socialLogin('facebook')
    if (user && user.socialType) {
      alert(`${user.socialType}로 가입된 계정이 있습니다.`)
    }
    else {
      handleClose()
    }
  }

  const _googleLogin = async () => {
    const user = await socialLogin('google')
    if (user && user.socialType) {
      alert(`${user.socialType}로 가입된 계정이 있습니다.`)
    }
    else {
      handleClose()
    }
  }

  const _kakaoLogin = async () => {
    const user = await socialLogin('kakao')
    if (user && user.socialType) {
      alert(`${user.socialType}로 가입된 계정이 있습니다.`)
    }
    else {
      handleClose()
    }
  }

  const _appleLogin = async () => {
    const user = await socialLogin('apple')
    if (user && user.socialType) {
      alert(`${user.socialType}로 가입된 계정이 있습니다.`)
    }
    else {
      handleClose()
    }
  }

  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    // <AnimationPopup>
    <AnimationPopup scrollHeight={scrollPosition}>
      <PopupInner>
        <Flex style={{ marginBottom: '30px' }}>
          <H6 type="Bold">로그인</H6>
          <H6>이 필요한 기능입니다.</H6>
        </Flex>
        {/* <FacebookLoginBtn facebookLogin={_facebookLogin} /> */}
        <GoogleLoginBtn
          googleLogin={_googleLogin}

        />
        <KakaoLoginBtn kakaoLogin={_kakaoLogin} style={{ margin: '20px 0' }} />
        <AppleLoginBtn appleLogin={_appleLogin} />
        <ArtistLoginBtn
          onClick={
            () => {
              history.push('/login/artist')
              handleClose()
            }
          }
          style={{ marginTop: 20 }}
        />
        <H6
          onClick={handleClose}
          color="#ffffff"
          style={
            {
              textDecoration: 'underline',
              cursor: 'pointer',
              position: 'absolute',
              top: '396px',
            }
          }
        >
          취소
        </H6>
      </PopupInner>
    </AnimationPopup>
  )
}

export default LoginPopup
