import React from 'react'
import { Flex } from '@components/atoms'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import SnsFollowerCard from '../Card/SnsFollowerCard'

const ArtistSnsFollowerList = ({
  uniqueName,
  followersInfo,
  listenerInfo,
  isDisplayInstagramData,
  isDisplayTwitterData,
  isDisplayYoutubeData,
  isDisplaySoundcloudData,
  artistFollowerInfo,
  twitterUrl,
  instagramUrl,
  soundcloudUrl,
}) => {
  const history = useHistory()

  return (
    <FollowerBox>
      <SnsFollowerCard
        snsType="youtube"
        data={listenerInfo?.today}
        periodData={listenerInfo?.today?.increaseValue}
        unit="명"
        onClick={() => history.push(`/artist/${uniqueName}/youtube`)}
        isListen
      />
      <SnsFollowerCard
        snsType="melon"
        data={listenerInfo?.today}
        periodData={listenerInfo?.today?.increaseValue}
        unit="명"
        onClick={() => history.push(`/artist/${uniqueName}/melon`)}
        isListen
      />
      {isDisplayInstagramData && (
        <SnsFollowerCard
          snsType="instagram"
          data={artistFollowerInfo.currentFollowerCountDetail}
          periodData={artistFollowerInfo.increaseValue}
          onClick={() => window.open(instagramUrl)}
          unit="명"
        />
      )}
      {isDisplayTwitterData && (
        <SnsFollowerCard
          snsType="twitter"
          data={artistFollowerInfo.currentFollowerCountDetail}
          periodData={artistFollowerInfo.increaseValue}
          onClick={() => window.open(twitterUrl)}
          unit="명"
        />
      )}
      {isDisplaySoundcloudData && (
        <SnsFollowerCard
          snsType="soundcloud"
          data={artistFollowerInfo.currentFollowerCountDetail}
          periodData={artistFollowerInfo.increaseValue}
          onClick={() => window.open(soundcloudUrl)}
          unit="명"
        />
      )}
    </FollowerBox>
  )
}

export default ArtistSnsFollowerList

const FollowerBox = styled(Flex)`
  width: 100%;
  margin-left: -16px;
  padding-left: 16px;
  padding-bottom: 16px;
  margin-bottom: 15px;
  overflow-x: auto;

  gap: 12px;
`
