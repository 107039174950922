import React from 'react'
import { Flex, H6, Caption1 } from '@components/atoms'
import { Link } from 'react-router-dom'
import { colors } from '@colors/'
import { default_profile_img } from '@images'
import { handleArtistImgError } from '@utils/handler'
import styled, { css } from 'styled-components'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const SearchArtistInplamItem = ({ item, history }) => {
  const handleMove = () => {
    if (item.isInplamOpen) {
      history.push(`/fanspot/${item.uniqueName}`)
    } else {
      history.push(`/artist/${item.uniqueName}`)
    }
  }

  return (
    <ItemBox onClick={handleMove} isInplamOpen={item?.isInplamOpen}>
      <Flex style={{ width: '100%', height: '56px' }}>
        <Image
          src={item?.image128Path ? item?.image128Uri : default_profile_img}
          size={['56px', '56px']}
          style={{
            marginRight: '12px',
            borderRadius: '28px',
            border: `solid 0.5px ${colors.inActive}`,
          }}
          onError={e => handleArtistImgError(e)}
        />
        <Flex
          type="column"
          style={{ flex: 1, height: '56px', justifyContent: 'center' }}
        >
          <H6
            align="left"
            style={ellipsisStyle}
            color={!item?.isInplamOpen && '#d4d4d4'}
          >
            {item.name}
          </H6>
        </Flex>
      </Flex>
      <Flex
        justify="flex-end"
        align="center"
        style={{ width: '100%', maxWidth: 90, minWidth: 90 }}
      >
        {item?.isInplamOpen ? (
          <Caption1 color={colors.brown_grey}>팬스팟</Caption1>
        ) : (
          <Caption1 color="#1982df">팬스팟 오픈 요청</Caption1>
        )}
      </Flex>
    </ItemBox>
  )
}

export default SearchArtistInplamItem

const ItemBox = styled(Flex)`
  width: 100%;
  height: 72px;
  align-items: center;

  ${({ isInplamOpen }) =>
    !isInplamOpen &&
    css`
      background-color: #fafafa;
      margin-left: -20px;
      padding: 0px 20px !important;
    `}
`
