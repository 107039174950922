import { computed, observable } from 'mobx'
import { STORAGE_URL } from '@consts'

export default class PopupModel {
  @observable _id
  @observable title

  @observable createdAt
  @observable updatedAt
  @observable startedAt
  @observable endedAt

  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path
  @observable imageFilename

  @observable link

  @computed get popupImageUrl() {
    return this.imageOriginalPath ? STORAGE_URL + this.imageOriginalPath : ''
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.title = props.title
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.startedAt = props.startedAt
      this.endedAt = props.endedAt

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path
      this.imageFilename = props.imageFilename

      this.link = props.link
    }
  }
}
