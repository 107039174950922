import React, { useState, useEffect, useCallback } from 'react'
// antd
import styled from 'styled-components'
import { Flex } from '@components/atoms'
import { SERVER_URL } from '@consts'
import Files from 'react-butterfiles'
import { colors } from '@colors/'
import { upload_photo_img } from '@images/'
import { useStore } from '@utils/hooks'
import ReactLoading from 'react-loading'
import { DetailCoverImage, FileItem } from '@components/molecules'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend'

import ConfirmPopup from '../Popup/ConfirmPopup'

// for web : HTML5
// for mobile : Touch
// use multi Backend
const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true }, // Note that you can call your backends with options
      preview: true,
      transition: TouchTransition,
    },
  ],
}

const UploadPhotoInfo = ({
  setImageList,
  imageList,
  isUploading,
  setIsUploading,
}) => {
  const { authStore } = useStore()

  const {
    isShowing: errorShowing,
    toggle: errorToggle,
  } = ConfirmPopup.usePopup()

  const [errorMsg, setErrorMsg] = useState('')
  const [detailCover, setDetailCover] = useState(null)
  // const [result, setResult] = useState(null)
  // const [isUploading, setIsUploading] = useState(false)
  // const [percent, setPercent] = useState(0)

  // 이미지 제거
  const removeImageIndex = selectedImage => {
    setImageList(imageList.filter(image => image !== selectedImage))
  }

  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param selectedFile
   * @returns {Promise<any>}
   */
  const getPresignedPostData = selectedFile => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      // Set the proper URL here.
      const url = `${SERVER_URL}/file`

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', `jwt ${authStore.jsonWebToken}`)
      xhr.send(
        JSON.stringify({
          name: selectedFile.name,
          type: selectedFile.type,
          isArticle: true,
        }),
      )
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText))
      }
    })
  }

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   * @param presignedPostData
   * @param file
   * @returns {Promise<any>}
   */
  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        // console.log(key, ' : ', presignedPostData.fields[key])
        formData.append(key, presignedPostData.fields[key])
      })
      // Actual file has to be appended last.
      formData.append('file', file)
      const xhr = new XMLHttpRequest()
      // xhr.upload.addEventListener(
      //   'progress',
      //   function(event) {
      //     if (event.lengthComputable) {
      //       setPercent(Math.floor((100 * event.loaded) / event.total))
      //     }
      //   },
      //   false,
      // )

      xhr.open('POST', presignedPostData.url, true)
      xhr.send(formData)
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText)
      }
    })
  }

  const moveImage = useCallback(
    (dragIndex, hoverIndex) => {
      const dragImage = imageList[dragIndex]

      setImageList(
        update(imageList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragImage],
          ],
        }),
      )
    },
    [imageList],
  )

  return (
    <>
      <Files
        multiple
        maxSize="10mb"
        multipleMaxSize="200mb"
        multipleMaxCount={9}
        // accept={'*'}
        accept={'image/*'}
        // accept={
        //   [
        //     'image/jpeg',
        //     'image/png',
        //     'image/jpg',
        //     'image/gif',
        //     'image/webp',
        //   ]
        // }
        onSuccess={
          // async files => {
          //   setIsUploading(true)
          //   let tmpList = []
          //   for (let i = 0; i <= files.length - 1; i++) {
          //     const selectedFile = files[i]
          //     // async (files[i] : selectedFile) => {
          //     setImageList(false)
          //     // Step 1 - get pre-signed POST data.
          //     const { data: presignedPostData } = await getPresignedPostData(
          //       selectedFile,
          //     )

          //     // Step 2 - upload the file to S3.
          //     try {
          //       const { file } = selectedFile.src
          //       await uploadFileToS3(presignedPostData, file)
          //       tmpList = tmpList.concat(`/${presignedPostData.fields.key}`)
          //     // alert('업로드 성공!')
          //     }
          //     catch (e) {
          //       console.log('An error occurred!', e.message)
          //       alert('업로드 실패.')
          //     }
          //   }
          //   setImageList(tmpList)
          //   setIsUploading(false)
          // }
          files => {
            setIsUploading(true)
            let tmpList = []

            files.map(async i => {
              const selectedFile = i
              // async (files[i] : selectedFile) => {
              // Step 1 - get pre-signed POST data.
              const { data: presignedPostData } = await getPresignedPostData(
                selectedFile,
              )

              // Step 2 - upload the file to S3.
              try {
                const { file } = selectedFile.src
                await uploadFileToS3(presignedPostData, file)
                tmpList = tmpList.concat(`/${presignedPostData.fields.key}`)
                // alert('업로드 성공!')
                setImageList(
                  update(imageList, {
                    $push: tmpList,
                  }),
                )
                setIsUploading(false)
                // console.log('loading...', file)
                // console.log('finish1?', isUploading)
              }
              catch (e) {
                console.log('An error occurred!', e.message)
                alert('업로드 실패! 확장자를 확인해주세요.')
              }
              console.log('map finish', isUploading)
            })
            console.log('finish?', isUploading)
          }
        }
        // onError={errors => setResult(errors)}
        onError={
          errors => {
          // setResult(errors)
            errors.map(error => {
              if (error.type === 'maxSizeExceeded') {
                setErrorMsg('이미지 용량이 초과되었습니다')
                return errorToggle()
              }
              if (error.type === 'multipleMaxCountExceeded') {
                setErrorMsg('이미지 첨부는 9장까지 가능합니다')
                return errorToggle()
              }
              return alert(error.type)
            })
          }
        }
      >
        {
          ({ browseFiles }) => (
            <>
              <UploadBtn onClick={browseFiles}>
                <img
                  src={upload_photo_img}
                  style={{ width: 32, height: 32 }}
                  alt="upload_img"
                />
              </UploadBtn>
              <Br />
              {
                isUploading ? (
                  <FileList style={{ justifyContent: 'center' }}>
                    <ReactLoading
                      type="spin"
                      color="black"
                      style={
                        {
                          width: '50px',
                          height: '50px',
                          marginTop: 50,
                        }
                      }
                    />
                  </FileList>
                ) : imageList ? (
                  <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                    <FileList>
                      {
                        imageList.map((file, index) => (
                          <FileItem
                            key={file}
                            id={file}
                            file={file}
                            index={index}
                            setDetailCover={setDetailCover}
                            removeImageIndex={removeImageIndex}
                            moveImage={moveImage}
                          />
                        ))
                      }
                      {/* {
                      result
                  && result.map(error =>
                    alert(`${error.file.name}의 ${error.type}`),
                  )
                    } */}
                    </FileList>
                  </DndProvider>
                ) : (
                  false
                )
              }
            </>
          )
        }
      </Files>
      <ConfirmPopup.View
        isShowing={errorShowing}
        toggle={errorToggle}
        confirmText="닫기"
        title={errorMsg}
      />
      {
        detailCover && (
          <DetailCoverImage
            setIsDetailCover={setDetailCover}
            imgSrc={detailCover}
          />
        )
      }
    </>
  )
}

export default UploadPhotoInfo

const UploadBtn = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: 7px 0;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const FileList = styled.ol`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;

  li:nth-child(3n-2),
  li:nth-child(3n-1) {
    margin: 0 16px 16px 0;
  }
`
