import React from 'react'
import styled from 'styled-components'

const DirectInputBtn = ({ onClick, text, style, isComplete=false, value }) => {
  return (
    <Btn onClick={onClick} style={style} isComplete={isComplete} value={value}>
      <Text isComplete={isComplete} value={value}>{text}</Text>
    </Btn>
  )
}

export default DirectInputBtn

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 14px;

  width: 68px;
  height: 28px;
  background: ${({ isComplete, value }) => ((isComplete && !!value) ? '#242424' : `#f4f4f4`)};

  cursor: pointer;
`

const Text = styled.p`
  font-family: NotoSansCJKkr-Regular;
  font-size: 12px;
  line-height: 20px;
  color:  ${({ isComplete, value }) => (isComplete ? (value ? '#ffffff' : '#bfbfbf' ):  `#646464`  )};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`
