import React from 'react'
import {  ValuationTemplate } from '@components/templates'
import { Helmet } from 'react-helmet'

const ValuationPage = ({ match }) => {
  const mbti = match?.params.mbti
  // const mbti = 'infp'

  return (
    <>
      {/* <Helmet>
        <title>{`내가 만약 '아이돌' 이었다면?(${mbti}) | 플램`}</title>
        <meta
          name="description"
          content={`내가 만약 '아이돌' 이었다면?(${mbti}) | 플램인`}
        />
        <meta
          name="keywords"
          content="mbti,부캐,아이돌,디스코그라피,음악,음반,앨범,음원유통,음원유통사,CD,아이돌,뮤지션,아티스트,프로필,music,album,linkfire,플램인,플램,plam,plamin"
        />
      </Helmet> */}
      <ValuationTemplate />
    </>
  )
}

export default ValuationPage
