import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { Store } from '.'
import { Network } from './networks'
import { TrackModel, AlbumModel, ArtistModel } from './models'

export default class SearchStore {
  /* 검색 모드
   * 'on' : 검색 창이 열린 상태
   * 'off' : 검색 창이 닫힌 상태
   * 'ing' : 검색어 입력 중인 상태
   * 'searched' : 검색을 실행한 상태
   */
  @observable isSearchMode

  /* 검색 상태 유무 */
  @observable isSearched

  /* 검색어 */
  @observable searchKeyword

  /* 상세 더보기 유무 */
  @observable more

  /* 검색 전 페이지 저장 */
  @persist @observable beforeSearchPage

  @observable searchList
  @observable autocompleteKeyword

  @observable searchArtistList
  @observable searchAlbumList
  @observable searchTrackList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init = () => {
    this.isSearchMode = false
    this.isSearched = false

    this.searchList = {}
    this.autocompleteKeyword = []
    this.searchArtistList = []
    this.searchAlbumList = []
    this.searchTrackList = []
    this.beforeSearchPage

    this.more = 'total'
  }

  // 검색 상태 유무 업데이트
  @action.bound
  updateIsSearched = async boolean => {
    this.isSearched = boolean
  }

  // 검색 모드 업데이트
  @action.bound
  updateIsSearchMode = async value => {
    this.isSearchMode = value
  }

  // 검색어 업데이트
  @action.bound
  updateSearchKeyword = async value => {
    this.searchKeyword = value
  }

  // 더보기 유무 업데이트
  @action.bound
  setMore = async value => {
    this.more = value
  }

  // 검색 전 페이지 업데이트
  @action.bound
  setBeforeSearchPage = async value => {
    this.beforeSearchPage = value
  }

  /**
   * 최초 검색 FETCH
   * @param {*} keyword : 검색어
   */
  @action.bound
  fetchSearchKeyword = async keyword => {
    return this.store.useLoading(async () => {
      if (!keyword) return

      const res = await this.network.searchNetwork.getSearchKeyword(
        encodeURIComponent(keyword),
      )
      if (!res) return

      // this.searchList = await res.searchResults

      // 가수 결과
      // 활동명 가수 기준으로 가져와서 ArtistModel에 담지 않음.
      this.searchList.artistList = await res.searchResults.artistList
      // .filter((elem) => !!elem)
      // .map((elem) => new ArtistModel(this.store, elem))

      // 트랙 결과
      this.searchList.trackList = await res.searchResults.trackList
        .filter(elem => !!elem)
        .map(elem => new TrackModel(this.store, elem))

      // 앨범 결과
      this.searchList.albumList = await res.searchResults.albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem))

      this.isSearched = true
      this.more = 'total'

      return !!res
    })
  }

  /**
   * 검색어 자동완성 FETCH
   * @param {*} keyword : 현재 검색어 값
   */
  @action.bound
  fetchSearchAutoComplete = async keyword => {
    if (!keyword) return

    const res = await this.network.searchNetwork.getSearchAutoComplete(
      encodeURIComponent(keyword),
    )
    if (
      !res.searchResults.artistList
      && !res.searchResults.albumList
      && !res.searchResults.trackList
    )
      return

    // 아티스트, 앨범, 트랙 키워드 배열 저장
    this.autocompleteKeyword = await res.searchResults.artistList
      .filter(elem => !!elem)
      .map(elem => ({
        keyword: elem.name,
        dataType: 'artist',
        _id: elem._id,
      }))
      .concat(
        res.searchResults.albumList
          .filter(elem => !!elem)
          .map(elem => ({
            keyword: elem.title,
            dataType: 'album',
            _id: elem._id,
          })),
      )
      .concat(
        res.searchResults.trackList
          .filter(elem => !!elem)
          .map(elem => ({
            keyword: elem.title,
            dataType: 'track',
            _id: elem._id,
          })),
      )

    return !!res
  }

  /**
   * 검색 결과 상세 처음 FETCH
   * @param {*} type : 'artist'|'album'|'track
   * @param {*} keyword : 현재 검색 중인 검색어
   */
  @action.bound
  firstFetchSearchDetail = async (type, keyword) => {
    return this.store.useLoading(async () => {
      if (!type || !keyword) return

      let res = null

      switch (type) {
        case 'artist':
          res = await this.network.searchNetwork.getSearchArtist(
            encodeURIComponent(keyword),
            0,
          )

          this.searchArtistList = res.searchResults.artistList
          return !!(res.searchResults.artistList.length !== 0)
          break
        case 'album':
          res = await this.network.searchNetwork.getSearchAlbum(
            encodeURIComponent(keyword),
            0,
          )

          this.searchAlbumList = res.searchResults.albumList
            .filter(elem => !!elem)
            .map(elem => new AlbumModel(this.store, elem))

          return !!(res.searchResults.albumList.length !== 0)
          break
        case 'track':
          res = await this.network.searchNetwork.getSearchTrack(
            encodeURIComponent(keyword),
            0,
          )

          this.searchTrackList = res.searchResults.trackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(this.store, elem))

          return !!(res.searchResults.trackList.length !== 0)
          break
        default:
      }
    })
  }

  /**
   * 검색 결과 상세 더보기 FETCH
   * @param {*} type : 'artist'|'album'|'track
   * @param {*} keyword : 현재 검색 중인 검색어
   * @param {*} offset : 현재 페이지
   */
  @action.bound
  moreFetchSearchDetail = async (type, keyword, offset) => {
    if (!type || !keyword) return

    let res = null

    switch (type) {
      case 'artist':
        res = await this.network.searchNetwork.getSearchArtist(
          encodeURIComponent(keyword),
          offset,
        )

        this.searchArtistList = this.searchArtistList.concat(
          res.searchResults.artistList,
        )
        return !!(res.searchResults.artistList.length !== 0)
        break
      case 'album':
        res = await this.network.searchNetwork.getSearchAlbum(
          encodeURIComponent(keyword),
          offset,
        )

        this.searchAlbumList = this.searchAlbumList.concat(
          res.searchResults.albumList
            .filter(elem => !!elem)
            .map(elem => new AlbumModel(this.store, elem)),
        )

        return !!(res.searchResults.albumList.length !== 0)
        break
      case 'track':
        res = await this.network.searchNetwork.getSearchTrack(
          encodeURIComponent(keyword),
          offset,
        )

        this.searchTrackList = this.searchTrackList.concat(
          res.searchResults.trackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(this.store, elem)),
        )

        return !!(res.searchResults.trackList.length !== 0)
        break
      default:
    }
  }

  /**
   * 자동완성 검색어 검색 카운트
   * @param {*} item : 자동완성 검색 아이템
   */
  @action.bound
  updateSearchCount = async item => {
    if (!item) return

    const { dataType, _id } = item

    const res = await this.network.searchNetwork.postSearchCount({
      _id,
      dataType,
    })

    return !!res
  }
}
