import React from 'react'
import { Flex, H6 } from '@components/atoms'
import { search_btn_img_gray } from '@images/'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const SearchListItem = ({ _onClick, item, updateSearchCount }) => {
  const handleSearch = (item) => {
    _onClick(item.keyword)
    updateSearchCount(item)
  }

  return (
    <Flex style={{ width: '100%' }} onClick={() => handleSearch(item)}>
      <img
        src={search_btn_img_gray}
        style={{ width: '24px', height: '24px', marginRight: '8px' }}
      />
      <H6 align="left" style={ellipsisStyle}>
        {item.keyword}
      </H6>
    </Flex>
  )
}

export default SearchListItem
