import { observable } from 'mobx'
import TrackModel from './TrackModel'

export default class ArtistTrackTrendModel extends TrackModel {
  /* Database fields */

  @observable albumInfo
  @observable likeCountInfo
  @observable dailyDetailList
  @observable listenerInfo
  @observable rankInfo

  @observable melonDailyList
  @observable bugsDailyList
  @observable genieDailyList
  @observable youtubeDailyList

  @observable trendDataAddedAt

  /* Database fields end */

  constructor(stores, props) {
    super(stores, props)

    this.albumInfo = props?.albumInfo
    this.likeCountInfo = props?.likeCountInfo
    this.dailyDetailList = props?.dailyDetailList
    this.listenerInfo = props?.listenerInfo
    this.rankInfo = props?.rankInfo

    this.melonDailyList = props?.melonDailyList
    this.bugsDailyList = props?.bugsDailyList
    this.genieDailyList = props?.genieDailyList
    this.youtubeDailyList = props?.youtubeDailyList

    this.trendDataAddedAt = props?.trendDataAddedAt
  }
}
