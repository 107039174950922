import React, { useEffect } from 'react'
import { ArtistTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { useLastLocation } from 'react-router-last-location'

interface ArtistPageProps {
  authStore: AuthStore;
  artistStore: ArtistStore;
  recordStore: RecordStore;
  statsStore: StatsStore;
  match: Any;
  history: Any;
  location: Any;
}

const ArtistPage = ({
  match,
  history,
  location,
  artistStore,
  recordStore,
  authStore,
  statsStore,
}: ArtistPageProps) => {
  const lastLocation = useLastLocation()

  /* store */
  const logout = authStore.logout || (() => { })
  const fetchLike = authStore.fetchLike || (() => { })
  const updateLike = authStore.updateLike || (() => { })
  const { likeInfo } = authStore
  const currentUser = authStore.currentUser || null

  const fetchArtistDetail = artistStore.fetchArtistDetail || (() => { })
  const artist = artistStore.artistDetail || null

  const enterRecord = recordStore.enterRecord || (() => { })

  const artistFollowerInfo = statsStore.artistFollowerInfo || null
  /* store end */

  // put record
  const updateRecord = async (type, url) => {
    const params = {
      _id: sessionStorage.getItem('recordId'),
      clickType: type,
      clickUrl: url,
    }

    await recordStore.updateRecord(params)
  }

  useEffect(() => {
    // fetch Artist
    fetchArtistDetail(match.params.id)

    // post Record
    const enterPage = {
      referrer: document.referrer,
      domain: document.domain,
      URL: document.URL,
      lastLocation,
      history,
      performance,
      type: 'artist',
      pathId: match.params.id,
    }
    enterRecord(enterPage)

    // 미로그인일 때, 로그인 전 페이지 저장
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [match, fetchArtistDetail])

  useEffect(() => {
    return () => artistStore.init()
  }, [])

  return (
    <ArtistTemplate
      artist={artist}
      currentPath={document.domain + match.url}
      updateRecord={updateRecord}
      currentUser={currentUser}
      logout={logout}
      fetchLike={fetchLike}
      likeInfo={likeInfo}
      updateLike={updateLike}
      artistFollowerInfo={artistFollowerInfo}
    />
  )
}

export default inject(
  'recordStore',
  'artistStore',
  'authStore',
  'statsStore',
)(observer(ArtistPage))
