import React from 'react'
import { Flex, Caption1, H6 } from '@components/atoms'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { move_btn_img } from '@images/index'


const PointBoxSkeleton = ({ item }) => {
  return (
    <UserCurrentPointBox
      type={'row'}
      justify={'space-between'}
      align={'center'}
    >

      <H6 style={{display:'flex', flexDirection:'row', whiteSpace:'pre-wrap'}}>
        <Skeleton width={300} />
      </H6>

      <img src={move_btn_img} alt={'move_point_btn'} style={{ width: '16px', height: '16px' }} />

    </UserCurrentPointBox>
  )
}

export default PointBoxSkeleton


const UserCurrentPointBox = styled(Flex)`
  padding: 0px 12px 0px 16px;

  width: 100%;
  height: 48px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 8px;

  border-radius: 4px;
  border: solid 1px #f4f4f4;
  cursor: pointer;
`