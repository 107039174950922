import { Network } from '.'

export default class DataMapNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getData(shortId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/data-maps/short-id/${shortId}`,
        'get',
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('DataMapNetwork getData error', error)
    }
  }

  async getShortenUrlData(subdomain, customUrl) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/shorten-url/link/${subdomain}${
          customUrl ? `?customUrl=${customUrl}` : ``
        }`,
        'get',
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('DataMapNetwork getShortenUrlData error', error)
    }
  }
}
