import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamTotalTemplate } from '@components/templates'
import qs from 'qs'

interface InPlamTotalPageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  inplamStore: InplamStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamTotalPage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
  inplamStore,
}: InPlamTotalPageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const [activeTab, setActiveTab] = useState('total')

  /* store */
  const fetchTotalArticleList = articleStore.fetchTotalArticleList || (() => {})

  const currentUser = authStore.currentUser || null
  const artistInfo = articleStore.artistInfo || null
  const totalArticleList = articleStore.totalArticleList || null
  const totalArticleListCount = articleStore.totalArticleListCount || null

  const totalInplamList = inplamStore.totalInplamList || null
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})
  /* store end */

  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)
  const [sortBy, setSortBy] = useState(
    query.sortBy ? query.sortBy : 'createdAt',
  )

  const handlePagination = _offset => {
    history.push(`/fanspot/total?offset=${_offset}&sortBy=${sortBy}`)
  }

  const handleChangeSortBy = _sortBy => {
    history.push(`/fanspot/total?offset=${offset}&sortBy=${_sortBy}`)
  }

  useEffect(() => {
    if (query.offset) setOffset(Number(query.offset))
    if (query.sortBy) setSortBy(query.sortBy)
  }, [query.offset, query.sortBy])

  useEffect(() => {
    const options = {
      limit: 10,
      sortBy,
      offset,
      isNotice: activeTab === 'notice',
    }

    if (activeTab === 'favorite') {
      options.isInplamFavorite = 1
    }

    fetchTotalArticleList(options)
  }, [offset, sortBy, activeTab])

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])
  useEffect(() => {
    const fetchTotalList = async () => {
      const result = await fetchTotalInplamList({
        offset: 0,
        limit: 20,
        isFavorite: 1,
      })

      if (!!result?.length < 20) {
        const result2 = await fetchTotalInplamList({
          offset: 0,
          limit: 20,
          isFavorite: 0,
        })
      }
    }

    fetchTotalList()
  }, [])

  useEffect(() => {
    if (totalInplamList?.findIndex(val => !!val?.isInplamFavorite) > -1) {
      setActiveTab('favorite')
    }
    else {
      setActiveTab('total')
    }
  }, [totalInplamList])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InPlamTotalTemplate
      uniqueName={match.params.uniqueName}
      artistInfo={artistInfo}
      totalArticleList={totalArticleList}
      totalArticleListCount={totalArticleListCount}
      offset={offset}
      handlePagination={handlePagination}
      sortBy={sortBy}
      handleChangeSortBy={handleChangeSortBy}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      totalInplamList={totalInplamList}
      currentUser={currentUser}
    />
  )
}

export default inject(
  'authStore',
  'articleStore',
  'inplamStore',
)(observer(InPlamTotalPage))
