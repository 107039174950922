import styled from 'styled-components'

/**
 * 모바일 웹의 레이아웃을 잡는 Template Wrapper 컴포넌트
 */
const BodyWrapper = styled.div.attrs({
  className: 'body_wrapper',
})`
  max-width: 480px;
  padding: 20px;
  background-color: white;
  min-height: 100vh;
  // overflow: hidden;
  box-sizing: border-box;
  position: relative;
`

export default BodyWrapper
