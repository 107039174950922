import React from 'react'
import { Typography, Flex } from '@components/atoms'
import styled from 'styled-components'
import Textarea from 'react-textarea-autosize'
import { dateYMD } from '@utils/format'

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0px;

  span:first-child {
    width: 55px;
  }
`

const AlbumInfo = ({
  albumType,
  albumGenre,
  releaseDate,
  releaseCompanyName,
  albumText,
  planningCompanyName,
}) => {
  return (
    <Flex type="column">
      <InfoItem>
        <Typography size="14px" color="#646464">
          유형
        </Typography>
        <Typography size="14px">{albumType}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          장르
        </Typography>
        <Typography size="14px">{albumGenre}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          발매일
        </Typography>
        <Typography size="14px">{dateYMD(releaseDate)}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          유통사
        </Typography>
        <Typography size="14px">{releaseCompanyName}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          권리사
        </Typography>
        <Typography size="14px">{planningCompanyName}</Typography>
      </InfoItem>

      <hr
        style={{
          margin: '24px 0px 30px 0px',
          borderColor: '#f4f4f4',
          width: '100%',
          borderWidth: '0.5px',
        }}
      />

      <Typography type="Medium" size="20px" style={{ paddingBottom: '6px' }}>
        앨범소개
      </Typography>
      {albumText ? (
        <Textarea
          readOnly
          value={albumText}
          className="textarea"
          style={{ fontSize: '14px', color: '#646464' }}
          minRows={1}
        />
      ) : (
        <Typography type="Medium">등록된 소개가 없습니다.</Typography>
      )}
    </Flex>
  )
}

export default AlbumInfo
