import { Caption1, Flex } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { converseCommentUnitEng, dateTodayYMDMM } from '@utils/format'
import {
  auth_artist_img,
  upload_photo_img,
  notice_img,
  sns_instagram,
  sns_twitter,
  sns_youtube,
} from '@images/'
import { STORAGE_URL } from '@consts'
import qs from 'qs'

const SnsIconComponenet = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  if (!text) {
    return null
  }

  const icons = text.split(urlRegex).map(url => {
    const query = qs.parse(url, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })

    if (url.indexOf('youtube') !== -1) {
      // if (url.indexOf('youtube') !== -1) {
      if (query[Object.keys(query)[0]]) {
        return <SnsImg src={sns_youtube} />
      }
    }
    if (url.indexOf('youtu.be') !== -1) {
      // if (!query[Object.keys(query)[0]] && url.indexOf('youtu.be') !== -1) {

      return <SnsImg src={sns_youtube} />
    }

    // 인스타그램 링크 처리 (임베드 공유 복사)
    if (
      url.includes('instagram.com/p/')
      && url.includes('?utm_source=ig_embed')
    ) {
      return <SnsImg src={sns_instagram} />
    }

    // 인스타그램 링크 처리 (임베드 링크 복사)
    if (url.includes('instagram.com/p/')) {
      return <SnsImg src={sns_instagram} />
    }

    // X (구 Twitter) 링크 처리
    if (
      url.includes('twitter.com')
      || (url.includes('x.com') && url.includes('?ref_src=twsrc'))
    ) {
      return <SnsImg src={sns_twitter} />
    }

    // X (구 Twitter) 링크 처리
    if (url.includes('twitter.com') || url.includes('x.com')) {
      return <SnsImg src={sns_twitter} />
    }

    return null
  })

  return icons.filter(val => !!val)?.[0] || null
}

const TotalInPlamListItem = ({ item, onClick }) => {
  if (item && !item.isNotice) {
    return (
      <ItemBox onClick={() => onClick(item._id)}>
        <Caption1 type="Bold" align="left" style={{ marginBottom: 8 }}>
          {item.artistName}
        </Caption1>
        <Flex>
          {
            item.imageOriginalPathList && item.imageOriginalPathList.length > 0 && (
              <ThumbnailBox>
                <img
                  src={
                    STORAGE_URL
                  + (item.image480PathList && item.image480PathList[0])
                  }
                  onError={
                    e => {
                      e.target.src = upload_photo_img
                    }
                  }
                  alt="exist_img"
                  style={
                    {
                      width: '48px',
                      height: '48px',
                      objectFit: 'cover',
                    }
                  }
                />
              </ThumbnailBox>
            )
          }
          <Flex
            type="column"
            style={
              {
                flex: 1,
                width: 1,
                marginRight: 8,
              }
            }
          >
            <Flex align="center">
              {/* {
                item.writerId === item.artistUserId && (
                  <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
                )
              } */}
              <TitleText>{item.title}</TitleText>
              <SnsIconComponenet text={item?.text} />
            </Flex>
            <Flex style={{ marginTop: 8 }} align="center">
              <Caption1 type="Regular" color={colors.brown_grey}>
                {/* {dateTodayYMDMM(item.createdAt)} */}
                {
                  item.isAnonymous
                    ? '익명'
                    : item.writerId === item?.artistUserId
                      ? item?.artistName
                      : item.writerInfo && item.writerInfo.nickname
                }
              </Caption1>
              {
                !item.isAnonymous && item?.isOfficial && (
                  <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
                )
              }
              <Caption1
                type="Regular"
                color={colors.brown_grey}
                style={{ margin: '0 16px' }}
              >
                조회
                {' '}
                {
                  (item.viewCount && converseCommentUnitEng(item.viewCount))
                  || 0
                }
              </Caption1>
              <Caption1 type="Regular" color={colors.brown_grey}>
                추천
                {' '}
                {
                  (item.likeCount && converseCommentUnitEng(item.likeCount))
                  || 0
                }
              </Caption1>
            </Flex>
          </Flex>

          <CommentCountBox>
            <Caption1 color={colors.brown_grey} type="Regular">
              댓글
            </Caption1>
            <Caption1 type="Regular">
              {
                (item.commentCount
                && converseCommentUnitEng(item.commentCount))
                || 0
              }
            </Caption1>
          </CommentCountBox>
        </Flex>
      </ItemBox>
    )
  }
  if (item && item.isNotice) {
    return (
      <ItemBox
        align="center"
        onClick={() => onClick(item._id)}
        style={{ height: 49, flexDirection: 'row' }}
      >
        <img
          src={notice_img}
          alt="notice_icon"
          style={{ width: 32, height: 16, marginRight: 8 }}
        />
        <TitleText>{item.title}</TitleText>
        <SnsIconComponenet text={item?.text} />
      </ItemBox>
    )
  }
  return <></>
}

export default TotalInPlamListItem

const ItemBox = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 16px 20px;
  border-bottom: solid 1px ${colors.whitef4};
  box-sizing: border-box;
  height: 106px;
  cursor: pointer;
  flex-direction: column;
`

const TitleText = styled(Caption1)`
  width: fit-content;
  max-width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const CommentCountBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 6px 9px;
  background-color: ${colors.whitef4};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const ThumbnailBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
`

const FromArtistImg = styled.img`
  width: 12px;
  height: 12px;
`

const SnsImg = styled.img`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  object-fit: cover;
  margin-left: 8px;
`
