import React from 'react'
import { artist_login_btn } from '@images/'

const ArtistLoginBtn = ({ onClick, style }) => {
  return (
    <img
      onClick={onClick}
      src={artist_login_btn}
      alt="artist_login_img"
      style={
        {
          width: '280px',
          height: '48px',
          cursor: 'pointer',
          ...style,
        }
      }
    />
  )
}

export default ArtistLoginBtn
