import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, H6, Caption1 } from '@components/atoms'
import TextareaAutosize from 'react-textarea-autosize'
import { comment_img, check_img, check_empty_img } from '@images/'
import { CommentCard, LikePopup, LoginPopup, OkCancelPopup } from '@components/organisms'
import { colors } from '@colors/'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { useLocation } from 'react-router-dom'
import useSnackBar from '../Popup/useSnackBar'
import ReportPopup from '../Popup/ReportPopup'
import BlockPopup from '../Popup/BlockPopup'

interface CommmentTemplateProps {
  currentUser: any;
  isLike: boolean;
  dataObjectId: string;
  dataCollectionName: string;
  contentTitle: string;
  artistId: string;
}

const InPlamArticleCommentList = ({
  currentUser,
  isLike,
  dataObjectId,
  dataCollectionName,
  artistId,
  writerId,
}: CommmentTemplateProps) => {
  const { authStore, commentStore } = useStore()
  const location = useLocation()

  const [offset, setOffset] = useState(0)

  const updateLike = authStore.updateLike || (() => {})
  const fetchCommentList = commentStore.fetchCommentList || (() => {})
  const createComment = commentStore.createComment || (() => {})
  const updateComment = commentStore.updateComment || (() => {})
  const updateCommentLike = commentStore.updateCommentLike || (() => {})
  const removeComment = commentStore.removeComment || (() => {})
  const reportComment = commentStore.reportComment || (() => {})
  const blockUser = authStore.blockUser || (() => {})

  const commentList = commentStore.commentList || null
  const commentListCount = commentStore.commentListCount || null

  const [inputValue, setInputValue] = useState('')
  const [showLoginPopup, setShowLoginPopup] = useState(false) // 로그인 요구 팝업
  const [focusInput, setFocusInput] = useState(false) // 댓글 입력창 포커스 여부
  const [isAnonymous, setIsAnonymous] = useState(true) // 익명 여부

  const [showLikePopup, setShowLikePopup] = useState(false) // 좋아요 알림 팝업


  /**
   * 삭제할 타겟 댓글 ID 저장
   * useState에 저장하면 불필요한 리렌더가 일어나므로, useRef 사용
   * 삭제 팝업 댓글 컴포넌트에서 현(리스트) 컴포넌트로 분리 (댓글당 렌더되던 팝업 => 하나의 팝업 렌더 )
   */
  const commentIdRef = useRef(null)

  const {
    isShowing: likeShowing,
    toggle: likeToggle,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: deleteShowing,
    toggle: deleteToggle,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: reportShowing,
    toggle: reportToggle,
  } = ReportPopup.usePopup()
  const {
    toggleSnackBar: toggleMineLike,
    View: MineLikeSnackBar,
  } = useSnackBar()
  const {
    toggleSnackBar: toggleDuplicateReport,
    View: DuplicateReportSnackBar,
  } = useSnackBar()
  const { toggleSnackBar: toggleReport, View: ReportSnackBar } = useSnackBar()

  const _fetchCommentList = () => {
    setOffset(0)
    fetchCommentList({
      dataObjectId,
      dataCollectionName,
      userId: currentUser && currentUser._id,
      limit: 10,
      offset: 0,
    })
  }

  const countOffset = () => {
    setOffset(offset + 1)
  }

  const confirmAuth = () => {
    if (!currentUser) {
      setShowLoginPopup(true)
      return
    }

    if (currentUser && !isLike) {
      likeToggle()
    }
  }

  const _createComment = async () => {
    const completed = await createComment({
      dataObjectId,
      dataCollectionName,
      text: inputValue,
      isAnonymous,
    })

    if (completed) {
      setInputValue('')
      setFocusInput(false)
      setIsAnonymous(true)
      _fetchCommentList()
    }
  }

  const _updateComment = async (text, _isAnonymous) => {
    const completed = await updateComment({
      commentId: commentIdRef.current,
      text,
      isAnonymous: _isAnonymous,
    })

    if (completed) {
      _fetchCommentList()
    }
  }

  const _createReply = async (value, parentId, replyIsAnonymous) => {
    const completed = await createComment({
      dataObjectId,
      dataCollectionName,
      text: value,
      parentId,
      isAnonymous: replyIsAnonymous,
    })

    return completed
  }

  const targetingComment = async (id, type: 'delete' | 'report' | 'block') => {
    commentIdRef.current = await id
    if (type === 'delete') {
      deleteToggle()
    }
    else if (type === 'report') {
      reportToggle()
    }
    else if (type === 'block') {
      blockToggle()
    }
  }

  const {
    isShowing: blockShowing,
    toggle: blockToggle,
  } = BlockPopup.usePopup()

  const {
    toggleSnackBar: toggleFailedBlock,
    View: FailedBlockSnackBar,
  } = useSnackBar()
  const { toggleSnackBar: toggleBlock, View: BlockSnackBar } = useSnackBar()

  const _blockUser = async () => {
    if (!currentUser) {
      showLoginPopup()
      return
    }

    const blocked = await blockUser({
      blockedUserId: commentIdRef.current?.writerId,
      isAnonymous: commentIdRef.current?.isAnonymous
    })
    if (blocked === 'completed') {
      // alert('신고가 접수되었습니다.')
      _fetchCommentList()
      toggleBlock()
    }
    else if (blocked === 'error') {
      // alert('이미 신고하신 댓글입니다.')
      toggleFailedBlock()
    }
  }

  const _removeComment = async () => {
    const deleted = await removeComment(commentIdRef.current, currentUser._id)
    if (deleted) _fetchCommentList()
  }

  const _reportComment = async reason => {
    if (!currentUser) {
      confirmAuth()
      return
    }
    const reported = await reportComment({
      commentId: commentIdRef.current,
      reason,
    })
    if (reported === 'completed') {
      // alert('신고가 접수되었습니다.')
      toggleReport()
      _fetchCommentList()
    }
    else if (reported === 'conflicted') {
      // alert('이미 신고하신 댓글입니다.')
      toggleDuplicateReport()
    }
  }

  const _handlelike = async () => {
    updateLike('add', currentUser._id, artistId, 'artist')
    setShowLikePopup(true)
  }

  useEffect(() => {
    fetchCommentList({
      dataObjectId,
      dataCollectionName,
      userId: currentUser && currentUser._id,
      limit: 10,
      offset,
    })
    if (offset < 1) {
      window.scrollTo(0, 0)
    }

    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser, dataObjectId, dataCollectionName, offset])

  if (commentList) {
    return (
      <>
        <H6 type="Bold" align="left" style={{ marginTop: 35 }}>
          {`댓글 ${commentListCount || 0}`}
        </H6>
        <Br style={{ margin: '16px 0 16px -20px' }} />
        <InputBox onClick={confirmAuth}>
          <Flex style={{ width: '100%', alignItems: 'flex-end' }}>
            <CustomInput
              maxLength={300}
              placeholder={
                !currentUser
                  ? '로그인을 해주세요'
                  : !isLike
                    ? '좋아요를 눌러주세요'
                    : '댓글을 입력해주세요.'
              }
              disabled={(!currentUser || !isLike) && true}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              maxRows={3}
              minRows={focusInput ? 3 : 1}
              autoCorrect="off"
              autoCapitalize="off"
              onFocus={() => setFocusInput(true)}
              // onBlur={() => setFocusInput(false)}
            />
            <Flex type="column">
              {
                focusInput && (
                  <Flex
                    style={
                      {
                        curosor: 'pointer',
                        justifyContent: 'center',
                      }
                    }
                    align="center"
                    onClick={() => setIsAnonymous(!isAnonymous)}
                  >
                    <img
                      src={isAnonymous ? check_img : check_empty_img}
                      alt="check_box"
                      style={{ width: 16, height: 16, marginRight: 2 }}
                    />
                    <Caption1>익명</Caption1>
                  </Flex>
                )
              }
              <SubmitBtn
                onClick={_createComment}
                disabled={
                  !currentUser || !isLike || inputValue === '' ? true : false
                }
              >
                등록
              </SubmitBtn>
            </Flex>
          </Flex>
        </InputBox>
        <Flex
          type="column"
          next={countOffset}
          hasMore={commentListCount > commentList.length}
        >
          {
            commentList && commentListCount && commentListCount > 0 ? (
              commentList.map((comment, index) => (
                <CommentCard
                  key={`${comment._id}_${index}`}
                  item={comment}
                  currentUser={currentUser && currentUser}
                  currentUserId={currentUser && currentUser._id}
                  targetingComment={targetingComment}
                  isLike={isLike}
                  _createReply={_createReply}
                  updateCommentLike={updateCommentLike}
                  setShowLoginPopup={setShowLoginPopup}
                  confirmAuth={confirmAuth}
                  toggleMineLike={toggleMineLike}
                  _updateComment={_updateComment}
                  isArticle
                  articleWriterId={writerId}
                />
              ))
            ) : (
              <Flex
                type="column"
                align="center"
                justify="center"
                style={{ marginTop: 50, marginBottom: 24 }}
              >
                <img
                  src={comment_img}
                  style={{ width: 40, height: 40 }}
                  alt="no_comment_icon"
                />
                <H6>
                댓글이 없습니다
                  <br />
                첫번째 댓글을 남겨주세요
                </H6>
              </Flex>
            )
          }
          {
            commentListCount > commentList.length && (
              <MoreBtn onClick={countOffset}>더보기</MoreBtn>
            )
          }
        </Flex>
        {/* <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={commentList.length}
          scrollThreshold={0.8}
          next={countOffset}
          hasMore={commentListCount > commentList.length}
          loader={
            <ReactLoading
              type="spin"
              color="black"
              style={
                {
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 50px auto',
                }
              }
            />
          }
          // className="scroll_div"
        >
          {
            commentList && commentListCount && commentListCount > 0 ? (
              commentList.map((comment, index) => (
                <CommentCard
                  key={`${comment._id}_${index}`}
                  item={comment}
                  currentUser={currentUser && currentUser}
                  currentUserId={currentUser && currentUser._id}
                  targetingComment={targetingComment}
                  isLike={isLike}
                  _createReply={_createReply}
                  updateCommentLike={updateCommentLike}
                  setShowLoginPopup={setShowLoginPopup}
                  confirmAuth={confirmAuth}
                  toggleMineLike={toggleMineLike}
                  _updateComment={_updateComment}
                />
              ))
            ) : (
              <Flex
                type="column"
                align="center"
                justify="center"
                style={{ marginTop: 50 }}
              >
                <img
                  src={comment_img}
                  style={{ width: 40, height: 40 }}
                  alt="no_comment_icon"
                />
                <H6>
                댓글이 없습니다
                  <br />
                첫번째 댓글을 남겨주세요
                </H6>
              </Flex>
            )
          }
        </InfiniteScroll> */}

        <OkCancelPopup.View
          isShowing={likeShowing}
          toggle={likeToggle}
          title={`아티스트 좋아요를 눌러주세요`}
          okText="좋아요"
          okColor={colors.primary}
          onOk={_handlelike}
        />
        <OkCancelPopup.View
          isShowing={deleteShowing}
          toggle={deleteToggle}
          title="댓글을 삭제합니다"
          okText="삭제"
          okColor={colors.lightish_red}
          onOk={_removeComment}
        />
        <ReportPopup.View
          isShowing={reportShowing}
          toggle={reportToggle}
          okColor={colors.lightish_red}
          onOk={_reportComment}
        />
        {
          showLoginPopup && (
            <LoginPopup handleClose={() => setShowLoginPopup(false)} />
          )
        }
        {
          showLikePopup && !authStore?.isNeverShowLikeArtistPopup && (
            <LikePopup handleClose={() => setShowLikePopup(false)} type={'artist'} />
          )
        }
        <BlockPopup.View
          isShowing={blockShowing}
          toggle={blockToggle}
          okColor={colors.lightish_red}
          onOk={_blockUser}
        />
        <BlockSnackBar text={'사용자 차단이 완료되었습니다'} />
        <FailedBlockSnackBar text={'차단에 실패하였습니다'} />
        <MineLikeSnackBar text={'내 댓글에는 좋아요를 누를 수 없습니다'} />
        <ReportSnackBar text={'신고처리가 완료되었습니다'} />
        <DuplicateReportSnackBar text={'이미 신고한 댓글입니다'} />
      </>
    )
  }
  return <></>
}

export default observer(InPlamArticleCommentList)

const InputBox = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: white;
`

const CustomInput = styled(TextareaAutosize)`
  font-family: NotoSansCJKkr-Regular;
  resize: none;
  width: calc(100% - 40px);
  font-size: 14px;
  margin: 7px 0 7px 0;
  padding: 10px;
  border-radius: unset;
  min-height: 20px;
  line-height: 1.4;

  border: ${props =>
    props.value === '' ? 'solid 1px #f4f4f4' : 'solid 1px #242424'};

  :disabled {
    background-color: #ffffff;
    border: solid 1px #f4f4f4;
  }

  ::placeholder {
    background-color: trasparent;
    color: #949494;
  }
`

const SubmitBtn = styled(Flex)`
  font-size: 12px;
  min-width: 48px;
  height: 40px;
  border: ${props =>
    props.disabled ? 'solid 1px #f4f4f4' : 'solid 1px black'};
  color: ${props => (props.disabled ? '#d4d4d4' : 'white')};
  background-color: ${props => (props.disabled ? 'white' : 'black')};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 7px;
  margin-right: 0px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 1px;
  border-bottom: solid 1px ${colors.whitef4};
  position: relative;
`

const MoreBtn = styled(Flex)`
  margin: 16px 0 0 0;
  width: 100%;
  height: 32px;
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  align-items: center;
  justify-content: center;
  border: solid 1px ${colors.whitef4};
  box-sizing: border-box;
  cursor: pointer;
`
