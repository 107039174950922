import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2 } from '@components/atoms'
import { converseUnitEng } from '@utils/format'
import { mobile } from '@styles/media'

const Box = styled.div`
  &::before {
    content: ${(props) =>
      props.data < 0
        ? "' \\25BC'"
        : props.data === 0
        ? "' \\257A'"
        : "' \\25B2'"};
    color: ${(props) =>
      props.data > 0 ? '#66bc6a' : props.data === 0 ? '#242424' : '#ea4653'};
    font-size: 10px;
    margin-right: 3px;
    position: relative;
    bottom: ${(props) => (props.data === 0 ? '8px' : '6px')};
    @media ${mobile} {
      bottom: ${(props) => (props.data === 0 ? '5px' : '4px')};
    }
  }
`

const IncreaseTextBox = ({ data, style }) => {
  return (
    <>
      {data <= 0 ? (
        false
      ) : (
        <Flex style={{ height: '18px', ...style }}>
          <Box data={data || 0} />
          <Caption2 color="#949494">
            {converseUnitEng(Math.abs(data || 0))}
          </Caption2>
          <Caption2 style={{ marginLeft: '5px' }} color="#949494">
            지난 28일전
          </Caption2>
        </Flex>
      )}
    </>
  )
}

export default IncreaseTextBox
