import React, { useState } from 'react'
import {
  Flex,
  BodyWrapper,
  Typography,
  Input,
  Caption1,
} from '@components/atoms'
import { plamin_logo_img } from '@images/index'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'

const AuthFindIdTemplate = () => {
  const history = useHistory()
  const { authStore } = useStore()
  const findAccount = authStore?.findAccount || (() => { })

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleFindAccount = async () => {
    const res = await findAccount(email)

    if (res) {
      if (res?.data?.isSend) {
        setError('complete')
      } else {
        setError('duplicated')
      }
    }
  }

  return (
    <BodyWrapper>
      <Flex
        type="column"
        style={{
          height: '100vh',
          padding: '56px 0',
          boxSizing: 'border-box',
        }}
      >
        <Flex
          type="column"
          style={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <img
            src={plamin_logo_img}
            width={126}
            height={36}
            alt="logo"
            onClick={() => (window.location.href = '/')}
          />
          {error === 'complete' ? (
            <>
              <Typography
                size="38px"
                type="bold"
                style={{ margin: '32px 0 12px 0', textAlign: 'center' }}
              >
                아이디 찾기
                <br />
                완료
              </Typography>
              <Box>
                <Typography size="12px" type="Regular" color="#646464">
                  아래의 이메일로 아이디를 전송했습니다.
                </Typography>
                <Typography size="16px">{email}</Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography
                size="38px"
                type="bold"
                style={{ margin: '32px 0 12px 0' }}
              >
                아이디 찾기
              </Typography>
              <Typography size="12px" color="#646464">
                회원가입에 등록된 이메일로 아이디를 찾으실 수 있어요.
              </Typography>
              <Input
                placeholder="이메일 주소"
                style={{ height: 48, width: '100%', marginTop: 32 }}
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
              />
              {error === 'duplicated' && (
                <Caption1
                  color="#ea4653"
                  align="left"
                  style={{ marginTop: 8, width: '100%' }}
                >
                  이메일을 다시 확인해주세요.
                </Caption1>
              )}
            </>
          )}
        </Flex>
        {error === 'complete' ? (
          <Flex type="column">
            <Button
              style={{ color: 'black', backgroundColor: '#f4f4f4' }}
              onClick={() => history.push('/login/artist')}
            >
              로그인
            </Button>
            <Typography
              size="16px"
              color="#646464"
              style={{
                width: '100%',
                marginTop: 24,
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => history.push('/auth/find-password')}
            >
              비밀번호 재설정
            </Typography>
          </Flex>
        ) : (
          <Button disabled={!email} onClick={handleFindAccount}>
            메일 전송
          </Button>
        )}
      </Flex>
    </BodyWrapper>
  )
}

export default AuthFindIdTemplate

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  border: none;
  background-color: black;
  color: white;

  :disabled {
    color: #bfbfbf;
    background-color: #f4f4f4;
  }
`

const Box = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 84px;
  border-radius: 2px;
  border: solid 0.5px #eaeaea;
  background-color: #fafafa;
`
